import api from './api';
import { getAuthHeader } from './authService';

const apiCall = async (method, endpoint, data = null) => {
  try {
    const token = await getAuthHeader();
    const config = {
      method,
      url: `/api${endpoint}`,
      headers: { Authorization: token },
    };
    if (data) {
      config.data = data;
    }
    const response = await api(config);
    return response.data;
  } catch (error) {
    console.error(`Error in ${method} ${endpoint}:`, error);
    if (error.response && error.response.status === 403) {
      throw new Error('Access denied. You do not have permission to perform this action.');
    }
    throw new Error(`API error: ${error.response?.data?.message || error.message}`);
  }
};

// Brand profile methods
export const getBrandProfiles = async () => {
  return apiCall('get', '');
};

export const getBrandProfile = async (orgId) => {
  try {
    //console.log(`Fetching brand profile for orgId: ${orgId}`);
    const response = await apiCall('get', `/organisations/${orgId}/brands`);
    //console.log('Received brand profile:', response);
    
    if (response && response.length > 0) {
      return response[0]; // Assuming one brand profile per organization
    }
    
    //console.log('No brand profile found');
    return null;
  } catch (error) {
    console.error('Error in getBrandProfile:', error);
    throw error;
  }
};

export const createBrandProfile = async (data) => {
  const response = await apiCall('post', '', data);
  return response;
};

export const updateBrandProfile = async (brandId, data) => {
  const response = await apiCall('put', `/${brandId}`, data);
  return response;
};

export const deleteBrandProfile = async (brandId) => {
  return apiCall('delete', `/${brandId}`);
};

// Tone of Voice methods
export const getToneOfVoice = async (brandId) => {
  if (!brandId) {
    throw new Error('Brand ID is required to fetch tone of voice');
  }
  try {
    //console.log(`Fetching tone of voice for brandId: ${brandId}`);
    const response = await apiCall('get', `/brands/${brandId}/toneofvoice`);
    //console.log('Tone of voice response:', response);
    return response;
  } catch (error) {
    console.error('Error fetching tone of voice:', error);
    if (error.response && error.response.status === 404) {
      //console.log('Tone of voice not found, returning null');
      return null;
    }
    throw error;
  }
};

export const createToneOfVoice = async (brandId, data) => {
  return apiCall('post', `/${brandId}/toneofvoice`, data);
};

export const updateToneOfVoice = async (brandId, toneOfVoiceId, data) => {
  return apiCall('put', `/${brandId}/toneofvoice/${toneOfVoiceId}`, data);
};

export const deleteToneOfVoice = async (brandId, toneOfVoiceId) => {
  return apiCall('delete', `/${brandId}/toneofvoice/${toneOfVoiceId}`);
};

export const createOrUpdateBrandProfile = async (orgId, data) => {
  try {
    //console.log('Attempting to create or update brand profile:', data);
    if (data.id) {
      //console.log(`Updating existing brand profile for orgId: ${orgId}`);
      return await apiCall('put', `/organisations/${orgId}/brands/${data.id}`, data);
    } else {
      //console.log('Creating new brand profile');
      return await apiCall('post', `/organisations/${orgId}/brands`, data);
    }
  } catch (error) {
    console.error('Error in createOrUpdateBrandProfile:', error);
    throw error;
  }
};

export const createOrUpdateToneOfVoice = async (brandId, data) => {
  try {
    //console.log('Attempting to create or update tone of voice:', data);
    if (data.id) {
      //console.log(`Updating existing tone of voice for brandId: ${brandId}`);
      return await apiCall('put', `/brands/${brandId}/toneofvoice/${data.id}`, data);
    } else {
      //console.log('Creating new tone of voice');
      return await apiCall('post', `/brands/${brandId}/toneofvoice`, data);
    }
  } catch (error) {
    console.error('Error in createOrUpdateToneOfVoice:', error);
    throw error;
  }
};

export default {
  getBrandProfiles,
  getBrandProfile,
  createBrandProfile,
  updateBrandProfile,
  deleteBrandProfile,
  getToneOfVoice,
  createToneOfVoice,
  updateToneOfVoice,
  deleteToneOfVoice,
  createOrUpdateBrandProfile,
  createOrUpdateToneOfVoice,
};
