import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box, Grid, Typography, IconButton, Tooltip,
  useTheme, TextField, Select, MenuItem, FormControl, InputLabel,
  Dialog, DialogContent, DialogActions, Button,
  AppBar, Toolbar, Paper
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import CloseIcon from '@mui/icons-material/Close';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import BulletList from '@tiptap/extension-bullet-list';
import OrderedList from '@tiptap/extension-ordered-list';
import ListItem from '@tiptap/extension-list-item';
import PuawaiOrganisationCanvasAIAssistButton from '../ai/PuawaiOrganisationCanvasAIAssistButton';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';

const cleanContent = (content) => {
  return content
    .replace(/<p><\/p>/g, '<br>')
    .replace(/<p>(.+?)<\/p>/g, '$1<br>')
    .replace(/<br\s*\/?>\s*$/, '')
    .trim();
};

const TipTapEditor = ({ content, onUpdate, editorProps }) => {
  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false,
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false,
        },
      }),
      Underline,
    ],
    content: '',
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      onUpdate(html);
    },
    ...editorProps,
  });

  useEffect(() => {
    if (editor?.isEmpty && content) {
      editor.commands.setContent(content, false);
    }
  }, [editor, content]);

  if (!editor) {
    return null;
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <MenuBar editor={editor} />
      <Box sx={{ 
        flexGrow: 1, 
        overflowY: 'auto', 
        minHeight: '150px', 
        maxHeight: '300px',
        border: '1px solid #ccc',
        borderRadius: '4px',
        padding: '8px'
      }}>
        <EditorContent editor={editor} />
      </Box>
    </Box>
  );
};

const MenuBar = ({ editor }) => {
  if (!editor) {
    return null;
  }

  return (
    <Box sx={{ mb: 1, display: 'flex', flexWrap: 'wrap' }}>
      <Tooltip title="Bold">
        <IconButton onClick={() => editor.chain().focus().toggleBold().run()} className={editor.isActive('bold') ? 'is-active' : ''}>
          <FormatBoldIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Italic">
        <IconButton onClick={() => editor.chain().focus().toggleItalic().run()} className={editor.isActive('italic') ? 'is-active' : ''}>
          <FormatItalicIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Underline">
        <IconButton onClick={() => editor.chain().focus().toggleUnderline().run()} className={editor.isActive('underline') ? 'is-active' : ''}>
          <FormatUnderlinedIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Bullet List">
        <IconButton 
          onClick={() => editor.chain().focus().toggleBulletList().run()} 
          className={editor.isActive('bulletList') ? 'is-active' : ''}
        >
          <FormatListBulletedIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Numbered List">
        <IconButton 
          onClick={() => editor.chain().focus().toggleOrderedList().run()} 
          className={editor.isActive('orderedList') ? 'is-active' : ''}
        >
          <FormatListNumberedIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

const BaseCanvas = ({
  user, selectedOrg, canvas, onClose, onUpdate, canvasType, sections, tooltips, icon: Icon, editor
}) => {
  const theme = useTheme();
  const [fullscreenField, setFullscreenField] = useState(null);
  const [localCanvas, setLocalCanvas] = useState(canvas);

  useEffect(() => {
    setLocalCanvas(canvas);
  }, [canvas]);

  const handleEditorUpdate = useCallback((field) => (content) => {
    const updatedContent = cleanContent(content);
    setLocalCanvas(prevCanvas => ({
      ...prevCanvas,
      [field]: updatedContent
    }));
    onUpdate(field, updatedContent);
  }, [onUpdate]);

  const handleSuggestionApply = useCallback((field, suggestion) => {
    const updatedCanvas = {
      ...localCanvas,
      [field]: suggestion
    };
    setLocalCanvas(updatedCanvas);
    onUpdate(field, suggestion);
  }, [localCanvas, onUpdate]);

  const renderInput = (section) => {
    switch (section.inputType) {
      case 'swot':
        return (
          <Grid container spacing={2}>
            {section.subSections.map((subSection) => (
              <Grid item xs={12} sm={6} key={subSection.field}>
                <Paper 
                  elevation={3} 
                  sx={{ 
                    p: 2, 
                    height: '100%', 
                    display: 'flex', 
                    flexDirection: 'column',
                    backgroundColor: theme.palette.background.default,
                  }}
                >
                  <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant="h6" sx={{ color: theme.palette.primary.main, fontWeight: 500 }}>
                      {subSection.title}
                    </Typography>
                    <Tooltip title={tooltips[subSection.field]}>
                      <IconButton size="small">
                        <InfoOutlinedIcon fontSize="small" sx={{ color: theme.palette.info.main }} />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <TipTapEditor
                    content={localCanvas[subSection.field] || ''}
                    onUpdate={handleEditorUpdate(subSection.field)}
                    editorProps={{
                      editorProps: {
                        attributes: {
                          style: 'height: 150px; overflow-y: auto;',
                        },
                      },
                    }}
                  />
                  <Box sx={{ mt: 'auto' }}>
                    <PuawaiOrganisationCanvasAIAssistButton
                      fieldName={subSection.field}
                      fieldTitle={subSection.title}
                      currentValue={localCanvas[subSection.field] || ''}
                      onSuggestion={(suggestion) => handleSuggestionApply(subSection.field, suggestion)}
                      orgData={selectedOrg}
                      canvasType={canvasType}
                      currentStepData={{
                        field: subSection.field,
                        value: localCanvas[subSection.field] || ''
                      }}
                      tooltip={tooltips[subSection.field]}
                      allFormData={localCanvas}
                      selectedOrg={selectedOrg}
                    />
                  </Box>
                </Paper>
              </Grid>
            ))}
          </Grid>
        );
      case 'select':
        return (
          <FormControl fullWidth>
            <InputLabel>{section.title}</InputLabel>
            <Select
              value={localCanvas[section.field] || ''}
              onChange={(e) => onUpdate(section.field, e.target.value)}
              label={section.title}
            >
              {section.options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      case 'number':
        return (
          <TextField
            fullWidth
            type="number"
            label={section.title}
            value={localCanvas[section.field] || ''}
            onChange={(e) => onUpdate(section.field, e.target.value)}
          />
        );
      case 'date':
        return (
          <TextField
            fullWidth
            type="date"
            label={section.title}
            value={localCanvas[section.field] || ''}
            onChange={(e) => onUpdate(section.field, e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
        );
      case 'richtext':
      default:
        return (
          <Box sx={{ position: 'relative' }}>
            <TipTapEditor
              content={localCanvas[section.field] || ''}
              onUpdate={(newContent) => {
                const cleanedContent = cleanContent(newContent);
                setLocalCanvas(prev => ({
                  ...prev,
                  [section.field]: cleanedContent
                }));
                onUpdate(section.field, cleanedContent);
              }}
              editorProps={{
                editorProps: {
                  attributes: {
                    class: 'tiptap-editor',
                    style: `min-height: ${(section.rows || 3) * 24}px; max-height: 300px; overflow-y: auto;`,
                  },
                },
              }}
            />
            <Box sx={{ position: 'absolute', top: 0, right: 0, zIndex: 1 }}>
              <IconButton onClick={() => setFullscreenField(section.field)}>
                <FullscreenIcon />
              </IconButton>
            </Box>
          </Box>
        );
    }
  };

  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column',
      bgcolor: theme.palette.background.paper,
      color: theme.palette.text.primary,
    }}>
      <Grid container spacing={3}>
        {sections.map((section) => (
          <Grid item xs={12} key={`${canvasType}-${section.field}`}>
            <Paper elevation={3} sx={{ p: 3, backgroundColor: theme.palette.background.default }}>
              <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="h6" sx={{ color: theme.palette.primary.main, fontWeight: 500 }}>
                    {section.title}
                  </Typography>
                  <Tooltip title={tooltips[section.field]}>
                    <IconButton size="small" sx={{ ml: 1 }}>
                      <InfoOutlinedIcon fontSize="small" sx={{ color: theme.palette.info.main }} />
                    </IconButton>
                  </Tooltip>
                </Box>
                {!section.hideAIAssist && (
                  <PuawaiOrganisationCanvasAIAssistButton
                    fieldName={section.field}
                    fieldTitle={section.title}
                    currentValue={localCanvas[section.field] || ''}
                    onSuggestion={(suggestion) => handleSuggestionApply(section.field, suggestion)}
                    orgData={selectedOrg}
                    canvasType={canvasType}
                    currentStepData={{
                      field: section.field,
                      value: localCanvas[section.field] || ''
                    }}
                    tooltip={tooltips[section.field]}
                    allFormData={localCanvas}
                    selectedOrg={selectedOrg}
                  />
                )}
              </Box>
              {renderInput(section)}
            </Paper>
          </Grid>
        ))}
      </Grid>
      <Dialog
        fullScreen
        open={!!fullscreenField}
        onClose={() => setFullscreenField(null)}
      >
        <AppBar position="fixed">
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={() => setFullscreenField(null)} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" sx={{ ml: 2, flex: 1 }}>
              {sections.find(s => s.field === fullscreenField)?.title}
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent sx={{
          mt: '64px', // Height of the AppBar
          mb: '64px', // Space for the bottom actions
          height: 'calc(100vh - 128px)', // Subtract AppBar height and bottom actions height
          display: 'flex',
          flexDirection: 'column',
          p: 0, // Remove default padding
        }}>
          <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', p: 2 }}>
            <TipTapEditor
              content={localCanvas[fullscreenField] || ''}
              onUpdate={handleEditorUpdate(fullscreenField)}
              editorProps={{
                editorProps: {
                  attributes: {
                    style: 'flex-grow: 1; overflow-y: auto;',
                  },
                },
              }}
            />
          </Box>
        </DialogContent>
        <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0 }}>
          <Toolbar>
            <Button color="inherit" onClick={() => setFullscreenField(null)}>
              Close
            </Button>
          </Toolbar>
        </AppBar>
      </Dialog>
    </Box>
  );
};

BaseCanvas.propTypes = {
  user: PropTypes.object.isRequired,
  selectedOrg: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]).isRequired,
  canvas: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  canvasType: PropTypes.string.isRequired,
  sections: PropTypes.arrayOf(PropTypes.shape({
    field: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    rows: PropTypes.number,
    inputType: PropTypes.oneOf(['richtext', 'select', 'number', 'date']),
    options: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })),
    hideAIAssist: PropTypes.bool,
  })).isRequired,
  tooltips: PropTypes.object.isRequired,
  icon: PropTypes.elementType,
};

export default BaseCanvas;
