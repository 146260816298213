import React, { useState, useEffect } from 'react';
import {
  Box, Typography, Grid, Card, CardContent, CardActions, IconButton,
  TextField, InputAdornment, Chip, Button, Snackbar, Alert, CircularProgress,
  Menu, MenuItem, Divider, List, ListItem, ListItemText,
  ListItemIcon, Drawer, useTheme, useMediaQuery,
  Dialog, DialogTitle, DialogContent, DialogActions,
  FormControl, InputLabel, Select
} from '@mui/material';
import { TreeView, TreeItem } from '@mui/lab'; // Update import to use TreeView from @mui/lab
import {
  Search as SearchIcon, Add as AddIcon, Delete as DeleteIcon,
  Visibility as VisibilityIcon, Folder as FolderIcon, 
  FilterList as FilterIcon, Sort as SortIcon,
  ViewModule as GridViewIcon, ViewList as ListViewIcon,
  ExpandMore as ExpandMoreIcon, ChevronRight as ChevronRightIcon,
  CreateNewFolder as CreateNewFolderIcon,
  MoreVert as MoreVertIcon,
  Edit as EditIcon
} from '@mui/icons-material';
import { listCollateral, deleteCollateral } from '../../services/collateralService';
import CollateralBuilder from './CollateralBuilder';
import { collateralTypes } from '../../constants/collateralTypes';

const CollateralLibrary = ({ selectedOrg, userData }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  const [collateralItems, setCollateralItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCollateral, setSelectedCollateral] = useState(null);
  const [builderOpen, setBuilderOpen] = useState(false);
  const [builderMode, setBuilderMode] = useState('create');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  
  // New state variables for organization
  const [viewMode, setViewMode] = useState('grid'); // 'grid' or 'list'
  const [selectedTab, setSelectedTab] = useState('all');
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [sortAnchorEl, setSortAnchorEl] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState({
    type: [],
    status: [],
    date: 'all'
  });
  const [sortBy, setSortBy] = useState('dateDesc');

  // Add new state for folders
  const [folders, setFolders] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [folderMenuAnchor, setFolderMenuAnchor] = useState(null);
  const [newFolderDialogOpen, setNewFolderDialogOpen] = useState(false);
  const [newFolderName, setNewFolderName] = useState('');
  const [selectedType, setSelectedType] = useState('all');

  useEffect(() => {
    if (selectedOrg) {
      fetchCollateralItems();
    }
  }, [selectedOrg]);

  const fetchCollateralItems = async () => {
    try {
      setLoading(true);
      const items = await listCollateral(selectedOrg);
      setCollateralItems(items);
    } catch (err) {
      setError('Failed to fetch collateral items');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleViewEdit = (item) => {
    setSelectedCollateral(item);
    setBuilderMode('edit');
    setBuilderOpen(true);
  };

  const handleDelete = async (item) => {
    try {
      await deleteCollateral(item.id, selectedOrg);
      setCollateralItems(collateralItems.filter(collateral => collateral.id !== item.id));
      showSnackbar('Collateral deleted successfully', 'success');
    } catch (error) {
      console.error('Error deleting collateral:', error);
      showSnackbar(`Failed to delete collateral: ${error.message}`, 'error');
    }
  };

  const handleCreateNew = () => {
    setSelectedCollateral(null);
    setBuilderMode('create');
    setBuilderOpen(true);
  };

  const handleSaveCollateral = (savedCollateral) => {
    if (builderMode === 'create') {
      setCollateralItems([...collateralItems, savedCollateral]);
    } else {
      setCollateralItems(collateralItems.map(item => 
        item.id === savedCollateral.id ? savedCollateral : item
      ));
    }
    showSnackbar(`Collateral ${builderMode === 'create' ? 'created' : 'updated'} successfully`, 'success');
  };

  const showSnackbar = (message, severity) => {
    setSnackbar({ open: true, message, severity });
  };

  const handleCloseSnackbar = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  // Filter and sort functions
  const getFilteredAndSortedItems = () => {
    let filtered = [...collateralItems];

    // Apply search
    if (searchTerm) {
      filtered = filtered.filter(item => 
        item.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.description?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    // Apply filters
    if (selectedFilters.type.length > 0) {
      filtered = filtered.filter(item => selectedFilters.type.includes(item.type));
    }

    if (selectedFilters.status.length > 0) {
      filtered = filtered.filter(item => selectedFilters.status.includes(item.status));
    }

    // Apply tab filter
    if (selectedTab !== 'all') {
      filtered = filtered.filter(item => item.type === selectedTab);
    }

    // Apply sorting
    filtered.sort((a, b) => {
      switch (sortBy) {
        case 'dateAsc':
          return new Date(a.createdAt) - new Date(b.createdAt);
        case 'dateDesc':
          return new Date(b.createdAt) - new Date(a.createdAt);
        case 'titleAsc':
          return a.title.localeCompare(b.title);
        case 'titleDesc':
          return b.title.localeCompare(a.title);
        default:
          return 0;
      }
    });

    return filtered;
  };

  const renderGridView = (items) => (
    <Grid container spacing={3}>
      {items.map((item) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={item.id}>
          <Card>
            <CardContent>
              <Typography variant="h6" noWrap>{item.title}</Typography>
              <Box display="flex" gap={1} mt={1} flexWrap="wrap">
                <Chip label={item.type} size="small" color="primary" />
                <Chip label={item.status} size="small" />
              </Box>
              <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                {item.description 
                  ? `${item.description.substring(0, 100)}...`
                  : 'No description available'}
              </Typography>
              <Typography variant="caption" display="block" sx={{ mt: 1 }}>
                Created: {new Date(item.createdAt).toLocaleDateString()}
              </Typography>
            </CardContent>
            <CardActions>
              <IconButton onClick={() => handleViewEdit(item)}>
                <VisibilityIcon />
              </IconButton>
              <IconButton onClick={() => handleDelete(item)}>
                <DeleteIcon />
              </IconButton>
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
  );

  const renderListView = (items) => (
    <List>
      {items.map((item) => (
        <ListItem
          key={item.id}
          secondaryAction={
            <Box>
              <IconButton onClick={() => handleViewEdit(item)}>
                <VisibilityIcon />
              </IconButton>
              <IconButton onClick={() => handleDelete(item)}>
                <DeleteIcon />
              </IconButton>
            </Box>
          }
        >
          <ListItemIcon>
            <FolderIcon />
          </ListItemIcon>
          <ListItemText
            primary={item.title}
            secondary={
              <Box>
                <Chip label={item.type} size="small" sx={{ mr: 1 }} />
                <Typography variant="caption">
                  Created: {new Date(item.createdAt).toLocaleDateString()}
                </Typography>
              </Box>
            }
          />
        </ListItem>
      ))}
    </List>
  );

  // Replace Tabs with Filter dropdown in the toolbar
  const renderToolbar = () => (
    <Box sx={{ mb: 3 }}>
      <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
        <TextField
          variant="outlined"
          placeholder="Search collateral..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{ flex: 1 }}
        />
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel>Type</InputLabel>
          <Select
            value={selectedType}
            onChange={(e) => setSelectedType(e.target.value)}
            label="Type"
          >
            <MenuItem value="all">All Types</MenuItem>
            {collateralTypes.map(type => (
              <MenuItem key={type.value} value={type.value}>
                {type.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          startIcon={<FilterIcon />}
          onClick={(e) => setFilterAnchorEl(e.currentTarget)}
        >
          Filter
        </Button>
        <Button
          startIcon={<SortIcon />}
          onClick={(e) => setSortAnchorEl(e.currentTarget)}
        >
          Sort
        </Button>
        <IconButton onClick={() => setViewMode(viewMode === 'grid' ? 'list' : 'grid')}>
          {viewMode === 'grid' ? <ListViewIcon /> : <GridViewIcon />}
        </IconButton>
      </Box>
    </Box>
  );

  // Add folder management components
  const renderFolderTree = () => (
    <Box sx={{ width: 240, p: 2, borderRight: 1, borderColor: 'divider' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Typography variant="h6">Folders</Typography>
        <IconButton onClick={() => setNewFolderDialogOpen(true)} size="small">
          <CreateNewFolderIcon />
        </IconButton>
      </Box>
      <TreeView
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
      >
        <TreeItem 
          nodeId="all" 
          label="All Collateral"
          onClick={() => setSelectedFolder(null)}
        />
        {folders.map((folder) => (
          <TreeItem
            key={folder.id}
            nodeId={folder.id}
            label={
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <FolderIcon sx={{ mr: 1 }} />
                  <Typography>{folder.name}</Typography>
                </Box>
                <IconButton
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    setFolderMenuAnchor(e.currentTarget);
                  }}
                >
                  <MoreVertIcon />
                </IconButton>
              </Box>
            }
            onClick={() => setSelectedFolder(folder)}
          />
        ))}
      </TreeView>
    </Box>
  );

  return (
    <Box sx={{ display: 'flex', height: '100%' }}>
      {renderFolderTree()}
      <Box sx={{ flex: 1, p: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <Typography variant="h4">
            {selectedFolder ? selectedFolder.name : 'All Collateral'}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleCreateNew}
          >
            Create New
          </Button>
        </Box>

        {renderToolbar()}

        {loading ? (
          <CircularProgress />
        ) : error ? (
          <Typography color="error">{error}</Typography>
        ) : (
          viewMode === 'grid' 
            ? renderGridView(getFilteredAndSortedItems())
            : renderListView(getFilteredAndSortedItems())
        )}

        <CollateralBuilder
          open={builderOpen}
          onClose={() => setBuilderOpen(false)}
          collateral={selectedCollateral}
          selectedOrg={selectedOrg}
          mode={builderMode}
          onSave={handleSaveCollateral}
        />

        {/* Filter Menu */}
        <Menu
          anchorEl={filterAnchorEl}
          open={Boolean(filterAnchorEl)}
          onClose={() => setFilterAnchorEl(null)}
        >
          {/* Add filter options */}
        </Menu>

        {/* Sort Menu */}
        <Menu
          anchorEl={sortAnchorEl}
          open={Boolean(sortAnchorEl)}
          onClose={() => setSortAnchorEl(null)}
        >
          {/* Add sort options */}
        </Menu>

        {/* Folder Management Dialogs */}
        <Dialog
          open={newFolderDialogOpen}
          onClose={() => setNewFolderDialogOpen(false)}
        >
          <DialogTitle>Create New Folder</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Folder Name"
              fullWidth
              value={newFolderName}
              onChange={(e) => setNewFolderName(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setNewFolderDialogOpen(false)}>Cancel</Button>
            <Button 
              onClick={() => {
                // Add folder creation logic here
                setFolders([...folders, { 
                  id: `folder-${Date.now()}`, 
                  name: newFolderName 
                }]);
                setNewFolderName('');
                setNewFolderDialogOpen(false);
              }}
              color="primary"
            >
              Create
            </Button>
          </DialogActions>
        </Dialog>

        {/* Folder Context Menu */}
        <Menu
          anchorEl={folderMenuAnchor}
          open={Boolean(folderMenuAnchor)}
          onClose={() => setFolderMenuAnchor(null)}
        >
          <MenuItem onClick={() => {
            // Add rename folder logic
            setFolderMenuAnchor(null);
          }}>
            <ListItemIcon>
              <EditIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Rename</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => {
            // Add delete folder logic
            setFolderMenuAnchor(null);
          }}>
            <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Delete</ListItemText>
          </MenuItem>
        </Menu>

        <Snackbar 
          open={snackbar.open} 
          autoHideDuration={6000} 
          onClose={handleCloseSnackbar}
        >
          <Alert 
            onClose={handleCloseSnackbar} 
            severity={snackbar.severity} 
            sx={{ width: '100%' }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
};

export default CollateralLibrary;
