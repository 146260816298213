import React, { useState, useEffect, useCallback, useMemo, useReducer } from 'react';
import {
  Box, Grid, Paper, Typography, Tabs, Tab, Divider, List, ListItem, ListItemText, ListItemIcon, IconButton, Tooltip, Fade, CircularProgress, Button, Dialog, DialogContent, DialogActions, DialogTitle, TextField, MenuItem, Menu, Skeleton, Snackbar, useTheme, Chip, ListItemButton, Collapse, ListItemSecondaryAction
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import {
  Dashboard as DashboardIcon, Assignment, Group, Event, Description, Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon, EmojiEvents, AttachMoney as MoneyIcon, AccessTime as AccessTimeIcon, Assessment as AssessmentIcon, MoreVert as MoreVertIcon,
  // Add these new imports
  DraftsOutlined,
  SearchOutlined,
  CheckCircleOutline,
  CancelOutlined,
  PlayArrowOutlined,
  SendOutlined,
  AssignmentTurnedInOutlined,
  EmojiEventsOutlined,
  SentimentVeryDissatisfiedOutlined,
  FolderOutlined as DocumentIcon,
  Visibility,
  VisibilityOff,
  Archive as ArchiveIcon,
  Unarchive as UnarchiveIcon,
  ExpandMore,
  ExpandLess,
} from '@mui/icons-material';
import {
  listBids, createBid, updateBid, deleteBid, archiveBid, unarchiveBid, getBidDetails
} from '../../services/bidService';
import {
  getUser, getOrganisationsForUser
} from '../../services/userService';
import {
  getBidCanvas, createBidCanvas, updateBidCanvas, deleteBidCanvas, listBidCanvases
} from '../../services/bidcanvasService';
import BidReadinessScore from '../../components/bids/BidReadinessScore';
import BidTasks from '../../components/bids/BidTasks';
import BidTeams from '../../components/bids/BidTeams';
import CalendarTimeline from '../../components/bids/CalendarTimeline';
import CanvasTable from '../../components/bids/CanvasTable';
import BidStrategyCanvas from '../../components/bids/bidcanvases/BidStrategyCanvas';
import RFPQualificationCanvas from '../../components/bids/bidcanvases/RFPQualificationCanvas';
import { getToken, getActiveAccount } from '../../services/authService';
import bidteamService from '../../services/bidteamService';
import { revokeInvitation } from '../../services/invitationService';
import { getOrganisation, getOrganisationUsers } from '../../services/organisationService';
import Confetti from 'react-confetti';
import BidBudget from '../../components/bids/BidBudget';
import TimeTracking from '../../components/bids/TimeTracking';
import BidGateStatus from '../../components/bids/BidGateStatus';
import { updateBidGate, getBidGate } from '../../services/bidGateService';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import BidDocuments from '../../components/bids/BidDocuments';
import { motion, AnimatePresence } from 'framer-motion';
import { useParams } from 'react-router-dom';
import { useDataFetcher } from '../../hooks/useDataFetcher';
import { format } from 'date-fns';
import DialogContentText from '@mui/material/DialogContentText';
import BidAI from '../../components/bids/BidAI';
import { SmartToy as AIIcon } from '@mui/icons-material';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const BidManagementDashboard = React.memo(({ userId, selectedOrg }) => {
  const { bidId } = useParams(); // Get the bidId from the URL
  ////console.log('BidManagementDashboard - userId:', userId);

  const theme = useTheme();
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogType, setDialogType] = useState('');
  const [editingBid, setEditingBid] = useState({ 
    name: '', 
    client: '', 
    status: 'draft',
    dueDate: format(new Date(), "yyyy-MM-dd'T'HH:mm"), // Set default to current date and time
    visibility: 'invite-only'
  });
  const [user, setUser] = useState(null);
  const [organisation, setOrganisation] = useState(null);
  const [currentTab, setCurrentTab] = useState(0);
  const [canvasDialogState, setCanvasDialogState] = useState({
    open: false,
    type: '',
    data: null,
    versions: [],
  });
  const [canvases, setCanvases] = useState({
    bidStrategyCanvas: null,
    rfpQualificationCanvas: null,
  });
  const [ownerDetails, setOwnerDetails] = useState({});
  const [bidStrategyCanvases, setBidStrategyCanvases] = useState([]);
  const [rfpQualificationCanvases, setRfpQualificationCanvases] = useState([]);
  const [organizationUsers, setOrganizationUsers] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [isLoadingTeams, setIsLoadingTeams] = useState(false);
  const [isLoadingOwner, setIsLoadingOwner] = useState(false);
  const [error, setError] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [isLoadingBidDetails, setIsLoadingBidDetails] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [showCelebration, setShowCelebration] = useState(false);
  const [celebrationMessage, setCelebrationMessage] = useState('');
  const [readinessImprovement, setReadinessImprovement] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuBid, setMenuBid] = useState(null);
  const [bidToDelete, setBidToDelete] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [archiveDialogOpen, setArchiveDialogOpen] = useState(false);
  const [currentGate, setCurrentGate] = useState(0);
  const [bidProgressExpanded, setBidProgressExpanded] = useState(false);
  const [showArchivedBids, setShowArchivedBids] = useState(false);
  const [bidToArchive, setBidToArchive] = useState(null);
  const [deleteCanvasDialog, setDeleteCanvasDialog] = useState({ open: false, canvasType: null, canvasId: null });

  const bidStatusOptions = [
    'draft',
    'qualifying',
    'qualified',
    'qualified out',
    'in progress',
    'approved to submit',
    'approved to contract',
    'won',
    'lost'
  ];

  // Use a reducer for complex state updates
  const bidReducer = (state, action) => {
    switch (action.type) {
      case 'SET_BIDS':
        return {
          ...state,
          allBids: action.payload,
          activeBids: action.payload.filter(bid => bid.status !== 'archived'),
          archivedBids: action.payload.filter(bid => bid.status === 'archived'),
        };
      case 'UPDATE_BID':
        const updatedBids = state.allBids.map(bid => 
          bid.id === action.payload.id ? { ...bid, ...action.payload } : bid
        );
        return {
          ...state,
          allBids: updatedBids,
          activeBids: updatedBids.filter(bid => bid.status !== 'archived'),
          archivedBids: updatedBids.filter(bid => bid.status === 'archived'),
          selectedBid: state.selectedBid?.id === action.payload.id 
            ? { ...state.selectedBid, ...action.payload }
            : state.selectedBid,
        };
      case 'SELECT_BID':
        return { ...state, selectedBid: action.payload };
      default:
        return state;
    }
  };

  const [bidState, dispatchBidAction] = useReducer(bidReducer, {
    allBids: [],
    activeBids: [],
    archivedBids: [],
    selectedBid: null,
  });

  // Use the imported useDataFetcher hook
  const { data: fetchedBids, isLoading: bidsLoading, error: bidsError } = useDataFetcher(
    async () => {
      if (selectedOrg) {
        return await listBids(selectedOrg);
      }
      return [];
    },
    [selectedOrg]
  );

  // Use an effect to update the bid state when fetchedBids changes
  useEffect(() => {
    if (fetchedBids) {
      dispatchBidAction({ type: 'SET_BIDS', payload: fetchedBids });
      if (!bidState.selectedBid && fetchedBids.length > 0) {
        dispatchBidAction({ type: 'SELECT_BID', payload: fetchedBids[0] });
      }
    }
  }, [fetchedBids]);

  // Function to update a bid
  const updateBidState = useCallback((updatedBid) => {
    dispatchBidAction({ type: 'UPDATE_BID', payload: updatedBid });
  }, []);

  // Function to select a bid
  const handleSelectBid = async (bid) => {
    dispatchBidAction({ type: 'SELECT_BID', payload: bid });
    localStorage.setItem('lastSelectedBidId', bid.id);
    
    try {
      const fullBidDetails = await getBidDetails(bid.id, selectedOrg);
      dispatchBidAction({ type: 'UPDATE_BID', payload: { ...fullBidDetails, owner: bid.owner } });
    } catch (error) {
      console.error('Error fetching full bid details:', error);
      setError('Failed to fetch complete bid details. Please try again.');
    }
  };

  // Function to handle bid creation
  const handleCreateBid = async (newBid) => {
    try {
      const createdBid = await createBid({
        ...newBid,
        owner: user.id,
        organisationId: selectedOrg
      });
      updateBidState(createdBid);
      handleSelectBid(createdBid);
      handleOpenSnackbar('Bid created successfully', 'success');
    } catch (error) {
      console.error('Error creating bid:', error);
      handleOpenSnackbar(`Error: ${error.message}. Please try again.`, 'error');
    }
  };

  // Function to handle bid update
  const handleUpdateBid = async (updatedBid) => {
    try {
      const result = await updateBid(updatedBid.id, updatedBid);
      updateBidState(result);
      handleOpenSnackbar('Bid updated successfully', 'success');
    } catch (error) {
      console.error('Error updating bid:', error);
      handleOpenSnackbar(`Error: ${error.message}. Please try again.`, 'error');
    }
  };

  // Function to handle bid archiving
  const handleArchiveBid = async (bid) => {
    try {
      setBidToArchive(bid);
      setArchiveDialogOpen(true);
    } catch (error) {
      console.error('Error preparing bid archiving:', error);
      handleOpenSnackbar(`Error preparing bid archiving: ${error.message}`, 'error');
    }
  };

  const handleArchiveConfirm = async () => {
    if (bidToArchive) {
      try {
        const archivedBid = await archiveBid(bidToArchive.id);
        updateBidState(archivedBid);
        if (bidState.selectedBid?.id === bidToArchive.id) {
          const nextActiveBid = bidState.activeBids.find(b => b.id !== bidToArchive.id);
          if (nextActiveBid) {
            handleSelectBid(nextActiveBid);
          } else {
            dispatchBidAction({ type: 'SELECT_BID', payload: null });
          }
        }
        handleOpenSnackbar('Bid archived successfully', 'success');
      } catch (error) {
        handleOpenSnackbar(`Error archiving bid: ${error.message}`, 'error');
      } finally {
        setArchiveDialogOpen(false);
        setBidToArchive(null);
      }
    }
  };

  // Data fetching hooks
  const { data: userData, isLoading: userLoading } = useDataFetcher(async () => {
    const account = await getActiveAccount();
    return account ? getUser(account.localAccountId) : null;
  }, []);
  const { data: organisationData, isLoading: organisationLoading } = useDataFetcher(async () => {
    const account = await getActiveAccount();
    if (!account) return null;
    const orgs = await getOrganisationsForUser(account.localAccountId);
    return orgs.length > 0 ? getOrganisation(orgs[0]) : null;
  }, []);

  // Effects for setting user and organisation data
  useEffect(() => {
    if (userData) setUser(userData);
  }, [userData]);

  useEffect(() => {
    if (organisationData) setOrganisation(organisationData);
  }, [organisationData]);

  useEffect(() => {
    const fetchBidDetails = async () => {
      if (bidState.selectedBid && organisation) {
        setIsLoadingBidDetails(true);
        try {
          const [ownerData, teamMembersData, orgUsersData] = await Promise.all([
            getUser(bidState.selectedBid.owner),
            bidteamService.getTeamMembers(bidState.selectedBid.id),
            getOrganisationUsers(organisation.id)
          ]);

          updateBidState({ ...bidState.selectedBid, ownerDetails: ownerData });
          setTeamMembers(teamMembersData);
          setOrganizationUsers(Array.isArray(orgUsersData) ? orgUsersData : []);
        } catch (error) {
          console.error('Error fetching bid details:', error);
          setError('Failed to fetch bid details. Please try again later.');
        } finally {
          setIsLoadingBidDetails(false);
        }
      }
    };

    fetchBidDetails();
  }, [bidState.selectedBid?.id, organisation?.id]);

  // Memoized callback functions
  const fetchTeamMembers = useCallback(async () => {
    if (!bidState.selectedBid || !organisation) return;
    setIsLoadingTeams(true);
    try {
      const members = await bidteamService.getTeamMembers(bidState.selectedBid.id);
      setTeamMembers(members);
      const orgUsers = await getOrganisationUsers(organisation.id);
      setOrganizationUsers(Array.isArray(orgUsers) ? orgUsers : []);
    } catch (error) {
      console.error('Error fetching team members or organization users:', error);
      setError('Failed to fetch team members or organization users. Please try again later.');
    } finally {
      setIsLoadingTeams(false);
    }
  }, [bidState.selectedBid?.id, organisation?.id]);

  const handleSaveBid = async () => {
    try {
      if (!selectedOrg) {
        throw new Error('No organisation selected. Please select an organisation before creating a bid.');
      }

      let updatedBid;
      if (dialogType === 'edit') {
        updatedBid = await updateBid(editingBid.id, editingBid);
      } else {
        updatedBid = await createBid({
          ...editingBid,
          owner: user.id,
          organisationId: selectedOrg
        });
      }
      updateBidState(updatedBid);
      handleSelectBid(updatedBid);
      handleCloseDialog();
      handleOpenSnackbar(dialogType === 'create' ? 'Bid created successfully' : 'Bid updated successfully', 'success');
    } catch (error) {
      console.error('Error saving bid:', error);
      handleOpenSnackbar(`Error: ${error.message}. Please try again.`, 'error');
    }
  };

  const handleDeleteBidConfirm = async (bid) => {
    try {
      setBidToDelete(bid);
      setDeleteDialogOpen(true);
    } catch (error) {
      console.error('Error preparing bid deletion:', error);
      handleOpenSnackbar(`Error preparing bid deletion: ${error.message}`, 'error');
    }
  };

  const handleDeleteConfirm = async () => {
    if (bidToDelete) {
      try {
        await deleteBid(bidToDelete.id);
        dispatchBidAction({ type: 'SET_BIDS', payload: bidState.allBids.filter(b => b.id !== bidToDelete.id) });
        if (bidState.selectedBid?.id === bidToDelete.id) {
          const nextActiveBid = bidState.activeBids.find(b => b.id !== bidToDelete.id);
          if (nextActiveBid) {
            handleSelectBid(nextActiveBid);
          } else {
            dispatchBidAction({ type: 'SELECT_BID', payload: null });
          }
        }
        handleOpenSnackbar('Bid deleted successfully', 'success');
      } catch (error) {
        console.error('Error deleting bid:', error);
        handleOpenSnackbar(`Error deleting bid: ${error.message}`, 'error');
      } finally {
        setDeleteDialogOpen(false);
        setBidToDelete(null);
      }
    }
  };

  const handleOpenDialog = (type, bid = null) => {
    setDialogType(type);
    if (type === 'edit' && bid) {
      setEditingBid({
        id: bid.id,
        name: bid.name,
        client: bid.client,
        status: bid.status,
        dueDate: bid.dueDate ? format(new Date(bid.dueDate), "yyyy-MM-dd'T'HH:mm") : '',
        visibility: bid.visibility
      });
    } else {
      setEditingBid({
        name: '',
        client: '',
        status: 'draft',
        dueDate: format(new Date(), "yyyy-MM-dd'T'HH:mm"),
        visibility: 'invite-only'
      });
      
    }
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setEditingBid(null);
  };

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const handleCanvasOpen = (canvasType, canvasId = null) => {
    handleActionClick(canvasType, canvasId);
  };

  const handleActionClick = async (action, canvasId = null) => {
    try {
      let fetchedCanvasData = null;

      if (canvasId) {
        fetchedCanvasData = await getBidCanvas(bidState.selectedBid.id, canvasId);
      } else {
        const existingCanvas = action === 'bidStrategy' 
          ? bidStrategyCanvases[0] 
          : rfpQualificationCanvases[0];

        if (existingCanvas) {
          fetchedCanvasData = existingCanvas;
        } else {
          const canvasType = action === 'bidStrategy' ? 'Bid Strategy Canvas' : 'RFP Qualification Canvas';
          const canvasName = `${bidState.selectedBid.name} - ${canvasType}`;

          fetchedCanvasData = await createBidCanvas(bidState.selectedBid.id, {
            name: canvasName,
            type: canvasType,
            createdBy: user?.id,
          });
        }
      }

      if (!fetchedCanvasData || !fetchedCanvasData.id) {
        console.error('Failed to fetch or create canvas:', fetchedCanvasData);
        return;
      }

      setCanvasDialogState({
        open: true,
        type: action,
        data: fetchedCanvasData,
        versions: fetchedCanvasData.versions || [],
      });
    } catch (error) {
      console.error('Error handling canvas action:', error);
      setSnackbar({
        open: true,
        message: `Error: ${error.message}`,
        severity: 'error'
      });
    }
  };
  
  const handleDeleteCanvas = async (canvasType, canvasId) => {
    setDeleteCanvasDialog({ open: true, canvasType, canvasId });
  };

  const handleConfirmDeleteCanvas = async () => {
    const { canvasType, canvasId } = deleteCanvasDialog;
    if (!bidState.selectedBid || !bidState.selectedBid.id) {
      console.error('No bid selected');
      return;
    }

    try {
      await deleteBidCanvas(bidState.selectedBid.id, canvasId);
      if (canvasType === 'bidStrategy') {
        setBidStrategyCanvases([]);
        updateBidState({ ...bidState.selectedBid, bidStrategyStatus: 'not_started' });
      } else {
        setRfpQualificationCanvases([]);
        updateBidState({ ...bidState.selectedBid, rfpQualificationStatus: 'not_started' });
      }
      setSnackbar({
        open: true,
        message: 'Canvas deleted successfully',
        severity: 'success'
      });
    } catch (error) {
      console.error('Error deleting canvas:', error);
      setSnackbar({
        open: true,
        message: `Error deleting canvas: ${error.message}`,
        severity: 'error'
      });
    } finally {
      setDeleteCanvasDialog({ open: false, canvasType: null, canvasId: null });
    }
  };
  

  const handleCloseCanvasDialog = () => {
    setCanvasDialogState({ open: false, type: '', data: null, versions: [] });
  };

  const handleSaveCanvas = async (savedCanvas, status) => {
    try {
      if (!bidState.selectedBid || !bidState.selectedBid.id) {
        throw new Error('No bid selected');
      }

      let updatedCanvas;
      if (savedCanvas.id) {
        updatedCanvas = await updateBidCanvas(bidState.selectedBid.id, savedCanvas.id, {
          ...savedCanvas,
          status,
          updatedAt: new Date().toISOString()
        });
      } else {
        updatedCanvas = await createBidCanvas(bidState.selectedBid.id, {
          ...savedCanvas,
          status,
          type: canvasDialogState.type === 'bidStrategy' ? 'Bid Strategy Canvas' : 'RFP Qualification Canvas',
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
          createdBy: user?.id
        });
      }

      // Update the correct canvas state
      if (updatedCanvas.type === 'Bid Strategy Canvas') {
        setBidStrategyCanvases([updatedCanvas]);
      } else if (updatedCanvas.type === 'RFP Qualification Canvas') {
        setRfpQualificationCanvases([updatedCanvas]);
      }

      setCanvases(prev => ({
        ...prev,
        [canvasDialogState.type === 'bidStrategy' ? 'bidStrategyCanvas' : 'rfpQualificationCanvas']: updatedCanvas,
      }));

      setCanvasDialogState(prev => ({ ...prev, open: false }));
      
      // Show success message
      setSnackbar({
        open: true,
        message: `Canvas ${status === 'completed' ? 'completed' : 'saved as draft'} successfully`,
        severity: 'success'
      });

      // Update bid readiness
      const oldReadiness = calculateBidReadiness(bidState.selectedBid);
      const updatedBid = {
        ...bidState.selectedBid,
        [canvasDialogState.type === 'bidStrategy' ? 'bidStrategyStatus' : 'rfpQualificationStatus']: status
      };
      const newReadiness = calculateBidReadiness(updatedBid);
      const improvement = newReadiness - oldReadiness;

      updateBidState(updatedBid);

      // Only show celebration for first-time completion
      if (status === 'completed' && improvement > 0) {
        const canvasType = canvasDialogState.type === 'bidStrategy' ? 'Bid Strategy Canvas' : 'RFP Qualification Canvas';
        const previousStatus = bidState.selectedBid[canvasDialogState.type === 'bidStrategy' ? 'bidStrategyStatus' : 'rfpQualificationStatus'];
        
        if (previousStatus !== 'completed') {
          setReadinessImprovement(improvement);
          setCelebrationMessage(`Congratulations! You've completed the ${canvasType}.`);
          setShowCelebration(true);
          setShowConfetti(true);
          setTimeout(() => setShowConfetti(false), 5000);
        }
      }

    } catch (error) {
      console.error('Error saving canvas:', error);
      setSnackbar({
        open: true,
        message: `Error saving canvas: ${error.message}`,
        severity: 'error'
      });
    }
  };
  

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return !isNaN(date) ? date.toLocaleDateString() : 'Invalid Date';
  };

  const fetchCanvases = useCallback(async (bidId) => {
    if (!bidId || typeof bidId !== 'string') {
      console.error('Invalid bidId:', bidId);
      setError('Invalid bid ID. Unable to fetch canvases.');
      return;
    }
    try {
      const authToken = await getToken();
      const canvases = await listBidCanvases(bidId, authToken);
      const bidStrategyCanvases = canvases.filter(canvas => canvas.type === 'Bid Strategy Canvas');
      const rfpQualificationCanvases = canvases.filter(canvas => canvas.type === 'RFP Qualification Canvas');
      setBidStrategyCanvases(bidStrategyCanvases);
      setRfpQualificationCanvases(rfpQualificationCanvases);
    } catch (error) {
      console.error('Error fetching canvases:', error);
      setError('Failed to fetch canvases. Please try again later.');
    }
  }, [getToken, listBidCanvases, setBidStrategyCanvases, setRfpQualificationCanvases, setError]);

  useEffect(() => {
    if (bidState.selectedBid?.id) {
      fetchCanvases(bidState.selectedBid.id);
    }
  }, [bidState.selectedBid?.id, fetchCanvases]);

  const handleTeamUpdate = useCallback(async () => {
    if (!bidState.selectedBid) return;
    
    try {
      const updatedTeamMembers = await bidteamService.getTeamMembers(bidState.selectedBid.id);
      setTeamMembers(updatedTeamMembers);
      
      // Fetch the updated bid details
      const updatedBid = await getBidDetails(bidState.selectedBid.id, selectedOrg);
      updateBidState(updatedBid);

      setSnackbar({
        open: true,
        message: 'Team updated successfully',
        severity: 'success'
      });
    } catch (error) {
      console.error('Error updating team:', error);
      setSnackbar({
        open: true,
        message: 'Failed to update team. Please try again.',
        severity: 'error'
      });
    }
  }, [bidState.selectedBid, selectedOrg, getBidDetails, updateBidState]);

  const refreshAuthToken = async () => {
    try {
      await refreshToken();
      return await getToken();
    } catch (error) {
      console.error('Error refreshing token:', error);
      // Handle logout or redirect to login page
      return null;
    }
  };

  // Memoized components
  const MemoizedBidTasks = useMemo(() => 
    bidState.selectedBid ? <BidTasks bidId={bidState.selectedBid.id} userId={userId} /> : null,
    [bidState.selectedBid, userId]
  );

  const MemoizedBidTeams = useMemo(() => 
    bidState.selectedBid && !isLoadingBidDetails ? (
      <BidTeams
        bidId={bidState.selectedBid.id}
        userId={userId}
        owner={bidState.selectedBid.owner}
        teamMembers={teamMembers}
        selectedOrg={selectedOrg}  // Make sure this prop is being passed
        isLoading={isLoadingBidDetails || isLoadingTeams}
        onTeamUpdate={handleTeamUpdate}
        bidVisibility={bidState.selectedBid.visibility}
      />
    ) : null,
    [bidState.selectedBid, userId, teamMembers, selectedOrg, isLoadingTeams, handleTeamUpdate, isLoadingBidDetails]
  );

  const handleRevokeInvitation = async (orgId, invitationId) => {
    try {
      await revokeInvitation(orgId, invitationId);
      // If successful, update your state or UI accordingly
      setInvitations(prevInvitations => prevInvitations.filter(inv => inv.id !== invitationId));
      // Show success message
      ////console.log('Invitation revoked successfully');
      // You might want to show a success message to the user
    } catch (error) {
      console.error('Failed to revoke invitation:', error.message);
      // Handle specific error cases
      if (error.message.includes('Invitation not found')) {
        ////console.log('This invitation has already been revoked or does not exist.');
        // Remove the invitation from the list if it doesn't exist
        setInvitations(prevInvitations => prevInvitations.filter(inv => inv.id !== invitationId));
      } else if (error.message.includes('permission')) {
        ////console.log('You do not have permission to revoke this invitation.');
      } else {
        ////console.log('An unexpected error occurred. Please try again later.');
      }
      // You might want to show these error messages to the user in a toast or alert
    }
  };

  const handleReadinessAction = (action) => {
    switch (action) {
      case 'bidStrategy':
        handleActionClick('bidStrategy');
        break;
      case 'rfpQualification':
        handleActionClick('rfpQualification');
        break;
      case 'tasks':
        setCurrentTab(0); // Switch to Tasks tab
        break;
      case 'team':
        setCurrentTab(1); // Switch to Teams tab
        break;
      default:
        ////console.log('Unknown action:', action);
    }
  };

  const updateBidReadiness = useCallback(() => {
    if (bidState.selectedBid) {
      const updatedBid = { ...bidState.selectedBid };
      updatedBid.bidStrategyStatus = bidStrategyCanvases[0]?.status || 'not_started';
      updatedBid.rfpQualificationStatus = rfpQualificationCanvases[0]?.status || 'not_started';
      updateBidState(updatedBid);
      // You might want to update this bid in your backend as well
    }
  }, [bidState.selectedBid, bidStrategyCanvases, rfpQualificationCanvases]);

  const calculateBidReadiness = (bid) => {
    const getCanvasScore = (status) => {
      switch (status) {
        case 'completed': return 25;
        case 'draft': return 10;
        default: return 0;
      }
    };

    const strategyScore = getCanvasScore(bid.bidStrategyStatus);
    const rfpScore = getCanvasScore(bid.rfpQualificationStatus);
    const tasksScore = bid.tasks && bid.tasks.length > 0 ? Math.min(25, bid.tasks.length * 2.5) : 0;
    const teamScore = bid.teamMembers && bid.teamMembers.length > 0 ? Math.min(25, bid.teamMembers.length * 5) : 0;

    return Math.round(strategyScore + rfpScore + tasksScore + teamScore);
  };

  const handleCloseCelebration = () => {
    setShowCelebration(false);
  };

  const renderQuickActions = () => {
    const actions = [];

    // Bid Strategy Canvas actions
    if (!bidStrategyCanvases.length) {
      actions.push(
        <Button
          key="createBidStrategy"
          variant="contained"
          color="primary"
          onClick={() => handleActionClick('bidStrategy')}
          sx={{ mr: 1, mb: 1 }}
        >
          Create Bid Strategy Canvas
        </Button>
      );
    } else {
      actions.push(
        <Button
          key="updateBidStrategy"
          variant="outlined"
          color="primary"
          onClick={() => handleActionClick('bidStrategy', bidStrategyCanvases[0].id)}
          sx={{ mr: 1, mb: 1 }}
        >
          Update Bid Strategy Canvas
        </Button>
      );
    }

    // RFP Qualification Canvas actions
    if (!rfpQualificationCanvases.length) {
      actions.push(
        <Button
          key="createRFPQualification"
          variant="contained"
          color="secondary"
          onClick={() => handleActionClick('rfpQualification')}
          sx={{ mb: 1 }}
        >
          Create RFP Qualification Canvas
        </Button>
      );
    } else {
      actions.push(
        <Button
          key="updateRFPQualification"
          variant="outlined"
          color="secondary"
          onClick={() => handleActionClick('rfpQualification', rfpQualificationCanvases[0].id)}
          sx={{ mb: 1 }}
        >
          Update RFP Qualification Canvas
        </Button>
      );
    }

    // Team actions
    if (!teamMembers.length) {
      actions.push(
        <Button
          key="addTeamMembers"
          variant="contained"
          color="info"
          onClick={() => setCurrentTab(2)} // Switch to Teams tab
          sx={{ mr: 1, mb: 1 }}
        >
          Add Team Members
        </Button>
      );
    }

    // Task actions
    const overdueTasks = bidState.selectedBid.tasks ? bidState.selectedBid.tasks.filter(task => 
      new Date(task.dueDate) < new Date() && task.status !== 'completed'
    ) : [];

    if (overdueTasks.length > 0) {
      actions.push(
        <Button
          key="reviewOverdueTasks"
          variant="contained"
          color="error"
          onClick={() => setCurrentTab(1)} // Switch to Tasks tab
          sx={{ mb: 1 }}
        >
          Review Overdue Tasks ({overdueTasks.length})
        </Button>
      );
    } else if (!bidState.selectedBid.tasks || bidState.selectedBid.tasks.length === 0) {
      actions.push(
        <Button
          key="createTasks"
          variant="contained"
          color="success"
          onClick={() => setCurrentTab(1)} // Switch to Tasks tab
          sx={{ mb: 1 }}
        >
          Create Tasks
        </Button>
      );
    }

    return actions;
  };

  const handleMenuOpen = (event, bid, isArchived) => {
    setAnchorEl(event.currentTarget);
    setMenuBid({ ...bid, isArchived });
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuBid(null);
  };

  const handleViewBid = () => {
    handleSelectBid(menuBid);
    handleMenuClose();
  };

  const handleEditBid = () => {
    handleOpenDialog('edit', menuBid);
    handleMenuClose();
  };

  const handleUnarchiveBid = async (bid) => {
    try {
      const unarchivedBid = await unarchiveBid(bid.id);
      updateBidState(unarchivedBid);
      handleOpenSnackbar('Bid unarchived successfully', 'success');
    } catch (error) {
      handleOpenSnackbar(`Error unarchiving bid: ${error.message}`, 'error');
    }
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case 'draft':
        return <DraftsOutlined fontSize="small" />;
      case 'qualifying':
        return <SearchOutlined fontSize="small" />;
      case 'qualified':
        return <CheckCircleOutline fontSize="small" />;
      case 'qualified out':
        return <CancelOutlined fontSize="small" />;
      case 'in progress':
        return <PlayArrowOutlined fontSize="small" />;
      case 'approved to submit':
        return <SendOutlined fontSize="small" />;
      case 'approved to contract':
        return <AssignmentTurnedInOutlined fontSize="small" />;
      case 'won':
        return <EmojiEventsOutlined fontSize="small" />;
      case 'lost':
        return <SentimentVeryDissatisfiedOutlined fontSize="small" />;
      default:
        return null;
    }
  };

  const memoizedBids = useMemo(() => bidState.allBids, [bidState.allBids]);

  useEffect(() => {
    const fetchOwnerDetails = async () => {
      const ownerIds = [...new Set(bidState.allBids.map(bid => bid.owner))];
      const ownerDetailsPromises = ownerIds.map(async (ownerId) => {
        if (ownerId && !ownerDetails[ownerId]) {
          try {
            const userDetails = await getUser(ownerId);
            return { [ownerId]: userDetails };
          } catch (error) {
            console.error(`Error fetching owner details for ID ${ownerId}:`, error);
            return { [ownerId]: null };
          }
        }
        return null;
      });

      const newOwnerDetails = await Promise.all(ownerDetailsPromises);
      setOwnerDetails(prevDetails => ({
        ...prevDetails,
        ...Object.assign({}, ...newOwnerDetails.filter(Boolean))
      }));
    };

    if (bidState.allBids.length > 0) {
      fetchOwnerDetails();
    }
  }, [bidState.allBids]);

  const handleVisibilityChange = async (bid) => {
    const newVisibility = bid.visibility === 'organisation' ? 'invite-only' : 'organisation';
    try {
      const updatedBid = await updateBid(bid.id, { visibility: newVisibility });
      updateBidState(updatedBid);
      setSnackbar({
        open: true,
        message: `Bid visibility changed to ${newVisibility === 'organisation' ? 'Organisation-wide' : 'Invite-only'}`,
        severity: 'success'
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: `Error updating bid visibility: ${error.message}`,
        severity: 'error'
      });
    }
  };

  const toggleArchivedBids = () => {
    setShowArchivedBids(!showArchivedBids);
  };

  const renderBidList = (bids, isArchived) => (
    <List>
      {bids.map((bid) => (
        <ListItem
          key={bid.id}
          button
          selected={bidState.selectedBid?.id === bid.id}
          onClick={() => handleSelectBid(bid)}
          sx={{
            opacity: isArchived ? 0.6 : 1,
          }}
        >
          <ListItemText
            primary={bid.name}
            secondary={`Status: ${bid.status}`}
            primaryTypographyProps={{
              color: isArchived ? 'text.secondary' : 'text.primary',
            }}
          />
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="more options" onClick={(e) => handleMenuOpen(e, bid, isArchived)}>
              <MoreVertIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  );

  const handleUpdateGate = (newGate) => {
    setCurrentGate(newGate);
  };

  const renderNoBidsView = () => (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
      <Paper elevation={3} sx={{ p: 4, maxWidth: 600, textAlign: 'center' }}>
        <Typography variant="h4" gutterBottom>
          Welcome to Bid Management!
        </Typography>
        <Typography variant="body1" paragraph>
          You haven't created any bids yet. Start your journey to winning more business by creating your first bid.
        </Typography>
        <Grid container spacing={3} sx={{ mt: 2, mb: 4 }}>
          <Grid item xs={12} sm={6}>
            <List>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleOutlineIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Organize your bid process" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleOutlineIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Collaborate with your team" />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} sm={6}>
            <List>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleOutlineIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Track progress and deadlines" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleOutlineIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Increase your win rate" />
              </ListItem>
            </List>
          </Grid>
        </Grid>
        <Button
          variant="contained"
          color="primary"
          size="large"
          startIcon={<AddCircleOutlineIcon />}
          onClick={() => handleOpenDialog('create')}
        >
          Create Your First Bid
        </Button>
      </Paper>
    </Box>
  );

  const handleGateAction = (action) => {
    switch (action) {
      case 'setupBudget':
        // Open budget feature
        break;
      case 'completeRFPCanvas':
        // Open RFP Qualification Canvas
        break;
      case 'completeBidStrategyCanvas':
        // Open Bid Strategy Canvas
        break;
      case 'makeBidDecision':
        // Open Bid/No-Bid decision dialog
        // This should only be accessible if all prerequisites are met
        break;
      // ... handle other actions
    }
  };

  const renderLoadingSkeleton = () => (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Grid container spacing={3}>
        {/* Bid List Skeleton */}
        <Grid item xs={12} md={3}>
          <Paper elevation={3} sx={{ p: 2, height: '100%' }}>
            <Typography variant="h6" gutterBottom>
              <Skeleton width="60%" />
            </Typography>
            <List>
              {[1, 2, 3, 4, 5].map((_, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                >
                  <ListItem>
                    <ListItemIcon>
                      <Skeleton variant="circular" width={40} height={40} />
                    </ListItemIcon>
                    <ListItemText
                      primary={<Skeleton width="80%" />}
                      secondary={<Skeleton width="60%" />}
                    />
                  </ListItem>
                </motion.div>
              ))}
            </List>
          </Paper>
        </Grid>

        {/* Main Content Skeleton */}
        <Grid item xs={12} md={9}>
          <Paper elevation={3} sx={{ p: 2, height: '100%' }}>
            <Tabs value={0}>
              {['Overview', 'Tasks', 'Team', 'Timeline', 'Canvases'].map((tab, index) => (
                <Tab key={index} label={<Skeleton width={60} />} />
              ))}
            </Tabs>
            <Box sx={{ mt: 3 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Skeleton variant="rectangular" height={200} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Skeleton variant="rectangular" height={200} />
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );

  if (bidsLoading || userLoading || organisationLoading) {
    return (
      <Fade in={true} style={{ transitionDelay: '300ms' }}>
        <div>{renderLoadingSkeleton()}</div>
      </Fade>
    );
  }

  const handleOpenSnackbar = (message, severity = 'info') => {
    setSnackbar({ open: true, message, severity });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  const toggleBidProgress = () => {
    setBidProgressExpanded(!bidProgressExpanded);
  };

  return (
    <Box sx={{ width: '100%', display: 'flex', height: 'calc(100vh - 64px)' }}>
      {/* Left Sidebar - Bid List */}
      <Paper elevation={3} sx={{ width: 300, minWidth: 300, overflowY: 'auto', display: 'flex', flexDirection: 'column', mr: 2 }}>
        <Box sx={{ p: 2, flexGrow: 1, overflowY: 'auto' }}>
          <Typography variant="h6" gutterBottom>Active Bids</Typography>
          <Tooltip title="Create New Bid">
            <IconButton color="primary" onClick={() => handleOpenDialog('create')}>
              <AddIcon />
            </IconButton>
          </Tooltip>
          <Divider sx={{ my: 2 }} />
          {renderBidList(bidState.activeBids, false)}

          <ListItem button onClick={toggleArchivedBids}>
            <ListItemIcon>
              {showArchivedBids ? <ExpandLess /> : <ExpandMore />}
            </ListItemIcon>
            <ListItemText 
              primary="Archived Bids" 
              primaryTypographyProps={{ color: 'text.secondary' }}
            />
          </ListItem>
          <Collapse in={showArchivedBids} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {renderBidList(bidState.archivedBids, true)}
            </List>
          </Collapse>
        </Box>
      </Paper>

     {/* Main Content Area */}
<Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflowY: 'auto' }}>
  {bidState.activeBids.length > 0 && bidState.selectedBid ? (
    <>
      <Paper elevation={3} sx={{ mb: 2 }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', bgcolor: 'background.paper' }}>
          <Typography variant="h6" sx={{ p: 2, fontWeight: 'medium', color: theme.palette.primary.main }}>
            {bidState.selectedBid.name}
          </Typography>
          <Tabs value={currentTab} onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
            <Tab icon={<DashboardIcon />} label="Overview" />
            <Tab icon={<Assignment />} label="Tasks" />
            <Tab icon={<Group />} label="Team" />
            <Tab icon={<Event />} label="Timeline" />
            <Tab icon={<Description />} label="Bid Canvases" />
            <Tab icon={<MoneyIcon />} label="Budget" />
            <Tab icon={<AccessTimeIcon />} label="Time Tracking" />
            <Tab icon={<DocumentIcon />} label="Documents" />
          </Tabs>
        </Box>
      </Paper>
      <Box sx={{ p: 3, flexGrow: 1, overflowY: 'auto' }}>
        {isLoadingBidDetails ? (
          <CircularProgress />
        ) : (
          <>
            <Paper elevation={2} sx={{ mb: 2 }}>
              <Box sx={{
                p: 1,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderBottom: bidProgressExpanded ? 1 : 0,
                borderColor: 'divider'
              }}>
                <Typography variant="subtitle1">Bid Progress</Typography>
                <IconButton onClick={toggleBidProgress} size="small">
                  {bidProgressExpanded ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              </Box>
              <BidGateStatus
                bidId={bidState.selectedBid.id}
                currentGate={currentGate}
                onUpdateGate={handleUpdateGate}
                onAction={handleGateAction}
                onTabChange={(tabIndex) => setCurrentTab(tabIndex)}
                onCanvasOpen={handleCanvasOpen}
                existingCanvases={{
                  rfpQualification: rfpQualificationCanvases[0],
                  bidStrategy: bidStrategyCanvases[0]
                }}
                isExpanded={bidProgressExpanded}
                onExpand={toggleBidProgress}
              />
            </Paper>
            {currentTab === 0 && (
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Paper elevation={2} sx={{ p: 2 }}>
                    <Typography variant="h6" gutterBottom>Bid Details</Typography>
                    <Typography>Name: {bidState.selectedBid.name}</Typography>
                    <Typography>Client: {bidState.selectedBid.client}</Typography>
                    <Typography>Status: {bidState.selectedBid.status}</Typography>
                    <Typography>
                      Owner: {ownerDetails[bidState.selectedBid.owner] ?
                        (ownerDetails[bidState.selectedBid.owner].name || ownerDetails[bidState.selectedBid.owner].email) :
                        'Loading...'}
                    </Typography>
                    <Typography>
                      Visibility: {bidState.selectedBid.visibility === 'organisation' ? 'Organisation' : 'Invite-only'}
                    </Typography>
                    <Box sx={{ mt: 2 }}>
                      <Chip
                        icon={bidState.selectedBid.visibility === 'organisation' ? <Visibility /> : <VisibilityOff />}
                        label={bidState.selectedBid.visibility === 'organisation' ? 'Organisation' : 'Invite-only'}
                        color={bidState.selectedBid.visibility === 'organisation' ? 'primary' : 'default'}
                        variant="outlined"
                        onClick={() => handleVisibilityChange(bidState.selectedBid)}
                        sx={{ cursor: 'pointer' }}
                      />
                    </Box>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Paper elevation={2} sx={{ p: 2 }}>
                    <Typography variant="h6" gutterBottom>Quick Actions</Typography>
                    {renderQuickActions()}
                  </Paper>
                </Grid>
              </Grid>
            )}
            {currentTab === 1 && <BidTasks bidId={bidState.selectedBid.id} />}
            {currentTab === 2 && (
              <BidTeams
                bidId={bidState.selectedBid.id}
                userId={userId}
                owner={bidState.selectedBid.owner}
                teamMembers={teamMembers}
                selectedOrg={selectedOrg}
                isLoading={isLoadingBidDetails || isLoadingTeams}
                onTeamUpdate={handleTeamUpdate}
                bidVisibility={bidState.selectedBid.visibility}
                updateBidState={updateBidState}
              />
            )}
            {currentTab === 3 && (
              <CalendarTimeline
                bidId={bidState.selectedBid.id}
                organisationId={selectedOrg}
                bidVisibility={bidState.selectedBid.visibility}
              />
            )}
            {currentTab === 4 && (
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <CanvasTable
                    canvasType="bidStrategy"
                    canvases={bidStrategyCanvases}
                    onActionClick={handleActionClick}
                    onDeleteCanvas={handleDeleteCanvas}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CanvasTable
                    canvasType="rfpQualification"
                    canvases={rfpQualificationCanvases}
                    onActionClick={handleActionClick}
                    onDeleteCanvas={handleDeleteCanvas}
                  />
                </Grid>
              </Grid>
            )}
            {currentTab === 5 && <BidBudget bidId={bidState.selectedBid.id} />}
            {currentTab === 6 && <TimeTracking bidId={bidState.selectedBid.id} />}
            {currentTab === 7 && <BidDocuments bidId={bidState.selectedBid.id} bidName={bidState.selectedBid.name} />}
          </>
        )}
      </Box>

      {/* Add BidAI component here */}
      <BidAI
        bid={bidState.selectedBid}
        userProfile={user}
        organisation={organisation}
        toneOfVoiceData={organisation?.toneOfVoice}
        onBidUpdate={handleUpdateBid}
      />
    </>
  ) : (
    renderNoBidsView()
  )}
</Box>


      {/* Dialog for creating/editing bids */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{dialogType === 'create' ? 'Create New Bid' : 'Edit Bid'}</DialogTitle>
        <DialogContent>
          <TextField
            label="Bid Name"
            value={editingBid?.name || ''}
            onChange={(e) => setEditingBid(prev => ({ ...prev, name: e.target.value }))}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Client"
            value={editingBid?.client || ''}
            onChange={(e) => setEditingBid(prev => ({ ...prev, client: e.target.value }))}
            fullWidth
            margin="normal"
          />
          <TextField
            select
            label="Status"
            value={editingBid?.status || 'draft'}
            onChange={(e) => setEditingBid(prev => ({ ...prev, status: e.target.value }))}
            fullWidth
            margin="normal"
          >
            {bidStatusOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option.charAt(0).toUpperCase() + option.slice(1)}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="Due Date and Time"
            type="datetime-local"
            value={editingBid?.dueDate || ''}
            onChange={(e) => setEditingBid(prev => ({ ...prev, dueDate: e.target.value }))}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            select
            label="Visibility"
            value={editingBid?.visibility || 'invite-only'}
            onChange={(e) => setEditingBid(prev => ({ ...prev, visibility: e.target.value }))}
            fullWidth
            margin="normal"
          >
            <MenuItem value="organisation">Visible to entire organisation</MenuItem>
            <MenuItem value="invite-only">Invite-only</MenuItem>
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleSaveBid} color="primary">
            {dialogType === 'create' ? 'Create' : 'Update'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Canvas Dialog */}
      <Dialog 
        open={canvasDialogState.open} 
        onClose={() => setCanvasDialogState(prev => ({ ...prev, open: false }))}
        fullScreen
        PaperProps={{
          style: {
            backgroundColor: 'rgba(255, 255, 255, 0.95)', // Semi-transparent white background
          },
        }}
      >
        <DialogContent sx={{ p: 0 }}>
          {canvasDialogState.type === 'bidStrategy' ? (
            <BidStrategyCanvas
              user={user}
              selectedOrg={organisation}
              canvas={canvasDialogState.data}
              onClose={() => setCanvasDialogState(prev => ({ ...prev, open: false }))}
              onSave={handleSaveCanvas}
              bidId={bidState.selectedBid?.id}
              bidName={bidState.selectedBid?.name}  // Make sure this is being passed
            />
          ) : (
            <RFPQualificationCanvas
              user={user}
              selectedOrg={organisation}
              canvas={canvasDialogState.data}
              onClose={() => setCanvasDialogState(prev => ({ ...prev, open: false }))}
              onSave={handleSaveCanvas}
              bidId={bidState.selectedBid?.id}
              bidName={bidState.selectedBid?.name}  // Make sure this is being passed
            />
          )}
        </DialogContent>
      </Dialog>

      {/* Celebration Modal */}
      <Dialog
        open={showCelebration}
        onClose={handleCloseCelebration}
        maxWidth="sm"
        fullWidth
      >
        <DialogContent>
          <Box textAlign="center" py={3}>
            <EmojiEvents color="primary" sx={{ fontSize: 60, mb: 2 }} />
            <Typography variant="h4" gutterBottom>
              {celebrationMessage}
            </Typography>
            <Typography variant="h6" color="primary">
              You've improved your bid readiness!
            </Typography>
            <Typography variant="body1" mt={2}>
              Keep up the great work!
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCelebration} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {showConfetti && <Confetti />}

      {/* Archive Confirmation Dialog */}
      <Dialog open={archiveDialogOpen} onClose={() => setArchiveDialogOpen(false)}>
        <DialogTitle>Confirm Archive</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to archive the bid "{bidToArchive?.name}"? 
            You can unarchive it later if needed.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setArchiveDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleArchiveConfirm} color="primary">Archive</Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography gutterBottom>
            Are you sure you want to delete the bid "{bidToDelete?.name}"? 
            This action cannot be undone and will delete all associated data.
          </Typography>
          <Typography variant="body2" color="error">
            Warning: Archiving is preferred to maintain prior bid history and analytics. 
            Deletion is irreversible.
          </Typography>
          {bidToDelete?.childObjectCounts && (
            <Box mt={2}>
              <Typography variant="subtitle2">This will delete:</Typography>
              <ul>
                <li>{bidToDelete.childObjectCounts.tasks} tasks</li>
                <li>{bidToDelete.childObjectCounts.teamMembers} team members</li>
                <li>{bidToDelete.childObjectCounts.canvases} canvases</li>
                <li>{bidToDelete.childObjectCounts.documents} documents</li>
              </ul>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} color="error">Delete</Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for notifications */}
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {!menuBid?.isArchived ? (
          [  // Use an array instead of a Fragment
            <MenuItem key="view" onClick={handleViewBid}>View</MenuItem>,
            <MenuItem key="edit" onClick={handleEditBid}>Edit</MenuItem>,
            <MenuItem key="archive" onClick={() => handleArchiveBid(menuBid)}>Archive</MenuItem>
          ]
        ) : (
          <MenuItem onClick={() => handleUnarchiveBid(menuBid)}>Unarchive</MenuItem>
        )}
        <MenuItem onClick={() => handleDeleteBidConfirm(menuBid)}>Delete</MenuItem>
      </Menu>

      {/* Delete Canvas Confirmation Dialog */}
      <Dialog
        open={deleteCanvasDialog.open}
        onClose={() => setDeleteCanvasDialog({ open: false, canvasType: null, canvasId: null })}
      >
        <DialogTitle>Confirm Canvas Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this {deleteCanvasDialog.canvasType === 'bidStrategy' ? 'Bid Strategy' : 'RFP Qualification'} Canvas? 
            This action cannot be undone and all data associated with this canvas will be permanently lost.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteCanvasDialog({ open: false, canvasType: null, canvasId: null })} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDeleteCanvas} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
});

export default BidManagementDashboard;