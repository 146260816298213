import React from 'react';
import { Document, Page, Text, View, StyleSheet, Font, Image } from '@react-pdf/renderer';
import { theme } from '../../theme';

// Register fonts
Font.register({
  family: 'Roboto',
  fonts: [
    { src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf', fontWeight: 300 },
    { src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf', fontWeight: 400 },
    { src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf', fontWeight: 500 },
    { src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf', fontWeight: 700 },
  ]
});

const StoryBrandPDF = ({ canvasData = {} }) => {
  const styles = StyleSheet.create({
    page: {
      flexDirection: 'column',
      backgroundColor: '#FFFFFF',
      padding: 30,
      fontFamily: 'Roboto',
    },
    header: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 20,
      borderBottom: `2 solid ${theme.palette.primary.main}`,
      paddingBottom: 10,
    },
    logoContainer: {
      width: 150,
      height: 75,
    },
    logo: {
      objectFit: 'contain',
      width: '100%',
      height: '100%',
    },
    title: {
      fontSize: 24,
      fontWeight: 700,
      color: theme.palette.primary.main,
    },
    section: {
      margin: '10 0',
      padding: 10,
      borderLeft: `4 solid ${theme.palette.primary.main}`,
    },
    sectionTitle: {
      fontSize: 16,
      fontWeight: 500,
      color: theme.palette.primary.main,
      marginBottom: 5,
    },
    text: {
      fontSize: 12,
      lineHeight: 1.5,
    },
    footer: {
      position: 'absolute',
      bottom: 30,
      left: 30,
      right: 30,
      textAlign: 'center',
      color: theme.palette.text.secondary,
      fontSize: 10,
      borderTop: `1 solid ${theme.palette.divider}`,
      paddingTop: 10,
    },
  });

  const sections = [
    { title: 'Character', key: 'character' },
    { title: 'Problem - External', key: 'problemExternal' },
    { title: 'Problem - Internal', key: 'problemInternal' },
    { title: 'Problem - Philosophical', key: 'problemPhilosophical' },
    { title: 'Guide - Empathy', key: 'guideEmpathy' },
    { title: 'Guide - Authority', key: 'guideAuthority' },
    { title: 'Plan', key: 'plan' },
    { title: 'Call to Action', key: 'callToAction' },
    { title: 'Success', key: 'success' },
    { title: 'Failure', key: 'failure' },
    { title: 'Transformation', key: 'transformation' },
  ];

  const stripHtmlAndEntities = (html) => {
    if (!html) return 'Not defined';
    return html
      .replace(/<[^>]+>/g, '') // Remove HTML tags
      .replace(/&nbsp;/g, ' ') // Replace &nbsp; with space
      .replace(/&amp;/g, '&') // Replace &amp; with &
      .replace(/&lt;/g, '<') // Replace &lt; with <
      .replace(/&gt;/g, '>') // Replace &gt; with >
      .replace(/&quot;/g, '"') // Replace &quot; with "
      .replace(/&#39;/g, "'") // Replace &#39; with '
      .replace(/\s+/g, ' ') // Replace multiple spaces with single space
      .trim();
  };

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <View style={styles.logoContainer}>
            <Image 
              src="/images/Puawai_Logo_Green_wText.png" 
              style={styles.logo} 
            />
          </View>
          <Text style={styles.title}>StoryBrand Canvas</Text>
        </View>
        
        {sections.map(({ title, key }) => (
          <View key={key} style={styles.section}>
            <Text style={styles.sectionTitle}>{title}</Text>
            <Text style={styles.text}>{stripHtmlAndEntities(canvasData[key])}</Text>
          </View>
        ))}

        <Text style={styles.footer}>
          © {new Date().getFullYear()} StepFWD Limited. All rights reserved.
        </Text>
      </Page>
    </Document>
  );
};

export default StoryBrandPDF;