import React, { useState, useEffect, useCallback } from 'react';
import { Box, Typography, Button, Tooltip, Stepper, Step, StepLabel, StepButton, Dialog, DialogTitle, DialogContent, DialogActions, Paper, IconButton, LinearProgress, Chip } from '@mui/material';
import { Check as CheckIcon, Warning as WarningIcon, Info as InfoIcon, CheckCircle as CheckCircleIcon, AttachMoney as BudgetIcon, Assignment as CanvasIcon, ArrowForward as ArrowForwardIcon } from '@mui/icons-material';
import { getBidGate, updateBidGate } from '../../services/bidGateService';

const gates = [
  {
    name: 'Budget Setup',
    description: 'Set up the initial budget for the bid',
    importance: 'Establishing a budget helps in resource allocation and pricing strategy.',
    greatLooksLike: 'A detailed budget breakdown covering all aspects of the project.',
    action: 'setupBudget'
  },
  {
    name: 'RFP Qualification Canvas',
    description: 'Complete the RFP Qualification Canvas',
    importance: 'This canvas helps assess the fit and feasibility of the opportunity.',
    greatLooksLike: 'A thorough analysis of the RFP against your organization\'s capabilities and strategic goals.',
    action: 'completeRFPCanvas'
  },
  {
    name: 'Bid Strategy Canvas',
    description: 'Complete the Bid Strategy Canvas',
    importance: 'This canvas outlines your approach to winning the bid.',
    greatLooksLike: 'A comprehensive strategy that addresses client needs and showcases your unique value proposition.',
    action: 'completeBidStrategyCanvas'
  },
  { 
    name: 'Bid/No-Bid Decision', 
    description: 'Decide whether to proceed with the bid',
    importance: 'This gate ensures that resources are allocated to winnable opportunities.',
    greatLooksLike: 'A data-driven decision based on the RFP Qualification and Bid Strategy Canvases.',
    action: 'makeBidDecision',
    preconditions: ['Budget Setup', 'RFP Qualification Canvas', 'Bid Strategy Canvas']
  },
  { 
    name: 'Service Design Approval', 
    description: 'Get approval for the proposed service design',
    importance: 'This gate confirms that the proposed solution meets client needs and is feasible.',
    greatLooksLike: 'A detailed service design that addresses all client requirements and aligns with your organization\'s capabilities.',
    action: 'approveServiceDesign'
  },
  { 
    name: 'Approval to Submit', 
    description: 'Obtain final approval to submit the bid',
    importance: 'This gate ensures that the bid is competitive and aligns with organizational strategy.',
    greatLooksLike: 'A comprehensive bid document that is well-written, competitively priced, and addresses all RFP requirements.',
    action: 'approveToSubmit'
  },
  { 
    name: 'Approval and Sign', 
    description: 'Get final approval and sign the contract',
    importance: 'This gate confirms that all terms are favorable and risks are mitigated before signing.',
    greatLooksLike: 'A negotiated contract that protects your interests and sets clear expectations for both parties.',
    action: 'approveAndSign'
  }
];

const BidGateStatus = ({ bidId, currentGate, onUpdateGate, onAction, onTabChange, onCanvasOpen, existingCanvases, isExpanded, onExpand }) => {
  const [localCurrentGate, setLocalCurrentGate] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedGate, setSelectedGate] = useState(null);
  const [openInfoDialog, setOpenInfoDialog] = useState(false);
  const [completedGates, setCompletedGates] = useState(new Array(gates.length).fill(false));

  const fetchCurrentGate = useCallback(async () => {
    if (!bidId) return;
    try {
      const { currentGate } = await getBidGate(bidId);
      setLocalCurrentGate(currentGate);
      setCompletedGates(new Array(gates.length).fill(false).map((_, index) => index < currentGate));
    } catch (error) {
      console.error('Error fetching gate status:', error);
    }
  }, [bidId]);

  useEffect(() => {
    fetchCurrentGate();
  }, [fetchCurrentGate]);

  const handleUpdateGate = useCallback(async (newGate) => {
    if (!bidId) return;
    try {
      const { currentGate: updatedGate } = await updateBidGate(bidId, newGate);
      setLocalCurrentGate(updatedGate);
      onUpdateGate(updatedGate);
    } catch (error) {
      console.error('Error updating gate status:', error);
    }
  }, [bidId, onUpdateGate]);

  const handleGateClick = (index) => {
    const gate = gates[index];
    switch (gate.action) {
      case 'setupBudget':
        onTabChange(5); // Assuming Budget tab is index 5
        break;
      case 'completeRFPCanvas':
        if (existingCanvases.rfpQualification) {
          onCanvasOpen('rfpQualification', existingCanvases.rfpQualification.id);
        } else {
          onCanvasOpen('rfpQualification');
        }
        break;
      case 'completeBidStrategyCanvas':
        if (existingCanvases.bidStrategy) {
          onCanvasOpen('bidStrategy', existingCanvases.bidStrategy.id);
        } else {
          onCanvasOpen('bidStrategy');
        }
        break;
      default:
        setSelectedGate(index);
        setOpenDialog(true);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedGate(null);
  };

  const handleInfoClick = (index) => {
    setSelectedGate(index);
    setOpenInfoDialog(true);
  };

  const handleCloseInfoDialog = () => {
    setOpenInfoDialog(false);
  };

  const handleToggleGateCompletion = (index) => {
    const newCompletedGates = [...completedGates];
    newCompletedGates[index] = !newCompletedGates[index];

    // For the first three gates, allow completion in any order
    if (index < 3) {
      setCompletedGates(newCompletedGates);
      
      // Update the current gate to the highest completed gate
      const highestCompletedGate = Math.max(...newCompletedGates.map((completed, i) => completed ? i : -1));
      handleUpdateGate(highestCompletedGate + 1);
    } else {
      // For gates after the first three, maintain the previous behavior
      if (index > localCurrentGate) {
        // Prevent completing future gates
        return;
      }

      // Ensure all previous gates are completed
      for (let i = 0; i < index; i++) {
        newCompletedGates[i] = true;
      }

      setCompletedGates(newCompletedGates);
      
      // Update the current gate
      const newCurrentGate = newCompletedGates.lastIndexOf(true) + 1;
      handleUpdateGate(Math.min(newCurrentGate, gates.length - 1));
    }
  };

  const handleAction = (action) => {
    if (onAction) {
      onAction(action);
    }
  };

  const getActionIcon = (action) => {
    switch (action) {
      case 'setupBudget':
        return <BudgetIcon />;
      case 'completeRFPCanvas':
      case 'completeBidStrategyCanvas':
        return <CanvasIcon />;
      default:
        return <CheckCircleIcon />;
    }
  };

  const arePrerequisitesMet = (gate) => {
    if (!gate.preconditions) return true;
    return gate.preconditions.every(precondition => 
      completedGates[gates.findIndex(g => g.name === precondition)]
    );
  };

  const handleChipClick = (e) => {
    e.stopPropagation();
    onExpand();
  };

  if (!isExpanded) {
    // Render a more compact summary view
    const currentGateInfo = gates[localCurrentGate];
    const nextGateInfo = gates[Math.min(localCurrentGate + 1, gates.length - 1)];
    
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: 1 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Chip 
            label={currentGateInfo.name}
            color="primary"
            size="small"
            icon={getActionIcon(currentGateInfo.action)}
            sx={{ mr: 1, cursor: 'pointer' }}
            onClick={handleChipClick}
          />
          <ArrowForwardIcon sx={{ fontSize: 16, mx: 0.5 }} />
          <Chip 
            label={nextGateInfo.name}
            variant="outlined"
            size="small"
            icon={getActionIcon(nextGateInfo.action)}
            sx={{ cursor: 'pointer' }}
            onClick={handleChipClick}
          />
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
          <LinearProgress 
            variant="determinate" 
            value={(localCurrentGate / (gates.length - 1)) * 100} 
            sx={{ width: 100, mr: 1, cursor: 'pointer' }}
            onClick={handleChipClick}
          />
          <Typography variant="caption" sx={{ cursor: 'pointer' }} onClick={handleChipClick}>
            {`${localCurrentGate + 1}/${gates.length}`}
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Paper elevation={2} sx={{ p: 3, mb: 4 }}>
      <Typography variant="h6" gutterBottom>Bid Progress</Typography>
      <Stepper activeStep={localCurrentGate} alternativeLabel>
        {gates.map((gate, index) => (
          <Step key={gate.name} completed={completedGates[index]}>
            <StepButton onClick={() => handleGateClick(index)}>
              <StepLabel 
                StepIconProps={{
                  icon: (
                    <Tooltip title={completedGates[index] ? "Completed" : "Mark as Complete"}>
                      <IconButton 
                        size="small" 
                        onClick={(e) => { e.stopPropagation(); handleToggleGateCompletion(index); }}
                        sx={{ 
                          color: completedGates[index] ? 'success.main' : 'action.disabled',
                          '&:hover': { backgroundColor: 'transparent' }
                        }}
                        disabled={index > localCurrentGate}
                      >
                        {completedGates[index] ? <CheckIcon /> : getActionIcon(gate.action)}
                      </IconButton>
                    </Tooltip>
                  ),
                }}
              >
                {gate.name}
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
                  <Tooltip title="More Info">
                    <IconButton size="small" onClick={(e) => { e.stopPropagation(); handleInfoClick(index); }}>
                      <InfoIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Box>
              </StepLabel>
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
        <Typography variant="body2">
          Current Stage: {localCurrentGate < gates.length ? gates[localCurrentGate].name : 'All Gates Completed'}
        </Typography>
        <Typography variant="body2">
          {Math.min(localCurrentGate, gates.length)} of {gates.length} gates completed
        </Typography>
      </Box>

      {/* Gate Change Dialog */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{selectedGate !== null ? gates[selectedGate].name : ''}</DialogTitle>
        <DialogContent>
          <Typography paragraph>{selectedGate !== null ? gates[selectedGate].description : ''}</Typography>
          {selectedGate !== null && gates[selectedGate].action && (
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleAction(gates[selectedGate].action)}
                startIcon={getActionIcon(gates[selectedGate].action)}
                disabled={!arePrerequisitesMet(gates[selectedGate])}
              >
                {`Go to ${gates[selectedGate].name}`}
              </Button>
              {!arePrerequisitesMet(gates[selectedGate]) && (
                <Box sx={{ mt: 2, p: 2, bgcolor: 'warning.light', borderRadius: 1 }}>
                  <Typography color="warning.dark">
                    <WarningIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                    You need to complete the following steps before proceeding:
                    <ul>
                      {gates[selectedGate].preconditions.map((precondition, index) => (
                        <li key={index}>{precondition}</li>
                      ))}
                    </ul>
                  </Typography>
                </Box>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="inherit">Close</Button>
        </DialogActions>
      </Dialog>

      {/* Gate Info Dialog */}
      <Dialog open={openInfoDialog} onClose={handleCloseInfoDialog}>
        <DialogTitle>{selectedGate !== null ? gates[selectedGate].name : ''}</DialogTitle>
        <DialogContent>
          <Typography variant="h6" gutterBottom>Why is this gate important?</Typography>
          <Typography paragraph>{selectedGate !== null ? gates[selectedGate].importance : ''}</Typography>
          <Typography variant="h6" gutterBottom>What does "great" look like?</Typography>
          <Typography paragraph>{selectedGate !== null ? gates[selectedGate].greatLooksLike : ''}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseInfoDialog} color="primary">Close</Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default BidGateStatus;