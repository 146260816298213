import React from 'react';
import { 
  Typography, Paper, Grid, Box, useTheme, Button, 
  Card, CardContent, CardMedia, Fade
} from '@mui/material';
import { 
  AccountBalance as AccountBalanceIcon,
  Business as BusinessIcon,
  Assessment as AssessmentIcon,
  Build as BuildIcon,
  Psychology as PsychologyIcon
} from '@mui/icons-material';
import { motion } from 'framer-motion';

const AccountsHome = () => {
  const theme = useTheme();

  const features = [
    {
      title: "CRM Integration",
      description: "Seamlessly connect with Salesforce, Pipedrive, and more.",
      icon: <BusinessIcon fontSize="large" />,
    },
    {
      title: "Proposal Builder",
      description: "Create stunning proposals for your customers with ease.",
      icon: <AssessmentIcon fontSize="large" />,
    },
    {
      title: "Retention Scoring",
      description: "Predict and improve customer retention with AI-powered insights.",
      icon: <BuildIcon fontSize="large" />,
    },
    {
      title: "Light CRM",
      description: "Perfect for companies not needing a full independent CRM solution.",
      icon: <AccountBalanceIcon fontSize="large" />,
    },
    {
      title: "Advanced AI Account Management",
      description: "Leverage cutting-edge AI to optimize your account strategies.",
      icon: <PsychologyIcon fontSize="large" />,
    },
  ];

  return (
    <Fade in={true} style={{ transitionDelay: '300ms' }}>
      <Box sx={{ padding: theme.spacing(3) }}>
        <Paper elevation={0} sx={{ padding: theme.spacing(3), borderRadius: theme.shape.borderRadius, backgroundColor: 'transparent' }}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
            <Typography variant="h4" component="h1">
              <AccountBalanceIcon sx={{ mr: 1, verticalAlign: 'bottom', color: theme.palette.primary.main }} />
              Accounts Management
            </Typography>
          </Box>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Paper elevation={3} sx={{ p: 4, mb: 4, backgroundColor: theme.palette.background.default }}>
              <Typography variant="h5" gutterBottom color="primary">
                Coming Soon: Advanced Accounts Management
              </Typography>
              <Typography variant="body1" paragraph>
              Get ready for a game-changing suite of tools designed to transform your account management experience! Here’s a sneak peek at what’s on the horizon:
              </Typography>

              <Grid container spacing={3}>
                {features.map((feature, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                      <CardContent sx={{ flexGrow: 1 }}>
                        <Box display="flex" alignItems="center" mb={2}>
                          {feature.icon}
                          <Typography variant="h6" component="div" sx={{ ml: 1 }}>
                            {feature.title}
                          </Typography>
                        </Box>
                        <Typography variant="body2" color="text.secondary">
                          {feature.description}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>

              <Box mt={4} textAlign="center">
                <Typography variant="body1" paragraph>
                We can’t wait to roll out these exciting features! Our mission is to deliver a comprehensive account management solution that meets the needs of businesses, big and small. Stay tuned!
                </Typography>
                <Button variant="contained" color="primary" disabled>
                  Notify Me When Available
                </Button>
              </Box>
            </Paper>
          </motion.div>
        </Paper>
      </Box>
    </Fade>
  );
};

export default AccountsHome;
