import React, { useState, useEffect, lazy, Suspense, useCallback } from 'react';
import { 
  Typography, 
  Stepper, 
  Step, 
  StepLabel, 
  Button, 
  Box, 
  Paper, 
  Tooltip, 
  IconButton,
  CircularProgress, 
  Snackbar, 
  Alert, 
  Collapse, 
  Skeleton, 
  Fade,
  useTheme,
  Fab,
  Zoom,
  Drawer,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { motion } from 'framer-motion';
import { 
  getBrandProfile, 
  createBrandProfile, 
  updateBrandProfile, 
  getToneOfVoice, 
  createToneOfVoice, 
  updateToneOfVoice,
  createOrUpdateBrandProfile,
  createOrUpdateToneOfVoice
} from '../../services/brandBuilderService';
import { useNavigate } from 'react-router-dom';
import ErrorBoundary from '../ErrorBoundary';
import BrandBuilderChatInterface from '../ai/puawaiBrandAIinterface';
import ChatIcon from '@mui/icons-material/Chat';
import FloatingAIIcon from '../ai/puawaiIntelligenceInterface'; // Add this import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSeedling } from '@fortawesome/free-solid-svg-icons';

// Lazy load components
const BrandInputForm = lazy(() => import('./BrandInputForm'));
const AudienceInsightsForm = lazy(() => import('./AudienceInsightsForm'));
const ToneDimensionsSlider = lazy(() => import('./ToneDimensionsSlider'));
const ToneQuadrantSelection = lazy(() => import('./ToneQuadrantSelection'));
const ChannelAdaptation = lazy(() => import('./ChannelAdaptation'));
const DosAndDonts = lazy(() => import('./DosAndDonts'));
const ToneOfVoiceGuide = lazy(() => import('./ToneOfVoiceGuide'));

const steps = [
  {
    label: 'Brand Input',
    description: 'Define your brand personality, archetype, mission, and vision.',
    tip: 'Think about what makes your brand unique and how you want to be perceived by your audience.'
  },
  {
    label: 'Audience Insights',
    description: 'Identify your target audience and their communication preferences.',
    tip: 'Consider demographics, psychographics, and the specific needs of your target audience.'
  },
  {
    label: 'Tone Dimensions',
    description: 'Position your brand on key tone spectrums.',
    tip: 'Reflect on how formal, friendly, or authoritative you want your brand voice to be.'
  },
  {
    label: 'Tone Quadrant',
    description: 'Define specific tone guidelines for different contexts.',
    tip: 'Think about how your tone might change in different situations or for different types of content.'
  },
  {
    label: 'Channel Adaptation',
    description: 'Customize your tone for different communication channels.',
    tip: 'Consider how your tone might need to adapt for social media versus formal communications.'
  },
  {
    label: 'Do\'s and Don\'ts',
    description: 'Generate guidelines for consistent tone application.',
    tip: 'Think about specific phrases or approaches that embody or contradict your brand voice.'
  },
  {
    label: 'Generate Guide',
    description: 'Create your downloadable Tone of Voice Guide.',
    tip: 'Review all the information you\'ve provided to ensure it accurately represents your brand.'
  }
];

const BrandBuilder = ({ selectedOrg, isEditMode = false }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [brandData, setBrandData] = useState({});
  const [toneOfVoiceData, setToneOfVoiceData] = useState({});
  const [completed, setCompleted] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isComplete, setIsComplete] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const navigate = useNavigate();
  const [showTip, setShowTip] = useState(true);
  const [isNewProfile, setIsNewProfile] = useState(false);
  const [stepLoading, setStepLoading] = useState(false);
  const theme = useTheme();
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [chatPosition, setChatPosition] = useState({ bottom: 16, right: 16 });
  const [allFormData, setAllFormData] = useState({});
  const [isDisclaimerOpen, setIsDisclaimerOpen] = useState(false);

  const fetchData = useCallback(async () => {
    if (!selectedOrg) return;
    setLoading(true);
    setError(null);
    try {
      const orgId = selectedOrg.id || selectedOrg;
      const brandProfile = await getBrandProfile(orgId);
      
      if (brandProfile) {
        setBrandData(brandProfile);
        const toneOfVoice = await getToneOfVoice(brandProfile.id);
        setToneOfVoiceData(toneOfVoice || {});
        const isComplete = !!toneOfVoice && Object.keys(toneOfVoice).length > 0;
        setIsComplete(isComplete);
        setIsNewProfile(!isComplete);
      } else {
        setBrandData({});
        setToneOfVoiceData({});
        setIsComplete(false);
        setIsNewProfile(true);
      }
    } catch (error) {
      setError('Failed to load existing data. Please try again.');
    } finally {
      setLoading(false);
    }
  }, [selectedOrg]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (!isComplete && activeStep === 0) {
      setBrandData(prevData => ({...prevData}));
    }
  }, [isComplete, activeStep]);

  const saveData = async () => {
    setLoading(true);
    setError(null);
    try {
      const orgId = selectedOrg.id || selectedOrg;
      let savedBrandProfile = await createOrUpdateBrandProfile(orgId, brandData);
      let savedToneOfVoice = await createOrUpdateToneOfVoice(savedBrandProfile.id, toneOfVoiceData);
      
      setBrandData(savedBrandProfile);
      setToneOfVoiceData(savedToneOfVoice);
      
      return true;
    } catch (error) {
      setError('Failed to save data. Please try again.');
      return false;
    } finally {
      setLoading(false);
    }
  };

  const handleNext = async () => {
    setLoading(true);
    try {
      if (await saveData()) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setCompleted({ ...completed, [activeStep]: true });
        setSnackbar({ open: true, message: 'Progress saved successfully!', severity: 'success' });
      } else {
        setSnackbar({ open: true, message: 'Failed to save progress. Please try again.', severity: 'error' });
      }
    } catch (error) {
      setSnackbar({ open: true, message: 'An error occurred. Please try again.', severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleDataUpdate = (data) => {
    setBrandData(prevData => {
      const updatedData = { ...prevData, ...data };
      setAllFormData(prevAllData => ({ ...prevAllData, ...data }));
      return updatedData;
    });
  };

  const handleToneOfVoiceUpdate = (data) => {
    setToneOfVoiceData(prevData => {
      const updatedData = { ...prevData, ...data };
      setAllFormData(prevAllData => ({ ...prevAllData, ...data }));
      return updatedData;
    });
  };

  const handleFinish = async () => {
    if (await saveData()) {
      setIsComplete(true);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Suspense fallback={<CircularProgress />}>
            <BrandInputForm
              brandData={brandData}
              onDataUpdate={handleDataUpdate}
              currentStep={activeStep}
              toneOfVoiceData={toneOfVoiceData}
              currentStepData={steps[activeStep]}
              setStepLoading={setStepLoading}
              allFormData={allFormData}
            />
          </Suspense>
        );
      case 1: return <AudienceInsightsForm brandData={brandData} toneOfVoiceData={toneOfVoiceData} onDataUpdate={handleDataUpdate} onToneOfVoiceUpdate={handleToneOfVoiceUpdate} selectedOrg={selectedOrg} setStepLoading={setStepLoading} />;
      case 2: return <ToneDimensionsSlider brandData={brandData} toneOfVoiceData={toneOfVoiceData} onDataUpdate={handleDataUpdate} onToneOfVoiceUpdate={handleToneOfVoiceUpdate} selectedOrg={selectedOrg} setStepLoading={setStepLoading} />;
      case 3: return (
        <ToneQuadrantSelection 
          brandData={brandData} 
          toneOfVoiceData={toneOfVoiceData} 
          onDataUpdate={handleToneOfVoiceUpdate} 
          currentStep={activeStep}
          currentStepData={steps[activeStep]}
          allFormData={allFormData}
        />
      );
      case 4: return (
        <ChannelAdaptation 
          brandData={brandData} 
          toneOfVoiceData={toneOfVoiceData} 
          onDataUpdate={handleToneOfVoiceUpdate}
          currentStep={activeStep}
          currentStepData={steps[activeStep]}
          allFormData={allFormData}
        />
      );
      case 5: return <DosAndDonts brandData={brandData} toneOfVoiceData={toneOfVoiceData} onDataUpdate={handleDataUpdate} onToneOfVoiceUpdate={handleToneOfVoiceUpdate} selectedOrg={selectedOrg} setStepLoading={setStepLoading} />;
      case 6: return <ToneOfVoiceGuide brandData={brandData} toneOfVoiceData={toneOfVoiceData} onDataUpdate={handleDataUpdate} onToneOfVoiceUpdate={handleToneOfVoiceUpdate} selectedOrg={selectedOrg} setStepLoading={setStepLoading} />;
      default: return 'Unknown step';
    }
  };

  const InfoPanel = ({ step }) => (
    <Collapse in={showTip}>
      <Paper 
        elevation={3} 
        sx={{ 
          p: 2, 
          mt: 2, 
          mb: 3,
          backgroundColor: 'info.light', 
          color: 'info.contrastText',
          position: 'relative'
        }}
      >
        <IconButton
          aria-label="close"
          color="inherit"
          size="small"
          onClick={() => setShowTip(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <InfoIcon sx={{ mr: 1 }} />
          <Typography variant="h6">Tip for this step</Typography>
        </Box>
        <Typography variant="body1" sx={{ pl: 3 }}>{steps[step].tip}</Typography>
      </Paper>
    </Collapse>
  );

  const renderLoadingSkeleton = () => (
    <Box sx={{ width: '100%' }}>
      <Skeleton variant="rectangular" width="100%" height={60} sx={{ mb: 2 }} />
      <Skeleton variant="rectangular" width="100%" height={200} sx={{ mb: 2 }} />
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Skeleton variant="rectangular" width={100} height={36} />
        <Skeleton variant="rectangular" width={100} height={36} />
      </Box>
    </Box>
  );

  const renderWelcomeMessage = () => (
    <Paper elevation={3} sx={{ p: 3, mb: 3, textAlign: 'center' }}>
      <Typography variant="h4" gutterBottom>
        Welcome to Brand Builder!
      </Typography>
      <Typography variant="body1" paragraph>
        It looks like you haven't created a brand profile for this organization yet. 
        Let's get started on defining your brand's unique voice and personality!
      </Typography>
      <Button 
        variant="contained" 
        color="primary" 
        onClick={() => setIsNewProfile(false)}
        startIcon={<AddIcon />}
      >
        Start Building Your Brand
      </Button>
    </Paper>
  );

  const handleEditBrandBuild = () => {
    setIsComplete(false);
    setActiveStep(0);
    setLoading(false);
    setStepLoading(false);
  };

  const renderContent = () => {
    if (loading) {
      return renderLoadingSkeleton();
    }

    if (error) {
      return (
        <Typography color="error" gutterBottom>
          {error}
        </Typography>
      );
    }

    if (!selectedOrg) {
      return (
        <Typography color="error" gutterBottom>
          Please select an organization to continue with the Brand Builder.
        </Typography>
      );
    }

    if (isNewProfile) {
      return renderWelcomeMessage();
    }

    if (isComplete && !isEditMode) {
      return (
        <ErrorBoundary>
          <Suspense fallback={<CircularProgress />}>
            <Box sx={{ width: '100%' }}>
              <ToneOfVoiceGuide 
                brandData={brandData} 
                toneOfVoiceData={toneOfVoiceData} 
                onEditBrandBuild={handleEditBrandBuild}
              />
            </Box>
          </Suspense>
        </ErrorBoundary>
      );
    }

    return (
      <ErrorBoundary>
        <Box sx={{ width: '100%', position: 'relative' }}>
          {error && (
            <Paper elevation={3} sx={{ p: 2, mb: 2, backgroundColor: 'error.light' }}>
              <Typography color="error">{error}</Typography>
            </Paper>
          )}
          <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
            <Typography variant="h4" gutterBottom>
              Brand Builder
              <Tooltip title="The Brand Builder helps you create a comprehensive Tone of Voice guide for your brand. Follow each step to define your brand's unique voice and communication style.">
                <IconButton>
                  <HelpOutlineIcon />
                </IconButton>
              </Tooltip>
            </Typography>
            <Typography variant="body1" paragraph>
              Welcome to the Brand Builder! This tool will guide you through creating a comprehensive Tone of Voice for your brand. Follow each step carefully to define your brand's unique voice and communication style.
            </Typography>
          </Paper>

          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((step, index) => (
              <Step key={step.label} completed={completed[index]}>
                <StepLabel>
                  <Tooltip title={step.description} placement="top">
                    <span>{step.label}</span>
                  </Tooltip>
                </StepLabel>
              </Step>
            ))}
          </Stepper>

          <Paper elevation={3} sx={{ p: 3, mt: 3 }}>
            <Typography variant="h6" gutterBottom>
              {steps[activeStep].label}
            </Typography>
            <Typography variant="body2" paragraph color="text.secondary">
              {steps[activeStep].description}
            </Typography>
            <InfoPanel step={activeStep} />
            <Suspense fallback={<CircularProgress />}>
              {stepLoading ? (
                <CircularProgress />
              ) : (
                <>
                  {getStepContent(activeStep)}
                </>
              )}
            </Suspense>
          </Paper>

          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0 || loading}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            {!showTip && (
              <Button
                color="info"
                onClick={() => setShowTip(true)}
                sx={{ mr: 1 }}
                startIcon={<InfoIcon />}
              >
                Show Tip
              </Button>
            )}
            <Tooltip title={activeStep === steps.length - 1 ? "Complete the Brand Builder process" : "Save and proceed to the next step"}>
              <Button 
                onClick={activeStep === steps.length - 1 ? handleFinish : handleNext} 
                variant="contained" 
                color="primary"
                disabled={loading || stepLoading}
              >
                {loading || stepLoading ? <CircularProgress size={24} /> : (activeStep === steps.length - 1 ? 'Finish' : 'Next')}
              </Button>
            </Tooltip>
          </Box>

          <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleSnackbarClose}>
            <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
              {snackbar.message}
            </Alert>
          </Snackbar>
        </Box>
      </ErrorBoundary>
    );
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      
      if (scrollPosition + windowHeight >= documentHeight - 100) {
        // Near the bottom of the page
        setChatPosition({ bottom: 80, right: 16 });
      } else {
        setChatPosition({ bottom: 16, right: 16 });
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleChat = () => {
    if (!isChatOpen) {
      // Only show the disclaimer when opening the chat
      setIsDisclaimerOpen(true);
    } else {
      // Directly close the chat without showing the disclaimer
      setIsChatOpen(false);
    }
  };

  const handleDisclaimerAccept = () => {
    setIsDisclaimerOpen(false);
    setIsChatOpen(true);
  };

  const handleDisclaimerDecline = () => {
    setIsDisclaimerOpen(false);
    // Don't open the chat if the user declines
  };

  const disclaimerText = (
    <>
      <Typography variant="h6" gutterBottom>
        Kia ora! I'm your Puāwai Intelligence Brand Assistant.
      </Typography>
      <Typography paragraph>
        I'm here to assist with your brand development. Please note:
      </Typography>
      <Box component="ul" sx={{ pl: 2 }}>
        <Typography component="li">As a new AI, I'm continuously learning and improving.</Typography>
        <Typography component="li">I use your data solely to provide personalized assistance.</Typography>
        <Typography component="li">Your information is not stored or used for training purposes.</Typography>
        <Typography component="li">Your privacy and data security are paramount.</Typography>
      </Box>
      <Typography paragraph>
        Let's collaborate to enhance your brand strategy.
      </Typography>
    </>
  );

  return (
    <Box sx={{ width: '100%', position: 'relative', minHeight: '100vh', paddingBottom: '100px' }}>
      {renderContent()}
      <BrandBuilderChatInterface 
        userProfile={brandData}
        organisation={selectedOrg}
        brandData={brandData}
        toneOfVoiceData={toneOfVoiceData}
        topOffset={64}
        currentStep={activeStep}
        currentStepData={steps[activeStep]}
        isOpen={isChatOpen}
        onToggle={toggleChat}
      />
      
      <Dialog open={isDisclaimerOpen} onClose={handleDisclaimerDecline}>
        <DialogTitle>Puāwai Intelligence Brand Assistant</DialogTitle>
        <DialogContent>
          {disclaimerText}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDisclaimerDecline} color="primary">
            Maybe Later
          </Button>
          <Button onClick={handleDisclaimerAccept} color="primary" variant="contained">
            Accept & Continue
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default React.memo(BrandBuilder);
