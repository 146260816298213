import React, { useState, useEffect } from 'react';
import BaseCanvas from './BaseCanvas';
import CampaignIcon from '@mui/icons-material/Campaign';
import { 
  Stepper, Step, StepLabel, StepContent, Button, Typography, Box, 
  LinearProgress, Paper, Tooltip, Dialog, DialogTitle, DialogContent, DialogActions,
  List, ListItem, ListItemIcon, ListItemText, useTheme, CircularProgress
} from '@mui/material';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import StarIcon from '@mui/icons-material/Star';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CloseIcon from '@mui/icons-material/Close';
import { motion } from 'framer-motion';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { PDFDownloadLink, pdf } from '@react-pdf/renderer';
import StoryBrandPDF from '../pdf/StoryBrandPDF';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { ThemeProvider } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { saveAs } from 'file-saver';
import { completeOrgCanvas, saveDraftOrgCanvas, updateOrgCanvas } from '../../services/organisationcanvasService';
import { toast } from 'react-toastify'; // Add this import
import DOMPurify from 'dompurify'; // Install this package: npm install dompurify
import { theme } from '../../theme'; // Import your theme



const StoryBrandCanvas = ({ user, selectedOrg, canvas, onClose, onSave, onUpdate }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [canvasData, setCanvasData] = useState(canvas || {});
  const [progress, setProgress] = useState(0);
  const [points, setPoints] = useState(0);
  const [showGuide, setShowGuide] = useState(true);
  const [achievements, setAchievements] = useState([]);
  const [completedSteps, setCompletedSteps] = useState([]);
  const [showAllSections, setShowAllSections] = useState(false);
  const [sectionStatus, setSectionStatus] = useState({});
  const theme = useTheme();
  const [pdfData, setPdfData] = useState(null);
  const [editingSection, setEditingSection] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!user || !user.id) {
      setError('User data is not available. Please try logging in again.');
      setIsLoading(false);
      return;
    }

    if (!selectedOrg) {
      setError('No organization selected. Please choose an organization and try again.');
      setIsLoading(false);
      return;
    }

    // Initialize canvas data and other setup
    if (canvas) {
      setCanvasData(canvas);
      calculateProgress(canvas);
      setPoints(canvas.points || 0);
      
      // Initialize section status
      const initialStatus = {};
      sections.forEach(section => {
        initialStatus[section.field] = canvas[section.field] && canvas[section.field].trim() !== '';
      });
      setSectionStatus(initialStatus);

      const completed = Object.values(initialStatus).filter(Boolean).length;
      setCompletedSteps(Array.from({ length: completed }, (_, i) => i));
      
      if (completed === sections.length) {
        setShowAllSections(true);
      }

      // Prepare PDF data
      setPdfData({
        canvasData: canvas,
        organisationName: typeof selectedOrg === 'object' ? selectedOrg.name : 'Selected Organization'
      });
    }

    setIsLoading(false);
  }, [user, selectedOrg, canvas]);

  const calculateProgress = (data) => {
    const filledFields = sections.filter(section => 
      data[section.field] && data[section.field].trim() !== ''
    ).length;
    setProgress((filledFields / sections.length) * 100);
  };

  const handleNext = () => {
    onUpdate(canvasData);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    calculateProgress(canvasData);
    awardPoints(10);
    checkAchievements();
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleFieldChange = (field, value) => {
    const updatedCanvas = { ...canvasData, [field]: value };
    const newSectionStatus = { 
      ...sectionStatus, 
      [field]: value && typeof value === 'string' ? value.trim() !== '' : Boolean(value)
    };
    
    setCanvasData(updatedCanvas);
    setSectionStatus(newSectionStatus);
    
    const completed = Object.values(newSectionStatus).filter(Boolean).length;
    setCompletedSteps(Array.from({ length: completed }, (_, i) => i));
    
    onUpdate(updatedCanvas);
    calculateProgress(updatedCanvas);
  };

  const handleSave = async (status) => {
    try {
      if (!selectedOrg) {
        throw new Error('No organization selected');
      }
      
      const orgId = typeof selectedOrg === 'string' ? selectedOrg : selectedOrg.id;
      
      if (!orgId) {
        throw new Error('Invalid organization ID');
      }
      
      if (!canvasData || !canvasData.id) {
        throw new Error('No canvas ID available');
      }
      
      // Call onSave and wait for it to complete
      await onSave({ ...canvasData, status, sectionStatus, points, orgId });
      
      // Close the canvas after successful save
      onClose();
    } catch (error) {
      toast.error(`Failed to save canvas as ${status}. Please try again. Error: ${error.message}`);
    }
  };

  const awardPoints = (amount) => {
    setPoints(prevPoints => {
      const newPoints = prevPoints + amount;
      setCanvasData(prevData => ({ ...prevData, points: newPoints }));
      return newPoints;
    });
  };

  const unlockAchievement = (achievement) => {
    if (!achievements.includes(achievement)) {
      setAchievements(prevAchievements => [...prevAchievements, achievement]);
    }
  };

  const checkAchievements = () => {
    if (progress >= 25) unlockAchievement('25% Completed');
    if (progress >= 50) unlockAchievement('Halfway There');
    if (progress >= 75) unlockAchievement('Almost Done');
    if (progress === 100) unlockAchievement('StoryBrand Master');
  };

  const handleStepClick = (step) => {
    if (completedSteps.includes(step) || step === activeStep + 1) {
      setActiveStep(step);
    }
  };

  const toggleShowAllSections = () => {
    setShowAllSections(!showAllSections);
  };

  const handleEditSection = (index) => {
    setEditingSection(index);
    setActiveStep(index);
    setShowAllSections(false);
  };

  const handleSaveSection = (sectionField, value) => {
    handleFieldChange(sectionField, value);
    setEditingSection(null);
  };

  const handleGeneratePDF = async () => {
    try {
      //console.log('Starting PDF generation');
      //console.log('Canvas Data:', canvasData);

      const pdfData = {
        character: canvasData.character || '',
        problemExternal: canvasData.problemExternal || '',
        problemInternal: canvasData.problemInternal || '',
        problemPhilosophical: canvasData.problemPhilosophical || '',
        guideEmpathy: canvasData.guideEmpathy || '',
        guideAuthority: canvasData.guideAuthority || '',
        plan: canvasData.plan || '',
        callToAction: canvasData.callToAction || '',
        success: canvasData.success || '',
        failure: canvasData.failure || '',
        transformation: canvasData.transformation || '',
      };

      //console.log('PDF Data:', pdfData);

      const blob = await pdf(
        <StoryBrandPDF 
          canvasData={pdfData}
        />
      ).toBlob();

      //console.log('PDF blob generated');

      saveAs(blob, 'StoryBrand_Canvas.pdf');
      //console.log('PDF saved');
    } catch (error) {
      console.error('Error generating PDF:', error);
      toast.error(`Failed to generate PDF. Error: ${error.message}`);
    }
  };

  const sections = [
    { 
      field: 'character', 
      title: 'Step 1: Identify the Character (Customer)', 
      width: 12,
      instruction: 'Define who your main character (customer) is and what they want.',
      explanation: 'In the StoryBrand framework, your customer is the hero of the story, not your brand. Understanding your customer is crucial for crafting a compelling narrative.',
      guidingQuestions: [
        'Who is your ideal customer?',
        'What are their demographics and psychographics?',
        'What is their primary goal or desire?'
      ],
      rows: 3,
    },
    { 
      field: 'problemExternal', 
      title: 'Step 2: Define the Problem - External', 
      width: 12,
      instruction: 'What is the obvious, surface-level issue the customer faces?',
      explanation: 'Every good story has a problem that the hero must overcome. In this step, we identify the external problem your customer is facing.',
      guidingQuestions: [
        'What tangible, real-world problem does your customer face?',
        'How does this problem manifest in their daily life?',
        'What are the symptoms of this problem?'
      ],
      rows: 3,
    },
    { 
      field: 'problemInternal', 
      title: 'Define the Problem - Internal', 
      width: 12,
      instruction: 'What is the emotional or deeper frustration the customer feels?',
      explanation: 'The internal problem is the emotional impact of the external problem. It\'s often the real reason customers seek a solution.',
      guidingQuestions: [
        'How does the external problem make your customer feel?',
        'What frustrations or anxieties does it cause?',
        'What desires are unfulfilled because of this problem?'
      ],
      rows: 3,
    },
    { 
      field: 'problemPhilosophical', 
      title: 'Define the Problem - Philosophical', 
      width: 12,
      instruction: 'Why does solving this problem matter on a larger, moral, or societal level?',
      explanation: 'The philosophical problem adds depth to your story by connecting it to larger issues or values.',
      guidingQuestions: [
        'Why is it morally wrong that your customer has this problem?',
        'What larger societal or ethical issues does this problem relate to?',
        'How does solving this problem contribute to a better world?'
      ],
      rows: 3,
    },
    { 
      field: 'guideEmpathy', 
      title: 'Step 3: Position the Brand as the Guide - Empathy', 
      width: 12,
      instruction: 'How does your brand demonstrate understanding of the customer\'s fear or frustration?',
      explanation: 'As the guide, your brand needs to show that it understands and empathizes with the hero\'s (customer\'s) struggles.',
      guidingQuestions: [
        'How can you show that you understand your customer\'s pain points?',
        'What shared experiences can you draw upon?',
        'How can you validate your customer\'s feelings?'
      ],
      rows: 3,
    },
    { 
      field: 'guideAuthority', 
      title: 'Position the Brand as the Guide - Authority', 
      width: 12,
      instruction: 'How does your brand showcase its expertise or experience?',
      explanation: 'In addition to empathy, your brand needs to demonstrate that it has the ability to help the hero overcome their challenge.',
      guidingQuestions: [
        'What qualifications or experience does your brand have?',
        'What success stories or testimonials can you share?',
        'What unique insights or processes do you offer?'
      ],
      rows: 3,
    },
    { 
      field: 'plan', 
      title: 'Step 4: Provide a Plan', 
      width: 12,
      instruction: 'Create a clear and simple plan to help the customer solve their problem. Summarize in a few actionable steps.',
      explanation: 'A clear plan reduces the cognitive load on your customer and makes it easier for them to take action.',
      guidingQuestions: [
        'What are the key steps your customer needs to take?',
        'How can you make these steps as simple and clear as possible?',
        'What assurances can you provide at each step?'
      ],
      rows: 4,
    },
    { 
      field: 'callToAction', 
      title: 'Step 5: Call to Action', 
      width: 12,
      instruction: 'Define a direct Call to Action that encourages the customer to take a specific step.',
      explanation: 'A clear call to action tells your customer exactly what they need to do to start their journey with your brand.',
      guidingQuestions: [
        'What is the primary action you want your customer to take?',
        'How can you make this action as clear and compelling as possible?',
        'What secondary call to action can you offer for those not ready to commit?'
      ],
      rows: 3,
    },
    { 
      field: 'success', 
      title: 'Step 6: Highlight the Potential Success', 
      width: 12,
      instruction: 'Describe what life will look like if the customer takes action. Focus on positive, successful results.',
      explanation: 'Paint a vivid picture of the positive outcomes your customer will experience by choosing your brand.',
      guidingQuestions: [
        'What specific benefits will your customer experience?',
        'How will their life improve?',
        'What larger goals or aspirations will they be able to achieve?'
      ],
      rows: 3,
    },
    { 
      field: 'failure', 
      title: 'Step 7: Outline the Risk of Failure', 
      width: 12,
      instruction: 'Clearly articulate what\'s at stake if the customer doesn\'t take action. Explain potential negative outcomes.',
      explanation: 'Highlighting the potential negative outcomes if the customer doesn\'t act creates urgency and motivates action.',
      guidingQuestions: [
        'What negative consequences might your customer face if they don\'t solve their problem?',
        'What opportunities might they miss out on?',
        'How might their current frustrations or challenges worsen?'
      ],
      rows: 3,
    },
    { 
      field: 'transformation', 
      title: 'Step 8: Show Identity Transformation', 
      width: 12,
      instruction: 'Describe how the customer will transform after solving their problem with your brand\'s help.',
      explanation: 'The most powerful stories show how the hero transforms. Describe how your customer\'s identity will evolve through their journey with your brand.',
      guidingQuestions: [
        'How will your customer see themselves differently after using your product/service?',
        'What new capabilities or confidence will they have gained?',
        'How will others perceive them differently?'
      ],
      rows: 3,
    },
  ];

  const tooltips = {
    character: "Your main customer or target audience.",
    characterWant: "The customer's primary desire or goal.",
    problemExternal: "The obvious, surface-level issue the customer faces.",
    problemInternal: "The emotional or deeper frustration the customer feels.",
    problemPhilosophical: "The larger, moral or societal reason why solving this problem matters.",
    guideEmpathy: "How your brand shows understanding of the customer's problem.",
    guideAuthority: "Your brand's expertise or experience in solving the problem.",
    plan: "A clear, simple plan to help the customer solve their problem.",
    callToAction: "A specific action the customer should take.",
    success: "The positive outcome of following your plan.",
    failure: "The negative consequences of not taking action.",
    transformation: "How the customer's identity changes after solving their problem.",
  };

  const renderSectionContent = (content) => {
    return { __html: DOMPurify.sanitize(content) };
  };

  const StepperContent = () => (
    <Stepper activeStep={activeStep} orientation="vertical" sx={{ mt: 2 }}>
      {sections.map((section, index) => (
        <Step key={section.field} completed={completedSteps.includes(index)}>
          <StepLabel 
            onClick={() => handleStepClick(index)}
            sx={{ cursor: completedSteps.includes(index) || index === activeStep + 1 ? 'pointer' : 'default' }}
          >
            <Typography variant="h6" sx={{ py: 1 }}>{section.title}</Typography>
          </StepLabel>
          <StepContent TransitionProps={{ unmountOnExit: false }}>
            <Box sx={{ bgcolor: 'background.paper', p: 3, borderRadius: 1, mb: 2 }}>
              <Typography variant="body1" paragraph>
                {section.explanation}
              </Typography>
              <Typography variant="body2" color="textSecondary" paragraph>
                {section.instruction}
              </Typography>
              <List>
                {section.guidingQuestions.map((question, qIndex) => (
                  <ListItem key={qIndex}>
                    <ListItemIcon>
                      <HelpOutlineIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText primary={question} />
                  </ListItem>
                ))}
              </List>
              <BaseCanvas
                user={user}
                selectedOrg={selectedOrg}
                canvas={canvasData}
                onClose={onClose}
                onUpdate={handleFieldChange}
                onSave={handleSave}
                canvasType="StoryBrand Canvas"
                sections={[{...section, rows: section.rows}]}
                tooltips={tooltips}
                icon={CampaignIcon}
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <Button
                variant="contained"
                onClick={handleNext}
                sx={{ mr: 1 }}
              >
                {index === sections.length - 1 ? 'Finish' : 'Continue'}
              </Button>
              {index > 0 && (
                <Button
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Back
                </Button>
              )}
            </Box>
          </StepContent>
        </Step>
      ))}
    </Stepper>
  );

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', flexDirection: 'column' }}>
        <Typography variant="h6" color="error" gutterBottom>
          {error}
        </Typography>
        <Button variant="contained" onClick={onClose}>
          Go Back
        </Button>
      </Box>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh', bgcolor: theme.palette.background.default }}>
        {/* Header */}
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center', 
          p: 2, 
          borderBottom: `1px solid ${theme.palette.divider}`
        }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
              <i className="fas fa-seedling" style={{ fontSize: '24px', marginRight: '8px', color: theme.palette.primary.main }} />
              <Typography variant="h5" sx={{ fontWeight: 'bold', color: theme.palette.primary.main }}>
                Puāwai
              </Typography>
            </Box>
            <CampaignIcon sx={{ verticalAlign: 'middle', mr: 1, fontSize: '28px', color: theme.palette.secondary.main }} />
            <Typography variant="h5" sx={{ fontWeight: 500 }}>StoryBrand Canvas</Typography>
          </Box>
          <Button startIcon={<CloseIcon />} onClick={onClose}>
            Close
          </Button>
        </Box>

        {/* Content */}
        <Box sx={{ p: 3, flexGrow: 1, overflowY: 'auto' }}>
          <Typography variant="body1" paragraph>
            Follow this step-by-step guide to create a compelling story where your customer is the hero and your brand is the trusted guide.
          </Typography>
          <Paper elevation={3} sx={{ p: 2, mb: 3 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="h6" gutterBottom>
                Your Progress
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <StarIcon color="primary" />
                <Typography variant="h6" sx={{ ml: 1 }}>{points} points</Typography>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" value={progress} />
              </Box>
              <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(progress)}%`}</Typography>
              </Box>
            </Box>
            <Box sx={{ mt: 2, display: 'flex', alignItems: 'center' }}>
              <EmojiEventsIcon color={progress === 100 ? 'primary' : 'disabled'} />
              <Typography variant="body2" color="text.secondary" sx={{ ml: 1 }}>
                {progress === 100 ? 'Congratulations! You\'ve completed the StoryBrand Canvas!' : 'Complete all sections to earn your badge!'}
              </Typography>
            </Box>
          </Paper>
          {completedSteps.length === sections.length && (
            <Button
              onClick={toggleShowAllSections}
              startIcon={showAllSections ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              sx={{ mb: 2 }}
            >
              {showAllSections ? 'Collapse All Sections' : 'Show All Sections'}
            </Button>
          )}
          <Box sx={{ flexGrow: 1 }}>
            {completedSteps.length === sections.length ? (
              sections.map((section, index) => (
                <Paper key={section.field} elevation={3} sx={{ p: 3, mb: 2 }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    <Typography variant="h6">{section.title}</Typography>
                    {editingSection === index ? (
                      <Button
                        startIcon={<SaveIcon />}
                        onClick={() => handleSaveSection(section.field, canvasData[section.field])}
                      >
                        Save
                      </Button>
                    ) : (
                      <Button
                        startIcon={<EditIcon />}
                        onClick={() => handleEditSection(index)}
                      >
                        Edit
                      </Button>
                    )}
                  </Box>
                  {editingSection === index ? (
                    <BaseCanvas
                      user={user}
                      selectedOrg={selectedOrg}
                      canvas={canvasData}
                      onClose={() => setEditingSection(null)}
                      onUpdate={(field, value) => handleFieldChange(field, value)}
                      onSave={() => handleSaveSection(section.field, canvasData[section.field])}
                      canvasType="StoryBrand Canvas"
                      sections={[{...section, rows: section.rows}]}
                      tooltips={tooltips}
                      icon={CampaignIcon}
                    />
                  ) : (
                    <div dangerouslySetInnerHTML={renderSectionContent(canvasData[section.field])} />
                  )}
                </Paper>
              ))
            ) : (
              <StepperContent />
            )}
          </Box>
        </Box>

        {/* Footer */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2, borderTop: `1px solid ${theme.palette.divider}` }}>
          <Button startIcon={<LightbulbIcon />} onClick={() => setShowGuide(true)}>
            Show Guide
          </Button>
          <Box>
            <Button 
              startIcon={<PictureAsPdfIcon />} 
              sx={{ mr: 2 }}
              onClick={handleGeneratePDF}
            >
              Download PDF
            </Button>
            <Button onClick={() => handleSave('draft')} sx={{ mr: 2 }}>
              Save as Draft
            </Button>
            <Button onClick={() => handleSave('completed')} variant="contained" color="primary">
              Complete Canvas
            </Button>
          </Box>
        </Box>

        {/* Guide Dialog */}
        <Dialog 
          open={showGuide} 
          onClose={() => setShowGuide(false)}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <i className="fas fa-seedling" style={{ fontSize: '24px', marginRight: '8px', color: theme.palette.primary.main }} />
              <Typography variant="h5" sx={{ fontWeight: 'bold', color: theme.palette.primary.main }}>
                Puāwai StoryBrand Canvas Guide
              </Typography>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Typography variant="h6" gutterBottom sx={{ color: theme.palette.secondary.main }}>
              Welcome to the StoryBrand Canvas!
            </Typography>
            <Typography variant="body1" paragraph>
              The StoryBrand framework, developed by Donald Miller, helps you clarify your brand message by positioning your customer as the hero of the story and your brand as the guide. This powerful tool will help you create a compelling narrative that resonates with your audience and drives engagement.
            </Typography>
            <Typography variant="h6" gutterBottom sx={{ color: theme.palette.secondary.main, mt: 2 }}>
              The StoryBrand Framework:
            </Typography>
            <List>
              <ListItem>
                <ListItemIcon>
                  <StarIcon color="primary" />
                </ListItemIcon>
                <ListItemText 
                  primary="A Character" 
                  secondary="Your customer is the hero of the story, not your brand."
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <StarIcon color="primary" />
                </ListItemIcon>
                <ListItemText 
                  primary="Has a Problem" 
                  secondary="Identify the external, internal, and philosophical problems your customer faces."
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <StarIcon color="primary" />
                </ListItemIcon>
                <ListItemText 
                  primary="Meets a Guide" 
                  secondary="Your brand positions itself as the experienced guide with empathy and authority."
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <StarIcon color="primary" />
                </ListItemIcon>
                <ListItemText 
                  primary="Who Gives Them a Plan" 
                  secondary="Provide a clear, step-by-step plan to solve the customer's problem."
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <StarIcon color="primary" />
                </ListItemIcon>
                <ListItemText 
                  primary="And Calls Them to Action" 
                  secondary="Clearly state what action the customer should take."
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <StarIcon color="primary" />
                </ListItemIcon>
                <ListItemText 
                  primary="That Helps Them Avoid Failure" 
                  secondary="Describe the negative consequences of not taking action."
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <StarIcon color="primary" />
                </ListItemIcon>
                <ListItemText 
                  primary="And Ends in Success" 
                  secondary="Paint a picture of the positive transformation your customer will experience."
                />
              </ListItem>
            </List>
            <Typography variant="body1" paragraph sx={{ mt: 2 }}>
              As you work through each section of the canvas, keep this framework in mind. The more clearly you can articulate each part, the more powerful your brand story will become.
            </Typography>
            <Typography variant="h6" gutterBottom sx={{ color: theme.palette.secondary.main, mt: 2 }}>
              Tips for Success:
            </Typography>
            <List>
              <ListItem>
                <ListItemIcon>
                  <LightbulbIcon color="primary" />
                </ListItemIcon>
                <ListItemText 
                  primary="Be Customer-Centric" 
                  secondary="Always focus on your customer's journey, not your brand's story."
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <LightbulbIcon color="primary" />
                </ListItemIcon>
                <ListItemText 
                  primary="Keep It Simple" 
                  secondary="Use clear, concise language that your customer can easily understand."
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <LightbulbIcon color="primary" />
                </ListItemIcon>
                <ListItemText 
                  primary="Be Specific" 
                  secondary="The more specific you can be in each section, the more impactful your story will be."
                />
              </ListItem>
            </List>
            <Typography variant="h6" gutterBottom sx={{ color: theme.palette.secondary.main, mt: 2 }}>
              Your Achievements:
            </Typography>
            {achievements.map((achievement, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
              >
                <Typography variant="body1">
                  <EmojiEventsIcon sx={{ verticalAlign: 'middle', mr: 1, color: theme.palette.primary.main }} />
                  {achievement}
                </Typography>
              </motion.div>
            ))}
            <Typography variant="body1" paragraph sx={{ mt: 2 }}>
              Complete each section to earn points and unlock achievements. The more thoughtful and detailed your responses, the stronger your brand story will be!
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowGuide(false)} color="primary">
              Close Guide
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </ThemeProvider>
  );
};

export default StoryBrandCanvas;