import axios from 'axios';
import { getToken } from './authService';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

/**
 * Centralized function to handle API requests.
 * @param {string} method - HTTP method (GET, POST, PUT, DELETE).
 * @param {string} endpoint - API endpoint path.
 * @param {object} [data=null] - Data to send with the request (for POST, PUT).
 * @returns {Promise<object>} - The API response data.
 * @throws {Error} - Throws an error if the API call fails.
 */
const apiCall = async (method, endpoint, data = null) => {
  const token = await getToken();
  if (!token) {
    throw new Error('Authorization token is missing');
  }

  const config = {
    method,
    url: `${API_BASE_URL}/api/${endpoint}`,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    data,
  };

  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    throw new Error(`API error: ${error.response?.data?.message || error.message}`);
  }
};

/**
 * Get a specific organisation canvas by ID.
 * @param {string} orgId - The organisation ID.
 * @param {string} id - The canvas ID.
 * @returns {Promise<object>} - The canvas data.
 * @throws {Error} - Throws an error if orgId or id are missing or if the API call fails.
 */
export const getOrgCanvas = async (orgId, id) => {
  if (!orgId || !id) {
    throw new Error('orgId and id are required for getting a canvas');
  }
  return apiCall('get', `organisations/${orgId}/canvases/${id}`);
};

/**
 * Create a new organisation canvas.
 * @param {string} orgId - The organisation ID.
 * @param {object} canvasData - The canvas data to create.
 * @returns {Promise<object>} - The created canvas data.
 */
export const createOrgCanvas = async (orgId, canvasData) => {
  return apiCall('post', `organisations/${orgId}/canvases`, canvasData);
};

/**
 * Delete an organisation canvas by ID.
 * @param {string} orgId - The organisation ID.
 * @param {string} id - The canvas ID.
 * @returns {Promise<void>}
 * @throws {Error} - Throws an error if orgId or id are missing or if the API call fails.
 */
export const deleteOrgCanvas = async (orgId, id) => {
  if (!orgId || !id) {
    throw new Error('orgId and id are required');
  }
  return apiCall('delete', `organisations/${orgId}/canvases/${id}`);
};

/**
 * List all canvases for an organisation.
 * @param {string} orgId - The organisation ID.
 * @returns {Promise<object[]>} - The list of canvases.
 */
export const listOrgCanvases = async (orgId) => {
  if (!orgId) {
    throw new Error('Organisation ID is undefined in listOrgCanvases');
  }
  const response = await apiCall('get', `organisations/${orgId}/canvases`);
  return processCanvases(response, orgId);
};

const getDefaultCanvases = (orgId) => {
  const canvasTypes = [
    "Business Strategy Canvas",
    "StoryBrand Canvas",
    "Business Model Canvas",
    "Growth Strategy Canvas",
    "Partner Channels Canvas",
    "Growth Experiments Canvas"
  ];
  
  return canvasTypes.map(type => ({
    id: null,
    type: type,
    name: `${orgId} - ${type}`,
    status: 'not_started',
    importance: getCanvasImportance(type).importance,
    priority: getCanvasImportance(type).priority,
    optional: getCanvasImportance(type).optional || false,
    exists: false
  }));
};

const processCanvases = (canvases, orgId) => {
  const canvasTypes = [
    "Business Strategy Canvas",
    "StoryBrand Canvas",
    "Business Model Canvas",
    "Growth Strategy Canvas",
    "Partner Channels Canvas",
    "Growth Experiments Canvas"
  ];
  
  return canvasTypes.map(type => {
    const canvas = canvases.find(c => c.type === type);
    const { importance, priority, optional } = getCanvasImportance(type);
    return {
      id: canvas ? canvas.id : null,
      type: type,
      name: canvas ? canvas.name : `${orgId} - ${type}`,
      status: canvas ? canvas.status : 'not_started',
      importance: importance,
      priority: priority,
      optional: optional || false,
      exists: !!canvas
    };
  });
};

/**
 * Update an organisation canvas by ID.
 * @param {string} orgId - The organisation ID.
 * @param {string} id - The canvas ID.
 * @param {object} canvasData - The updated canvas data.
 * @returns {Promise<object>} - The updated canvas data.
 * @throws {Error} - Throws an error if orgId or id are missing or if the API call fails.
 */
export const updateOrgCanvas = async (orgId, id, canvasData) => {
  if (!orgId || !id) {
    throw new Error('orgId and id are required for updating a canvas');
  }
  return apiCall('put', `organisations/${orgId}/canvases/${id}`, canvasData);
};

/**
 * Mark an organisation canvas as complete.
 * @param {string} orgId - The organisation ID.
 * @param {string} canvasId - The canvas ID.
 * @param {object} canvasData - The canvas data to save.
 * @returns {Promise<object>} - The updated canvas data.
 * @throws {Error} - Throws an error if orgId or canvasId are missing or if the API call fails.
 */
export const completeOrgCanvas = async (orgId, canvasId, canvasData) => {
  if (!orgId) {
    throw new Error('orgId is required for completing a canvas');
  }
  if (!canvasId) {
    throw new Error('canvasId is required for completing a canvas');
  }
  const updatedCanvas = await apiCall('put', `organisations/${orgId}/canvases/${canvasId}`, { 
    ...canvasData, 
    status: 'completed',
    completedAt: new Date().toISOString()
  });

  return updatedCanvas;
};

/**
 * Save a draft of an organisation canvas.
 * @param {string} orgId - The organisation ID.
 * @param {string} canvasId - The canvas ID.
 * @param {object} canvasData - The draft canvas data.
 * @returns {Promise<object>} - The updated canvas data.
 * @throws {Error} - Throws an error if orgId or canvasId are missing or if the API call fails.
 */
export const saveDraftOrgCanvas = async (orgId, canvasId, canvasData) => {
  if (!orgId) {
    throw new Error('orgId is required for saving a draft canvas');
  }
  if (!canvasId) {
    throw new Error('canvasId is required for saving a draft canvas');
  }
  return apiCall('put', `organisations/${orgId}/canvases/${canvasId}`, { ...canvasData, status: 'draft' });
};

const getCanvasImportance = (canvasType) => {
  const importanceMap = {
    "Business Strategy Canvas": { importance: "High", priority: 1 },
    "StoryBrand Canvas": { importance: "High", priority: 2 },
    "Business Model Canvas": { importance: "High", priority: 3 },
    "Growth Strategy Canvas": { importance: "Medium", priority: 4 },
    "Partner Channels Canvas": { importance: "Medium", priority: 5 },
    "Growth Experiments Canvas": { importance: "Low", priority: 6, optional: true }
  };
  return importanceMap[canvasType] || { importance: "Low", priority: 7 };
};
