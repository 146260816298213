import React, { useState, useEffect } from 'react';
import BidBaseCanvas from './BidBaseCanvas';
import { Assessment as StrategyIcon } from '@mui/icons-material';
import { updateBidCanvas, getBidCanvas, createBidCanvas } from '../../../services/bidcanvasService';

const sections = [
  { field: 'winStrategy', title: 'Win Strategy', width: 6, weight: 3 },
  { field: 'competitiveAdvantage', title: 'Competitive Advantage', width: 6, weight: 3 },
  { field: 'clientHotButtons', title: 'Client Hot Buttons', width: 4, weight: 2 },
  { field: 'solutionOverview', title: 'Solution Overview', width: 4, weight: 2 },
  { field: 'pricingStrategy', title: 'Pricing Strategy', width: 4, weight: 2 },
  { field: 'riskMitigation', title: 'Risk Mitigation', width: 6, weight: 2 },
  { field: 'teamCapabilities', title: 'Team Capabilities', width: 6, weight: 1 },
];

const tooltips = {
  winStrategy: "Outline your overall strategy to win this bid. Include key differentiators and value propositions. Format: Concise paragraph or bullet points. Example: 'Leverage our unique AI technology to offer a solution that reduces operational costs by 30% while improving accuracy by 50%. Emphasize our successful track record in similar projects.'",
  
  competitiveAdvantage: "Identify and explain your key competitive advantages. Consider technology, expertise, past performance, and unique methodologies. Format: Bullet points with brief explanations. Example: '• Proprietary AI algorithm: 40% more efficient than industry standard\n• Team of certified experts: Over 100 years combined experience'",
  
  clientHotButtons: "List the client's key concerns, priorities, or 'hot buttons'. These are issues that are particularly important to the client. Format: Prioritized list. Example: '1. Cost reduction (Critical)\n2. Scalability (High)\n3. Ease of integration (Medium)'",
  
  solutionOverview: "Provide a high-level overview of your proposed solution. Focus on how it addresses the client's needs and hot buttons. Format: Structured summary. Example: 'Our cloud-based AI solution offers:\n- 30% cost reduction through process automation\n- Scalable architecture supporting up to 10,000 concurrent users\n- Seamless integration with existing systems via standard APIs'",
  
  pricingStrategy: "Outline your pricing approach for this bid. Consider value-based pricing, competitive positioning, and any strategic discounts. Format: Pricing structure with rationale. Example: 'Tiered pricing model:\n- Base package: $100K (covers core needs)\n- Premium features: +$50K (addresses scalability)\nStrategic 10% discount to position below Competitor X'",
  
  riskMitigation: "Identify potential risks and your strategies to mitigate them. Consider both project execution risks and competitive risks. Format: Risk-mitigation pairs. Example: 'Risk: Tight timeline\nMitigation: Dedicated team, parallel workstreams\n\nRisk: Incumbent advantage\nMitigation: Emphasize superior technology, offer transition support'",
  
  teamCapabilities: "Highlight the key capabilities and experiences of your proposed team. Focus on aspects directly relevant to this project. Format: Role-based list with key strengths. Example: 'Project Lead: Jane Doe - 15 years in similar projects, certified PMP\nTechnical Architect: John Smith - Designer of our core AI algorithm, PhD in Machine Learning'"
};

function BidStrategyCanvas({ user, selectedOrg, canvas, onClose, onSave, bidId, bidName, brandData, toneOfVoiceData, bidData }) {
  const [canvasData, setCanvasData] = useState(canvas || {});
  const [confidenceScore, setConfidenceScore] = useState(0);

  const calculateConfidenceScore = (ratings = {}) => {
    const totalSections = sections.length;
    let totalScore = 0;

    sections.forEach(section => {
      const rating = ratings[section.field] || 'low';
      switch (rating) {
        case 'high':
          totalScore += 3;
          break;
        case 'medium':
          totalScore += 2;
          break;
        case 'low':
        default:
          totalScore += 1;
          break;
      }
    });

    return Math.round((totalScore / (totalSections * 3)) * 100);
  };

  const handleUpdate = async (updatedCanvas, status) => {
    try {
      let savedCanvas;
      if (updatedCanvas.id) {
        savedCanvas = await updateBidCanvas(bidId, updatedCanvas.id, updatedCanvas);
      } else {
        const newCanvas = {
          ...updatedCanvas,
          name: `${bidName} - Bid Strategy Canvas`,
          type: 'Bid Strategy Canvas',
        };
        savedCanvas = await createBidCanvas(bidId, newCanvas);
      }
      onSave(savedCanvas, status);
    } catch (error) {
      console.error('Error updating canvas:', error);
    }
  };

  const handleRatingChange = (updatedCanvas) => {
    const newConfidenceScore = calculateConfidenceScore(updatedCanvas.ratings);
    setConfidenceScore(newConfidenceScore);
    setCanvasData(updatedCanvas);
  };

  useEffect(() => {
    if (canvas) {
      const parsedCanvas = {
        ...canvas,
        ratings: typeof canvas.ratings === 'string' ? JSON.parse(canvas.ratings) : (canvas.ratings || {})
      };
      setCanvasData(parsedCanvas);
      const initialConfidenceScore = calculateConfidenceScore(parsedCanvas.ratings);
      setConfidenceScore(initialConfidenceScore);
    }
  }, [canvas]);

  return (
    <BidBaseCanvas 
      user={user}
      selectedOrg={selectedOrg}
      canvas={canvasData}
      onClose={onClose}
      onUpdate={handleUpdate}
      onRatingChange={handleRatingChange}
      canvasType="Bid Strategy Canvas"
      sections={sections}
      tooltips={tooltips}
      icon={StrategyIcon}
      bidName={bidName}
      confidenceScore={confidenceScore}
      brandData={brandData}
      toneOfVoiceData={toneOfVoiceData}
      allFormData={canvasData}
      bidData={bidData}
    />
  );
}

export default BidStrategyCanvas;
