// RFPQualificationCanvas.js

import React, { useState, useEffect } from 'react';
import BidBaseCanvas from './BidBaseCanvas';
import { AssignmentTurnedIn } from '@mui/icons-material';
import { updateBidCanvas, getBidCanvas, createBidCanvas } from '../../../services/bidcanvasService';

// Updated sections to incorporate TAS principles
const sections = [
  { field: 'customerNeeds', title: 'Customer Needs and Pain Points', width: 6 },
  { field: 'solutionFit', title: 'Solution Fit', width: 6 },
  { field: 'competitivePosition', title: 'Competitive Position', width: 6 },
  { field: 'relationshipStrength', title: 'Relationship Strength', width: 6 },
  { field: 'decisionMakingProcess', title: 'Decision Making Process', width: 6 },
  { field: 'strategicValue', title: 'Strategic Value', width: 6 },
  { field: 'financialConsiderations', title: 'Financial Considerations', width: 6 },
  { field: 'riskAssessment', title: 'Risk Assessment', width: 6 },
];

// Updated tooltips for each section
const tooltips = {
  customerNeeds: "Identify and list the customer's key pain points and business needs. Consider both explicit and implicit needs. Format: Bullet points. Example: '• Reduce operational costs by 20%\n• Improve customer satisfaction scores by 15%'",
  
  solutionFit: "Evaluate how well your solution addresses the customer's needs. Use a scale of 1-10 for each need and provide brief explanations. Format: Scored list. Example: '9/10 - Cost reduction: Our solution automates 80% of manual processes'",
  
  competitivePosition: "Analyze your position relative to competitors. Consider strengths, weaknesses, and unique selling points. Format: SWOT-style list. Example: 'Strength: Industry-leading AI technology\nWeakness: Higher initial implementation cost'",
  
  relationshipStrength: "Assess the quality of your relationship with key stakeholders. Include history, trust level, and recent interactions. Format: Short paragraphs. Example: 'Strong relationship with CTO (8/10). Recent successful project completion improved trust.'",
  
  decisionMakingProcess: "Map out the customer's decision-making process, including key decision-makers, their roles, and evaluation criteria. Format: Flowchart or bullet points. Example: '1. Initial screening by IT team\n2. Technical evaluation by CTO\n3. Final approval by CFO and CEO'",
  
  strategicValue: "Evaluate the long-term strategic value of winning this bid. Consider future opportunities, market position, and alignment with your company's goals. Format: Pros and cons list. Example: 'Pros: Opens up healthcare sector, aligns with AI focus\nCons: Requires significant resource allocation'",
  
  financialConsiderations: "Analyze the financial implications, including potential ROI, resource requirements, and long-term profitability. Format: Financial summary. Example: 'Estimated project value: $500K\nExpected ROI: 200% over 3 years\nBreak-even point: 18 months'",
  
  riskAssessment: "Identify potential risks associated with the project and propose mitigation strategies. Format: Risk matrix. Example: 'Risk: Tight deadline (High)\nMitigation: Allocate additional resources, establish clear milestones'"
};

/**
 * RFPQualificationCanvas Component
 *
 * Props:
 * - user: The current user object.
 * - selectedOrg: The selected organization object.
 * - canvas: The existing canvas data (if any).
 * - onClose: Function to handle closing the canvas.
 * - onSave: Callback function to handle save actions.
 * - bidId: The ID of the bid.
 * - bidName: The name of the bid.
 * - brandData: The brand data object.
 * - toneOfVoiceData: The tone of voice data object.
 * - bidData: The bid data object.
 */
function RFPQualificationCanvas({ user, selectedOrg, canvas, onClose, onSave, bidId, bidName, brandData, toneOfVoiceData, bidData }) {
  const [canvasData, setCanvasData] = useState(canvas || {});
  const [confidenceScore, setConfidenceScore] = useState(0);

  const calculateConfidenceScore = (ratings = {}) => {
    const totalSections = sections.length;
    let totalScore = 0;

    sections.forEach(section => {
      const rating = ratings[section.field] || 'low';
      switch (rating) {
        case 'high':
          totalScore += 3;
          break;
        case 'medium':
          totalScore += 2;
          break;
        case 'low':
        default:
          totalScore += 1;
          break;
      }
    });

    return Math.round((totalScore / (totalSections * 3)) * 100);
  };

  const handleUpdate = async (updatedCanvas, status) => {
    try {
      let savedCanvas;
      if (updatedCanvas.id) {
        savedCanvas = await updateBidCanvas(bidId, updatedCanvas.id, updatedCanvas);
      } else {
        const newCanvas = {
          ...updatedCanvas,
          name: `${bidName} - RFP Qualification Canvas`,
          type: 'RFP Qualification Canvas',
        };
        savedCanvas = await createBidCanvas(bidId, newCanvas);
      }
      onSave(savedCanvas, status);
    } catch (error) {
      console.error('Error updating canvas:', error);
    }
  };

  const handleRatingChange = (updatedCanvas) => {
    const newConfidenceScore = calculateConfidenceScore(updatedCanvas.ratings);
    setConfidenceScore(newConfidenceScore);
    setCanvasData(updatedCanvas);
  };

  useEffect(() => {
    if (canvas) {
      const parsedCanvas = {
        ...canvas,
        ratings: typeof canvas.ratings === 'string' ? JSON.parse(canvas.ratings) : (canvas.ratings || {})
      };
      setCanvasData(parsedCanvas);
      const initialConfidenceScore = calculateConfidenceScore(parsedCanvas.ratings);
      setConfidenceScore(initialConfidenceScore);
    }
  }, [canvas]);

  return (
    <BidBaseCanvas 
      user={user}
      selectedOrg={selectedOrg}
      canvas={canvasData}
      onClose={onClose}
      onUpdate={handleUpdate}
      onRatingChange={handleRatingChange}
      canvasType="RFP Qualification Canvas"
      sections={sections}
      tooltips={tooltips}
      icon={AssignmentTurnedIn}
      bidName={bidName}
      confidenceScore={confidenceScore}
      brandData={brandData}
      toneOfVoiceData={toneOfVoiceData}
      allFormData={canvasData}
      bidData={bidData}
    />
  );
}

export default RFPQualificationCanvas;
