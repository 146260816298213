import React, { useState, useEffect } from 'react';
import BaseCanvas from './BaseCanvas';
import StrategyIcon from '@mui/icons-material/Flag';
import { Box, Button, Typography, useTheme } from '@mui/material';

const BusinessStrategyCanvas = ({ user, selectedOrg, canvas, onClose, onSave }) => {
  const [canvasData, setCanvasData] = useState(canvas || {});
  const theme = useTheme();

  useEffect(() => {
    if (canvas) {
      setCanvasData(canvas);
    }
  }, [canvas]);

  const handleSave = async (status) => {
    try {
      if (!selectedOrg) {
        throw new Error('No organization selected');
      }
      
      const orgId = typeof selectedOrg === 'string' ? selectedOrg : selectedOrg.id;
      
      if (!orgId) {
        throw new Error('Invalid organization ID');
      }
      
      if (!canvasData || !canvasData.id) {
        throw new Error('No canvas ID available');
      }
      
      await onSave({ ...canvasData, status, orgId });
      onClose();
    } catch (error) {
      console.error(`Failed to save canvas as ${status}. Please try again. Error: ${error.message}`);
    }
  };

  const sections = [
    { field: 'vision', title: 'Vision', rows: 4 },
    { field: 'mission', title: 'Mission', rows: 4 },
    { field: 'coreValues', title: 'Core Values', rows: 4 },
    { field: 'swotAnalysis', title: 'SWOT Analysis', rows: 6 },
    { field: 'longTermObjectives', title: 'Long-term Objectives', rows: 4 },
    { field: 'competitiveAdvantage', title: 'Competitive Advantage', rows: 4 },
    { field: 'marketPositioning', title: 'Market Positioning', rows: 4 },
    { field: 'keyStrategies', title: 'Key Strategies', rows: 6 },
    { field: 'resourceAllocation', title: 'Resource Allocation', rows: 4 },
    { field: 'keyPerformanceIndicators', title: 'Key Performance Indicators', rows: 4 },
  ];

  const tooltips = {
    vision: "Define your long-term vision for the company. Format: Concise statement. Example: 'To become the global leader in sustainable energy solutions by 2030.'",
    mission: "State your company's purpose and primary objectives. Format: Brief paragraph. Example: 'Our mission is to accelerate the world's transition to sustainable energy through innovative technology and exceptional customer service.'",
    coreValues: "List the fundamental beliefs that guide your company's actions. Format: Bullet points. Example: '• Integrity\n• Innovation\n• Sustainability\n• Customer-centricity'",
    swotAnalysis: "Analyze your Strengths, Weaknesses, Opportunities, and Threats. Format: SWOT matrix. Example: 'Strengths: Strong R&D team\nWeaknesses: Limited market presence\nOpportunities: Growing demand for green energy\nThreats: Intense competition'",
    longTermObjectives: "Define specific, measurable long-term goals. Format: Numbered list. Example: '1. Achieve 30% market share in renewable energy sector by 2025\n2. Expand operations to 50 countries within 5 years'",
    competitiveAdvantage: "Identify what sets you apart from competitors. Format: Bullet points with brief explanations. Example: '• Proprietary technology: 40% more efficient than industry standard\n• Strong partnerships: Exclusive deals with key suppliers'",
    marketPositioning: "Describe how you want to be perceived in the market. Format: Concise statement. Example: 'The premium provider of innovative and reliable sustainable energy solutions for environmentally conscious businesses.'",
    keyStrategies: "Outline your high-level plans to achieve your objectives. Format: Numbered list with brief descriptions. Example: '1. Product Innovation: Invest 15% of revenue in R&D\n2. Market Expansion: Enter 3 new markets annually'",
    resourceAllocation: "Specify how you'll allocate resources to support your strategy. Format: Percentage breakdown. Example: 'R&D: 15%\nMarketing: 20%\nOperations: 40%\nHuman Resources: 15%\nAdministration: 10%'",
    keyPerformanceIndicators: "List the metrics you'll use to measure success. Format: Bullet points with targets. Example: '• Revenue growth: 20% year-over-year\n• Customer satisfaction: Net Promoter Score > 50\n• Market share: Increase by 2% annually'",
  };

  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column',
      height: '100vh',
      bgcolor: theme.palette.background.default,
      color: theme.palette.text.primary,
    }}>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
        p: 2, 
        borderBottom: `1px solid ${theme.palette.divider}`
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <StrategyIcon sx={{ mr: 1, fontSize: 28, color: theme.palette.secondary.main }} />
          <Typography variant="h5" sx={{ fontWeight: 500 }}>Business Strategy Canvas</Typography>
        </Box>
        <Button startIcon={<StrategyIcon />} onClick={onClose}>
          Close
        </Button>
      </Box>

      <Box sx={{ flexGrow: 1, overflowY: 'auto', p: 3 }}>
        <BaseCanvas
          user={user}
          selectedOrg={selectedOrg}
          canvas={canvasData}
          onClose={onClose}
          onUpdate={(field, value) => setCanvasData(prev => ({ ...prev, [field]: value }))}
          onSave={handleSave}
          canvasType="Business Strategy Canvas"
          sections={sections}
          tooltips={tooltips}
          icon={StrategyIcon}
        />
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2, borderTop: `1px solid ${theme.palette.divider}` }}>
        <Button onClick={onClose} variant="outlined">
          Close
        </Button>
        <Box>
          <Button onClick={() => handleSave('draft')} variant="outlined" sx={{ mr: 1 }}>
            Save Draft
          </Button>
          <Button onClick={() => handleSave('completed')} variant="contained" color="primary">
            Complete
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default BusinessStrategyCanvas;
