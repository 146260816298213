import api from './api';
import { getAuthHeader } from './authService';

const apiCall = async (method, endpoint, data = null) => {
  try {
    const token = await getAuthHeader();
    const config = {
      method,
      url: endpoint,
      headers: { Authorization: token },
    };
    if (data) {
      config.data = data;
    }
    ////console.log('API Call Config:', config);
    const response = await api(config);
    ////console.log('API Response:', response);
    return response.data;
  } catch (error) {
    console.error(`Error in ${method} ${endpoint}:`, error);
    throw new Error(`API error: ${error.response?.data?.message || error.message}`);
  }
};

// Get the current gate for a bid
export const getBidGate = async (bidId) => {
  ////console.log('getBidGate called with bidId:', bidId, 'Type:', typeof bidId);
  if (!bidId) {
    console.error('getBidGate: Bid ID is required');
    throw new Error('Bid ID is required');
  }
  const result = await apiCall('get', `/api/bids/${bidId}/gates`);
  ////console.log('getBidGate result:', result);
  return result;
};

// Update the current gate for a bid
export const updateBidGate = async (bidId, currentGate) => {
  ////console.log('updateBidGate called with bidId:', bidId, 'Type:', typeof bidId, 'currentGate:', currentGate);
  if (!bidId) {
    console.error('updateBidGate: Bid ID is required');
    throw new Error('Bid ID is required');
  }
  return apiCall('put', `/api/bids/${bidId}/gates`, { currentGate });
};

export default {
  getBidGate,
  updateBidGate,
};
