import React, { useState, useEffect } from 'react';
import BaseCanvas from './BaseCanvas';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { 
  Box, Button, Typography, useTheme, Grid
} from '@mui/material';

const GrowthStrategyCanvas = ({ user, selectedOrg, canvas, onClose, onSave }) => {
  const [canvasData, setCanvasData] = useState(canvas || {});
  const theme = useTheme();

  useEffect(() => {
    if (canvas) {
      setCanvasData(canvas);
    }
  }, [canvas]);

  const handleSave = async (status) => {
    try {
      if (!selectedOrg) {
        throw new Error('No organization selected');
      }
      
      const orgId = typeof selectedOrg === 'string' ? selectedOrg : selectedOrg.id;
      
      if (!orgId) {
        throw new Error('Invalid organization ID');
      }
      
      if (!canvasData || !canvasData.id) {
        throw new Error('No canvas ID available');
      }
      
      // Call onSave and wait for it to complete
      await onSave({ ...canvasData, status, orgId });
      
      // Close the canvas after successful save
      onClose();
    } catch (error) {
      console.error(`Failed to save canvas as ${status}. Please try again. Error: ${error.message}`);
      // You may want to show an error message to the user here
    }
  };

  const sections = [
    { 
      field: 'situationalAnalysis', 
      title: 'Situational Analysis (SWOT)', 
      rows: 8, 
      inputType: 'swot',
      hideAIAssist: true,
      subSections: [
        { field: 'strengths', title: 'Strengths', rows: 2 },
        { field: 'weaknesses', title: 'Weaknesses', rows: 2 },
        { field: 'opportunities', title: 'Opportunities', rows: 2 },
        { field: 'threats', title: 'Threats', rows: 2 },
      ]
    },
    { field: 'growthObjectives', title: 'Growth Objectives', rows: 4, inputType: 'richtext' },
    { field: 'targetMarkets', title: 'Target Markets', rows: 4, inputType: 'richtext' },
    { field: 'valueProposition', title: 'Value Proposition', rows: 4, inputType: 'richtext' },
    { field: 'growthStrategies', title: 'Growth Strategies', rows: 6, inputType: 'richtext' },
    { field: 'marketingTactics', title: 'Marketing Tactics', rows: 4, inputType: 'richtext' },
    { field: 'salesTactics', title: 'Sales Tactics', rows: 4, inputType: 'richtext' },
    { field: 'keyMetrics', title: 'Key Performance Indicators (KPIs)', rows: 4, inputType: 'richtext' },
    { 
      field: 'resourceAllocation', 
      title: 'Resource Allocation', 
      inputType: 'select',
      options: [
        { value: 'low', label: 'Low' },
        { value: 'medium', label: 'Medium' },
        { value: 'high', label: 'High' },
      ]
    },
    { field: 'timeline', title: 'Implementation Timeline', inputType: 'date' },
  ];

  const tooltips = {
    situationalAnalysis: "Conduct a comprehensive SWOT analysis of your organization's current position.",
    strengths: "List internal factors that give your organization an advantage over others. Consider unique resources, capabilities, and competitive advantages. Format: Bullet points. Example: '• Strong brand recognition\n• Proprietary technology\n• Experienced leadership team'",
    weaknesses: "Identify internal factors that place your organization at a disadvantage relative to others. Consider areas for improvement or limitations. Format: Bullet points. Example: '• Limited market presence in certain regions\n• High production costs\n• Lack of digital marketing expertise'",
    opportunities: "List external factors that your organization could capitalize on to its advantage. Consider market trends, industry changes, or new customer needs. Format: Bullet points. Example: '• Growing demand for sustainable products\n• Emerging markets in Asia\n• Potential for strategic partnerships'",
    threats: "Identify external factors that could cause trouble for your organization. Consider competitive pressures, regulatory changes, or economic factors. Format: Bullet points. Example: '• Increasing competition from new market entrants\n• Changing consumer preferences\n• Potential economic downturn'",
    growthObjectives: "Define specific, measurable growth goals. Format: SMART goals. Example: '1. Increase market share by 5% in the next 12 months\n2. Enter 2 new geographic markets within 18 months'",
    targetMarkets: "Identify and describe your ideal customer segments for growth. Format: Detailed profiles. Example: 'Segment A: Small businesses in tech sector, 10-50 employees, $1-5M annual revenue\nSegment B: Mid-size manufacturers, 100-500 employees, $10-50M annual revenue'",
    valueProposition: "Articulate your unique value proposition for each target market. Format: Concise statements. Example: 'For small tech businesses: Streamline operations and reduce costs by 30%\nFor mid-size manufacturers: Increase productivity and quality control by 40%'",
    growthStrategies: "Outline your main strategies for achieving growth. Format: Strategy statements with brief explanations. Example: '1. Market Penetration: Increase sales to existing customers through upselling and cross-selling\n2. Product Development: Launch 2 new product lines tailored to our target markets'",
    marketingTactics: "Detail your marketing plans to support your growth strategies. Format: Tactical plan. Example: '1. Content Marketing: Develop industry-specific whitepapers and case studies\n2. Account-Based Marketing: Implement targeted campaigns for top 100 prospects'",
    salesTactics: "Describe your sales approach to drive growth. Format: Sales process outline. Example: '1. Lead Qualification: Implement BANT criteria\n2. Sales Enablement: Provide weekly product training\n3. Follow-up: Implement 5-touch follow-up process'",
    keyMetrics: "List the key metrics you'll use to measure growth progress. Format: Metrics with targets. Example: '• Customer Acquisition Cost (CAC): Reduce by 15%\n• Customer Lifetime Value (CLV): Increase by 25%\n• Net Promoter Score (NPS): Achieve score of 50+'",
    resourceAllocation: "Specify how you'll allocate resources to support growth. Format: Percentage breakdown. Example: 'Marketing: 30%\nSales: 25%\nProduct Development: 20%\nCustomer Success: 15%\nOperations: 10%'",
    timeline: "Create a timeline for implementing your growth strategies and tactics. Format: Quarterly plan. Example: 'Q1: Market research and strategy refinement\nQ2: New product development and marketing preparation\nQ3: Product launch and sales push\nQ4: Evaluation and adjustment'",
  };

  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column',
      height: '100vh',
      bgcolor: theme.palette.background.default,
      color: theme.palette.text.primary,
    }}>
      {/* Header */}
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
        p: 2, 
        borderBottom: `1px solid ${theme.palette.divider}`
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <TrendingUpIcon sx={{ mr: 1, fontSize: 28, color: theme.palette.secondary.main }} />
          <Typography variant="h5" sx={{ fontWeight: 500 }}>Growth Strategy Canvas</Typography>
        </Box>
        <Button startIcon={<TrendingUpIcon />} onClick={onClose}>
          Close
        </Button>
      </Box>

      {/* Content */}
      <Box sx={{ flexGrow: 1, overflowY: 'auto', p: 3 }}>
        <BaseCanvas
          user={user}
          selectedOrg={selectedOrg}
          canvas={canvasData}
          onClose={onClose}
          onUpdate={(field, value) => setCanvasData(prev => ({ ...prev, [field]: value }))}
          canvasType="Growth Strategy Canvas"
          sections={sections}
          tooltips={tooltips}
          icon={TrendingUpIcon}
        />
      </Box>

      {/* Footer */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2, borderTop: `1px solid ${theme.palette.divider}` }}>
        <Button onClick={onClose} variant="outlined">
          Close
        </Button>
        <Box>
          <Button onClick={() => handleSave('draft')} variant="outlined" sx={{ mr: 1 }}>
            Save Draft
          </Button>
          <Button onClick={() => handleSave('completed')} variant="contained" color="primary">
            Complete
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default GrowthStrategyCanvas;
