import React, { useState } from 'react';
import { 
  Box, 
  AppBar, 
  Toolbar, 
  Typography, 
  IconButton, 
  Menu, 
  MenuItem, 
  Avatar, 
  Button,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  useMediaQuery,
  Chip
} from '@mui/material';
import { 
  Menu as MenuIcon, 
  MoreVert as MoreVertIcon,
  Dashboard as DashboardIcon,
  Assignment as AssignmentIcon,
  Settings as SettingsIcon,
  ExitToApp as LogoutIcon,
  BrandingWatermark as BrandingWatermarkIcon,
  Work as PortfolioIcon,
  AccountBalance as AccountBalanceIcon,
  Business as BusinessIcon,
  LibraryBooks as LibraryBooksIcon
} from '@mui/icons-material';
import { Outlet, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useGlobalStyles from '../../styles/globalStyles';
import { signOut } from '../../services/authService';

const drawerWidth = 240;

const Layout = ({ user, selectedOrg }) => {
  const globalClasses = useGlobalStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleLogout = async () => {
    try {
      await signOut();
    } catch (error) {
    }
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={Boolean(anchorEl)}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={() => { handleMenuClose(); navigate('/profile'); }}>Profile</MenuItem>
      <MenuItem onClick={() => { handleMenuClose(); navigate('/my-organisation'); }}>
        <BusinessIcon sx={{ mr: 1 }} />
        My Organisation
      </MenuItem>
      <MenuItem onClick={() => { handleMenuClose(); navigate('/subscriptions'); }}>Subscriptions</MenuItem>
      <MenuItem onClick={() => { handleMenuClose(); handleLogout(); }}>Logout</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={Boolean(mobileMoreAnchorEl)}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <Avatar alt={user?.name} src={user?.profilePicture} />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  const drawerContent = (
    <div>
      <Toolbar />
      <Divider />
      <List>
        <ListItem component="div" onClick={() => { navigate('/dashboard'); isMobile && setDrawerOpen(false); }}>
          <ListItemIcon><DashboardIcon /></ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>
        <ListItem component="div" onClick={() => { navigate('/bids'); isMobile && setDrawerOpen(false); }}>
          <ListItemIcon><AssignmentIcon /></ListItemIcon>
          <ListItemText primary="Bids" />
        </ListItem>
        <ListItem component="div" onClick={() => { navigate('/brand-builder'); isMobile && setDrawerOpen(false); }}>
          <ListItemIcon><BrandingWatermarkIcon /></ListItemIcon>
          <ListItemText primary="Brand" />
        </ListItem>
        <ListItem component="div" onClick={() => { navigate('/portfolio'); isMobile && setDrawerOpen(false); }}>
          <ListItemIcon><PortfolioIcon /></ListItemIcon>
          <ListItemText primary="Portfolio" />
        </ListItem>
        <ListItem component="div" onClick={() => { navigate('/accounts'); isMobile && setDrawerOpen(false); }}>
          <ListItemIcon><AccountBalanceIcon /></ListItemIcon>
          <ListItemText primary="Accounts" />
        </ListItem>
        <ListItem component="div" onClick={() => { navigate('/collateral'); isMobile && setDrawerOpen(false); }}>
          <ListItemIcon><LibraryBooksIcon /></ListItemIcon>
          <ListItemText primary="Collateral" />
        </ListItem>
      </List>
    </div>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar position="fixed" sx={{ zIndex: theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
            <i className="fas fa-seedling" style={{ fontSize: '1.5rem', marginRight: '0.5rem' }}></i>
            <Typography variant="h6" noWrap component="div">
              Puāwai
            </Typography>
            <Chip 
              label="beta" 
              size="small" 
              sx={{ 
                ml: 1, 
                fontSize: '0.7rem', 
                height: '20px',
                fontWeight: 'bold',
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.secondary.contrastText,
                textTransform: 'lowercase',
                borderRadius: '12px',
                '& .MuiChip-label': {
                  px: 1,
                },
              }} 
            />
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <Button color="inherit" onClick={() => navigate('/dashboard')}>Dashboard</Button>
            <Button color="inherit" onClick={() => navigate('/bids')}>Bids</Button>
            <Button color="inherit" onClick={() => navigate('/brand-builder')}>Brand Builder</Button>
            <Button color="inherit" onClick={() => navigate('/portfolio')}>Portfolio</Button>
            <Button color="inherit" onClick={() => navigate('/accounts')}>Accounts</Button>
            <Button color="inherit" onClick={() => navigate('/collateral')}>Collateral</Button>
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <Avatar alt={user?.name} src={user?.profilePicture} />
            </IconButton>
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreVertIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      <Drawer
        variant="temporary"
        open={drawerOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        {drawerContent}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
        open
      >
        {drawerContent}
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          mt: '64px',
          p: 2,
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default Layout;
