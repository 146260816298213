export const convertToNZEnglish = (text) => {
  // This is a simple example. You may want to expand this function
  // with more comprehensive word replacements.
  const replacements = {
    'organization': 'organisation',
    'color': 'colour',
    'customize': 'customise',
    'center': 'centre',
    // Add more American to NZ English replacements as needed
  };

  return text.replace(/\b(?:organization|color|customize|center)\b/gi, matched => 
    replacements[matched.toLowerCase()] || matched
  );
};
