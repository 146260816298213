import React, { useState, useEffect } from 'react';
import { 
  IconButton, 
  Tooltip, 
  Slide,
  Typography, 
  Box,
  Chip,
  TextField,
  Button,
  useTheme,
  Paper,
  Snackbar,
  Alert,
  alpha,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import { motion, AnimatePresence } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSeedling } from '@fortawesome/free-solid-svg-icons';
import { brandBuilderChatService } from '../../services/ai/puawaiBrandAIService';
import { listOrgCanvases } from '../../services/organisationcanvasService';

const PuawaiBidCanvasAIAssistButton = ({ 
  fieldName, 
  fieldTitle, // Add this new prop
  currentValue, 
  onSuggestion, 
  bidData,
  canvasType,
  currentStepData,
  tooltip,
  allFormData,
  selectedOrg,
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [selectedSuggestion, setSelectedSuggestion] = useState(currentValue || '');
  const [chatInput, setChatInput] = useState('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [mode, setMode] = useState('suggestions');
  const [ideas, setIdeas] = useState([]);
  const [newIdea, setNewIdea] = useState('');
  const [orgCanvasData, setOrgCanvasData] = useState(null);
  const theme = useTheme();

  useEffect(() => {
    const fetchOrgCanvases = async () => {
      if (selectedOrg) {
        try {
          const canvases = await listOrgCanvases(selectedOrg.id);
          setOrgCanvasData(canvases);
        } catch (error) {
          console.error('Error fetching organization canvases:', error);
        }
      }
    };

    fetchOrgCanvases();
  }, [selectedOrg]);

  useEffect(() => {
    if (currentValue && currentValue !== selectedSuggestion) {
      setSelectedSuggestion(currentValue);
    }
  }, [currentValue]);

  const handleClick = async () => {
    setOpen(true);
    if (currentValue && !selectedSuggestion) {
      setSelectedSuggestion(currentValue);
    }
    if (suggestions.length === 0) {
      await generateInitialSuggestions();
    }
  };

  const generatePrompt = (message) => {
    let prompt = `${message}\n\nField: ${fieldName}\nCurrent Value: ${currentValue}\nCanvas Type: ${canvasType}\n\nGuidance: ${tooltip}\n\nProvide a suggestion for the ${fieldName} field in the context of a ${canvasType}. Follow the format and example provided in the guidance. Do not include any introductory phrases, explanations, timestamps, or concluding remarks. Start directly with the content for the field and end immediately after the relevant content.`;

    if (orgCanvasData) {
      prompt += "\n\nOrganization Canvas Data:";
      orgCanvasData.forEach(canvas => {
        prompt += `\n${canvas.name}: ${JSON.stringify(canvas.data)}`;
      });
    }

    prompt += "\n\nEnsure your response follows the format specified in the guidance and matches the style of the example provided, without any additional text before or after the content. Do not include any metadata or execution information in your response.";

    return prompt;
  };

  const generateInitialSuggestions = async () => {
    const initialPrompt = generatePrompt(`Provide a suggestion for the ${fieldName} field in bid strategy. Consider the following context:\n${JSON.stringify(allFormData, null, 2)}`);
    const response = await brandBuilderChatService.sendMessage(initialPrompt, {
      bidData,
      canvasType,
      currentStepData,
      allFormData,
      orgCanvasData,
    });
    const processedSuggestion = processAIResponse(response.response.trim());
    setSuggestions([processedSuggestion]);
    setSelectedSuggestion(processedSuggestion);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedSuggestion(currentValue);
  };

  const handleSuggestionSelect = (suggestion) => {
    setSelectedSuggestion(suggestion);
    setSnackbar({ open: true, message: 'Suggestion selected', severity: 'info' });
  };

  const handleApply = () => {
    if (selectedSuggestion && selectedSuggestion !== currentValue) {
      onSuggestion(selectedSuggestion);
      handleClose();
      setSnackbar({ open: true, message: 'AI suggestion applied successfully!', severity: 'success' });
    } else {
      setSnackbar({ open: true, message: 'No changes to apply', severity: 'info' });
    }
  };

  const handleChatSubmit = async (message = chatInput) => {
    if (!message.trim()) return;

    try {
      setLoading(true);
      const formattedMessage = generatePrompt(message);
      const contextData = {
        bidData,
        canvasType,
        currentStepData,
        allFormData,
        orgCanvasData,
      };
      const response = await brandBuilderChatService.sendMessage(formattedMessage, contextData);
      
      // Process the AI's response to remove any introductory text
      const newSuggestion = processAIResponse(response.response.trim());
      setSuggestions(prev => [...new Set([...prev, newSuggestion])].slice(0, 5));
      setSelectedSuggestion(newSuggestion);
      setChatInput('');
    } catch (error) {
      console.error('Error in chat:', error);
      setSnackbar({ open: true, message: 'Error generating AI suggestion. Please try again.', severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  // Update the processAIResponse function
  const processAIResponse = (response) => {
    // Remove any introductory text, including date/time stamps and "Received response from OpenAI:" messages
    let processedResponse = response.replace(/^\[.*?\].*?(?:Here's|This is|Following is).*?:\s*/gims, '');
    
    // Remove any concluding text (like "Feel free to adjust...")
    processedResponse = processedResponse.replace(/\n---.*$/s, '');
    
    // Remove phrases like "Here's a refined suggestion" or "Incorporating the ideas..."
    processedResponse = processedResponse.replace(/^.*?(refined|incorporating|combined).*?:/i, '');
    
    // Remove any bullet points or asterisks at the start of the response
    processedResponse = processedResponse.replace(/^[•\-\*]\s*/gm, '');
    
    // Remove any extra newlines at the beginning or end
    processedResponse = processedResponse.replace(/^\n+|\n+$/g, '');
    
    // Remove any "Executed 'Functions..." lines
    processedResponse = processedResponse.replace(/^Executed 'Functions.*$/gm, '');

    // Trim any remaining whitespace
    processedResponse = processedResponse.trim();
    
    return processedResponse;
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbar({ ...snackbar, open: false });
  };

  const handleModeChange = (event, newMode) => {
    if (newMode !== null) {
      setMode(newMode);
      if (newMode === 'suggestions' && selectedSuggestion) {
        setSuggestions(prev => [...new Set([...prev, selectedSuggestion])].slice(0, 5));
      }
    }
  };

  const handleAddIdea = () => {
    if (newIdea.trim()) {
      setIdeas([...ideas, newIdea.trim()]);
      setNewIdea('');
    }
  };

  const handleRemoveIdea = (index) => {
    setIdeas(ideas.filter((_, i) => i !== index));
  };

  const handleCombineIdeas = async () => {
    if (ideas.length === 0) return;

    setLoading(true);
    try {
      const prompt = `Combine and refine the following ideas for the ${fieldName} field:\n${ideas.join('\n')}\n\nProvide a suggestion that incorporates the best elements of these ideas and follows this format: ${tooltip}. Do not include any introductory or concluding remarks. Start directly with the content.`;
      const response = await brandBuilderChatService.sendMessage(prompt, {
        bidData,
        canvasType,
        currentStepData,
        allFormData,
        orgCanvasData,
      });
      
      const combinedSuggestion = processAIResponse(response.response.trim());
      setSuggestions(prev => [...new Set([...prev, combinedSuggestion])].slice(0, 5));
      setSelectedSuggestion(combinedSuggestion);
      setMode('suggestions');
      setSnackbar({ open: true, message: 'Ideas combined and suggestion created', severity: 'success' });
    } catch (error) {
      console.error('Error combining ideas:', error);
      setSnackbar({ open: true, message: 'Error combining ideas. Please try again.', severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Tooltip title="Get Puāwai Intelligence Assist suggestions">
        <IconButton 
          onClick={handleClick} 
          size="small"
          sx={{
            color: theme.palette.primary.main,
            '&:hover': {
              backgroundColor: alpha(theme.palette.primary.main, 0.08),
            },
          }}
        >
          <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
            <FontAwesomeIcon icon={faSeedling} />
          </motion.div>
        </IconButton>
      </Tooltip>
      <Slide direction="left" in={open} mountOnEnter unmountOnExit>
        <Paper
          elevation={3}
          sx={{
            position: 'fixed',
            right: theme.spacing(2),
            top: theme.spacing(8), // Adjust this value to account for the header height
            bottom: theme.spacing(8), // Adjust this value to account for the footer height
            width: '400px',
            display: 'flex',
            flexDirection: 'column',
            p: theme.spacing(2),
            zIndex: theme.zIndex.drawer + 1,
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
            borderRadius: theme.shape.borderRadius,
            overflow: 'hidden', // Prevent content from overflowing
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: theme.spacing(2) }}>
            <Typography variant="h6" color="primary">
              <FontAwesomeIcon icon={faSeedling} style={{ marginRight: theme.spacing(1) }} />
              Puāwai Intelligence Assist: {fieldTitle}
            </Typography>
            <IconButton onClick={handleClose} size="small" sx={{ color: theme.palette.text.secondary }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <ToggleButtonGroup
            value={mode}
            exclusive
            onChange={handleModeChange}
            aria-label="text alignment"
            sx={{ mb: theme.spacing(2) }}
          >
            <ToggleButton value="suggestions" aria-label="suggestions">
              Suggestions
            </ToggleButton>
            <ToggleButton value="ideation" aria-label="ideation">
              Ideation
            </ToggleButton>
          </ToggleButtonGroup>
          <Box sx={{ flexGrow: 1, overflowY: 'auto', mb: theme.spacing(2) }}>
            {mode === 'suggestions' ? (
              <>
                <Typography variant="subtitle1" gutterBottom color="primary">Suggestions:</Typography>
                {suggestions.map((suggestion, index) => (
                  <Chip
                    key={index}
                    label={suggestion}
                    onClick={() => handleSuggestionSelect(suggestion)}
                    color={selectedSuggestion === suggestion ? "primary" : "default"}
                    sx={{ m: 0.5, borderRadius: theme.shape.borderRadius, cursor: 'pointer' }}
                  />
                ))}
              </>
            ) : (
              <>
                <Typography variant="subtitle1" gutterBottom color="primary">Ideas:</Typography>
                <AnimatePresence>
                  {ideas.map((idea, index) => (
                    <motion.div
                      key={index}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, x: -20 }}
                      transition={{ duration: 0.2 }}
                    >
                      <Paper
                        elevation={2}
                        sx={{
                          p: theme.spacing(1),
                          mb: theme.spacing(1),
                          backgroundColor: theme.palette.background.default,
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <Typography variant="body2">{idea}</Typography>
                        <IconButton size="small" onClick={() => handleRemoveIdea(index)}>
                          <CloseIcon fontSize="small" />
                        </IconButton>
                      </Paper>
                    </motion.div>
                  ))}
                </AnimatePresence>
              </>
            )}
          </Box>
          {mode === 'suggestions' && (
            <>
              <Box sx={{ mb: theme.spacing(2) }}>
                <Typography variant="subtitle1" gutterBottom color="primary">Selected:</Typography>
                <Paper 
                  variant="outlined" 
                  sx={{ 
                    p: theme.spacing(1), 
                    minHeight: '60px',
                    maxHeight: '120px',
                    overflowY: 'auto',
                    backgroundColor: alpha(theme.palette.primary.main, 0.08),
                    borderColor: theme.palette.primary.main,
                  }}
                >
                  <Typography>{selectedSuggestion || 'No suggestion selected'}</Typography>
                </Paper>
              </Box>
              <Box sx={{ display: 'flex', mb: theme.spacing(2) }}>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Ask for more suggestions..."
                  value={chatInput}
                  onChange={(e) => setChatInput(e.target.value)}
                  size="small"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: theme.palette.primary.main,
                      },
                      '&:hover fieldset': {
                        borderColor: theme.palette.primary.dark,
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: theme.palette.primary.main,
                      },
                    },
                  }}
                />
                <IconButton 
                  onClick={() => handleChatSubmit()} 
                  disabled={loading || !chatInput.trim()}
                  sx={{
                    color: theme.palette.primary.main,
                    '&:hover': {
                      backgroundColor: alpha(theme.palette.primary.main, 0.08),
                    },
                  }}
                >
                  <SendIcon />
                </IconButton>
              </Box>
              <Button 
                onClick={handleApply} 
                variant="contained" 
                color="primary" 
                fullWidth
                sx={{
                  borderRadius: theme.shape.borderRadius,
                  textTransform: 'none',
                  fontWeight: 'bold',
                }}
              >
                Apply Suggestion
              </Button>
            </>
          )}
          {mode === 'ideation' && (
            <>
              <Box sx={{ display: 'flex', mb: theme.spacing(2) }}>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Add a new idea..."
                  value={newIdea}
                  onChange={(e) => setNewIdea(e.target.value)}
                  size="small"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: theme.palette.primary.main,
                      },
                      '&:hover fieldset': {
                        borderColor: theme.palette.primary.dark,
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: theme.palette.primary.main,
                      },
                    },
                  }}
                />
                <IconButton 
                  onClick={handleAddIdea} 
                  disabled={!newIdea.trim()}
                  sx={{
                    color: theme.palette.primary.main,
                    '&:hover': {
                      backgroundColor: alpha(theme.palette.primary.main, 0.08),
                    },
                  }}
                >
                  <AddIcon />
                </IconButton>
              </Box>
              <Button 
                onClick={handleCombineIdeas} 
                variant="contained" 
                color="primary" 
                fullWidth
                startIcon={<LightbulbIcon />}
                disabled={ideas.length === 0}
                sx={{
                  borderRadius: theme.shape.borderRadius,
                  textTransform: 'none',
                  fontWeight: 'bold',
                }}
              >
                Combine Ideas
              </Button>
            </>
          )}
        </Paper>
      </Slide>
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert 
          onClose={handleSnackbarClose} 
          severity={snackbar.severity} 
          sx={{ width: '100%' }}
          elevation={6}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default PuawaiBidCanvasAIAssistButton;
