import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { 
  Box, Typography, Grid, Paper, Button, Avatar, 
  List, ListItem, ListItemText, ListItemAvatar,
  Chip, LinearProgress, IconButton, Tooltip,
  Dialog, DialogTitle, DialogContent, DialogActions,
  TextField, MenuItem, useTheme, Alert, Skeleton, Fade,
  CircularProgress, DialogContentText, Fab, Zoom, Drawer,
  Stepper, Step, StepLabel, StepContent, StepButton,
  Accordion, AccordionSummary, AccordionDetails,
  Card, CardContent, CardActions, Collapse,
  SwipeableDrawer, useMediaQuery, Tabs, Tab, ListItemIcon
} from '@mui/material';
import {
  Add as AddIcon,
  TrendingUp as TrendingUpIcon,
  Assignment as AssignmentIcon,
  MoreVert as MoreVertIcon,
  EmojiEvents as EmojiEventsIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
  Star as StarIcon,
  Fullscreen as FullscreenIcon,
  FullscreenExit as FullscreenExitIcon,
  CheckCircle as CheckCircleIcon,
  RadioButtonUnchecked as RadioButtonUncheckedIcon,
  Info as InfoIcon,
  ExpandMore as ExpandMoreIcon,
  Dashboard as DashboardIcon,
  Assessment as AssessmentIcon
} from '@mui/icons-material';
import { getActiveAccount } from '../../services/authService';
import { getOrganisation } from '../../services/organisationService';
import { 
  listOrgCanvases, 
  createOrgCanvas, 
  deleteOrgCanvas, 
  getOrgCanvas,
  updateOrgCanvas,
  completeOrgCanvas,
  saveDraftOrgCanvas
} from '../../services/organisationcanvasService';
import { listBids, createBid } from '../../services/bidService';
import { useNavigate, Routes, Route, useParams } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { toast } from 'react-toastify';
import GrowthStrategyCanvas from '../../components/orgcanvases/GrowthStrategyCanvas';
import BusinessModelCanvas from '../../components/orgcanvases/BusinessModelCanvas';
import BusinessStrategyCanvas from '../../components/orgcanvases/BusinessStrategyCanvas';
import GrowthExperimentsCanvas from '../../components/orgcanvases/GrowthExperimentsCanvas';
import PartnerChannelsCanvas from '../../components/orgcanvases/PartnerChannelsCanvas';
import StoryBrandCanvas from '../../components/orgcanvases/StoryBrandCanvas';
import { getUser, getUserWithSubscriptionStatus } from '../../services/userService';
import ChatIcon from '@mui/icons-material/Chat';
import CloseIcon from '@mui/icons-material/Close';
import ChatInterface from '../../components/ai/puawaiAIinterface';
import { useLanguage } from '../../contexts/LanguageContext';
import FloatingAIIcon from '../../components/ai/puawaiIntelligenceInterface';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCircle, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import PuawaiIntelligenceInterface from '../../components/ai/puawaiIntelligenceInterface';
import { getPortfolioItems } from '../../services/portfolioService';
import AITooltip from '../../components/ai/AITooltip';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import { format } from 'date-fns';
import { useInView } from 'react-intersection-observer';
import { useSpring, animated } from 'react-spring';

const Dashboard = ({ userData, selectedOrgId, toneOfVoiceData }) => {
  const [userProfile, setUserProfile] = useState(null);
  const [organisation, setOrganisation] = useState(null);
  const [canvases, setCanvases] = useState([]);
  const [bids, setBids] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogType, setDialogType] = useState('');
  const [newItem, setNewItem] = useState({ name: '', client: '', status: 'draft', dueDate: '' });
  const [canvasDialogState, setCanvasDialogState] = useState({
    open: false,
    data: null,
    type: null,
  });
  const navigate = useNavigate();
  const theme = useTheme();
  const [topBannerHeight, setTopBannerHeight] = useState(0);
  const { language, locale } = useLanguage();
  const [topOffset, setTopOffset] = useState(0);
  const layoutRef = useRef(null);
  const [portfolio, setPortfolio] = useState(null);
  const [expandedCanvas, setExpandedCanvas] = useState(null);
  const [activeTab, setActiveTab] = useState('overview');
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [deleteConfirmation, setDeleteConfirmation] = useState({ open: false, canvasId: null });

  const getUserName = (user) => {
    if (user.givenName) return user.givenName;
    if (user.name) return user.name.split(' ')[0];
    if (user.username) return user.username.split('@')[0];
    return 'User';
  };

  useEffect(() => {
    fetchDashboardData();
  }, [selectedOrgId, userData.localAccountId]);

  const fetchDashboardData = async () => {
    const orgId = selectedOrgId?.id || selectedOrgId;
    if (!orgId) {
      setLoading(false);
      return;
    }

    try {
      setLoading(true);
      
      const fetchedUserData = await getUser(userData.localAccountId);
      setUserProfile(fetchedUserData);

      const org = await getOrganisation(orgId);
      setOrganisation(org);

      if (org) {
        const fetchedBids = await listBids(orgId);
        setBids(fetchedBids);

        const fetchedCanvases = await listOrgCanvases(orgId);
        setCanvases(fetchedCanvases);

        const fetchedPortfolio = await getPortfolioItems(orgId);
        setPortfolio(fetchedPortfolio);
      }

    } catch (error) {
      toast.error('Failed to load some dashboard data. Please try refreshing the page.');
    } finally {
      setLoading(false);
    }
  };

  const getGrowthReadiness = () => {
    const completedCanvases = canvases.filter(canvas => canvas.status === 'completed').length;
    const draftCanvases = canvases.filter(canvas => canvas.status === 'draft').length;
    const totalReadiness = (completedCanvases * 20) + (draftCanvases * 10);
    return Math.min(totalReadiness, 100); // Ensure the readiness doesn't exceed 100%
  };

  const getActiveBidsCount = () => {
    return bids.filter(bid => bid.status !== 'closed').length;
  };

  const handleOpenDialog = (type) => {
    setDialogType(type);
    setNewItem({ name: '', client: '', status: 'draft', dueDate: '' });
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCreateItem = async () => {
    try {
      if (dialogType === 'bid') {
        const createdBid = await createBid({
          ...newItem,
          organisationId: selectedOrgId?.id || selectedOrgId,
        });
        setBids([...bids, createdBid]);
      } else if (dialogType === 'canvas') {
        const createdCanvas = await createOrgCanvas(organisation.id, { name: newItem.name, type: 'Growth Strategy Canvas' });
        setCanvases([...canvases, createdCanvas]);
      }
      handleCloseDialog();
    } catch (error) {
      toast.error('Error creating item. Please try again.');
    }
  };

  const fetchCanvases = useCallback(async () => {
    if (!selectedOrgId) return;
    try {
      const fetchedCanvases = await listOrgCanvases(selectedOrgId);
      setCanvases(fetchedCanvases);
    } catch (error) {
      toast.error('Failed to fetch canvases. Please try again.');
    }
  }, [selectedOrgId]);

  useEffect(() => {
    fetchCanvases();
  }, [fetchCanvases]);

  const handleOpenCanvas = async (canvasId) => {
    try {
      if (!organisation || !organisation.id) {
        throw new Error('No organization selected');
      }
      const canvas = await getOrgCanvas(organisation.id, canvasId);
      setCanvasDialogState({
        open: true,
        data: {
          ...canvas,
          points: canvas.points || 0, // Ensure points are included, default to 0 if not present
        },
      });
      toast.success('Canvas loaded successfully');
    } catch (error) {
      toast.error('Failed to open canvas. Please try again.');
    }
  };

  const handleCloseCanvasDialog = () => {
    setCanvasDialogState({ open: false, data: null, type: null });
  };

  const handleSaveCanvas = async (updatedCanvas) => {
    try {
      if (!selectedOrgId) {
        throw new Error('No organization selected');
      }
      
      const orgId = typeof selectedOrgId === 'string' ? selectedOrgId : selectedOrgId.id;
      
      let savedCanvas;
      if (updatedCanvas.status === 'completed') {
        savedCanvas = await completeOrgCanvas(orgId, updatedCanvas.id, updatedCanvas);
      } else {
        savedCanvas = await saveDraftOrgCanvas(orgId, updatedCanvas.id, updatedCanvas);
      }
      
      // Update the canvas list
      setCanvases(prevCanvases => 
        prevCanvases.map(c => c.id === savedCanvas.id ? savedCanvas : c)
      );
      
      // Show success message
      toast.success(`Canvas ${updatedCanvas.status === 'completed' ? 'completed' : 'saved as draft'} successfully`);
      
      // Refresh the canvas list
      await fetchCanvases();

      return savedCanvas;
    } catch (error) {
      toast.error(`Failed to save canvas. Please try again. Error: ${error.message}`);
      throw error;
    }
  };

  const handleDeleteCanvas = async (canvasId) => {
    setDeleteConfirmation({ open: true, canvasId });
  };

  const confirmDeleteCanvas = async () => {
    try {
      if (!organisation || !organisation.id) {
        throw new Error('No organization selected');
      }
      await deleteOrgCanvas(organisation.id, deleteConfirmation.canvasId);
      setCanvases(canvases.filter(canvas => canvas.id !== deleteConfirmation.canvasId));
      toast.success('Canvas deleted successfully');
    } catch (error) {
      toast.error('Failed to delete canvas. Please try again.');
    } finally {
      setDeleteConfirmation({ open: false, canvasId: null });
    }
  };

  const canvasTypes = [
    { type: 'Business Strategy Canvas', component: BusinessStrategyCanvas },
    { type: 'Growth Strategy Canvas', component: GrowthStrategyCanvas },
    { type: 'StoryBrand Canvas', component: StoryBrandCanvas },
    { type: 'Business Model Canvas', component: BusinessModelCanvas },
    { type: 'Partner Channels Canvas', component: PartnerChannelsCanvas },
    { type: 'Growth Experiments Canvas', component: GrowthExperimentsCanvas },
  ];

  const getCanvasDescription = (canvasType) => {
    const descriptions = {
      'Growth Strategy Canvas': 'Define your growth strategy and key initiatives.',
      'Business Model Canvas': 'Outline your business model and value proposition.',
      'StoryBrand Canvas': 'Craft your brand story and messaging framework.',
      'Business Strategy Canvas': 'Develop your overall business strategy and goals.',
      'Growth Experiments Canvas': 'Plan and track growth experiments and their outcomes.',
      'Partner Channels Canvas': 'Map out your partner channels and relationships.'
      
    };
    return descriptions[canvasType] || 'Explore this canvas to enhance your business strategy.';
  };

  const CanvasCard = ({ canvas, existingCanvas, onActionClick, onDeleteCanvas }) => {
    const [expanded, setExpanded] = useState(false);
    const [ref, inView] = useInView({
      triggerOnce: true,
      threshold: 0.1,
    });

    const cardSpring = useSpring({
      opacity: inView ? 1 : 0,
      transform: inView ? 'translateY(0px)' : 'translateY(50px)',
    });

    const isCompleted = existingCanvas && existingCanvas.status === 'completed';
    const isDraft = existingCanvas && existingCanvas.status === 'draft';

    let StepIcon;
    let iconColor;
    let statusText;
    if (isCompleted) {
      StepIcon = () => <FontAwesomeIcon icon={faCheck} />;
      iconColor = 'success.main';
      statusText = 'Completed';
    } else if (isDraft) {
      StepIcon = () => <FontAwesomeIcon icon={faPencilAlt} />;
      iconColor = 'warning.main';
      statusText = 'In Progress';
    } else {
      StepIcon = () => <FontAwesomeIcon icon={faCircle} />;
      iconColor = 'text.secondary';
      statusText = 'Not Started';
    }

    return (
      <animated.div style={cardSpring} ref={ref}>
        <Card>
          <CardContent>
            <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => setExpanded(!expanded)}>
              <Box sx={{ mr: 2, color: iconColor }}>
                <StepIcon />
              </Box>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="subtitle1">{canvas.type}</Typography>
                <Chip 
                  label={statusText}
                  color={isCompleted ? 'success' : (isDraft ? 'warning' : 'default')}
                  size="small"
                  sx={{ ml: 2 }}
                />
              </Box>
              <ExpandMoreIcon sx={{ transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)', transition: '0.3s' }} />
            </Box>
          </CardContent>
          <Collapse in={expanded}>
            <CardContent>
              <Typography variant="body2" color="text.secondary">
                {getCanvasDescription(canvas.type)}
              </Typography>
              {existingCanvas && (
                <LinearProgress 
                  variant="determinate" 
                  value={existingCanvas.completionPercentage || 0} 
                  sx={{ mt: 2 }}
                />
              )}
            </CardContent>
            <CardActions sx={{ justifyContent: 'flex-end' }}>
              {existingCanvas ? (
                <>
                  <Button
                    startIcon={<VisibilityIcon />}
                    onClick={() => onActionClick('view', existingCanvas.id, canvas.type)}
                    variant="outlined"
                    size="small"
                  >
                    View/Edit
                  </Button>
                  {(isDraft || isCompleted) && (
                    <Tooltip title="Delete">
                      <IconButton size="small" onClick={() => onDeleteCanvas(existingCanvas.id)}>
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  )}
                </>
              ) : (
                <Button
                  startIcon={<AddIcon />}
                  onClick={() => onActionClick('create', null, canvas.type)}
                  variant="contained"
                  color="primary"
                  size="small"
                >
                  Start Canvas
                </Button>
              )}
            </CardActions>
          </Collapse>
        </Card>
      </animated.div>
    );
  };

  const renderCanvasSection = () => (
    <Grid container spacing={2}>
      {canvasTypes.map((canvas, index) => {
        const existingCanvas = canvases.find(c => c.type === canvas.type);
        return (
          <Grid item xs={12} sm={6} md={4} key={canvas.type}>
            <CanvasCard
              canvas={canvas}
              existingCanvas={existingCanvas}
              onActionClick={handleActionClick}
              onDeleteCanvas={handleDeleteCanvas}
            />
          </Grid>
        );
      })}
    </Grid>
  );

  const handleActionClick = async (action, canvasId = null, canvasType = null) => {
    try {
      if (!organisation || !organisation.id) {
        throw new Error('No organization selected');
      }

      if (canvasId) {
        // Open existing canvas
        const canvas = await getOrgCanvas(organisation.id, canvasId);
        setCanvasDialogState({
          open: true,
          data: canvas,
          type: canvas.type,
        });
      } else {
        // Create new canvas without prompting for a name
        const newCanvas = await createOrgCanvas(organisation.id, {
          name: `${organisation.name} - ${canvasType}`,
          type: canvasType,
          createdBy: userData?.id,
          status: 'draft',
        });

        setCanvasDialogState({
          open: true,
          data: newCanvas,
          type: canvasType,
        });
        setCanvases([...canvases, newCanvas]);
        toast.success('New canvas created successfully');
      }
    } catch (error) {
      toast.error(`Error: ${error.message}`);
    }
  };

  const handleOpenBid = (bid) => {
    navigate(`/bid-management/${bid.id}`);
  };

  const renderLoadingSkeleton = () => (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Grid container spacing={3}>
        {/* Welcome and Quick Stats Skeleton */}
        <Grid item xs={12}>
          <Paper sx={{ p: 3, background: 'linear-gradient(45deg, #f3f3f3 30%, #e0e0e0 90%)' }}>
            <Typography variant="h4" gutterBottom>
              <Skeleton width="60%" />
            </Typography>
            <Typography variant="subtitle1">
              <Skeleton width="40%" />
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
              <Skeleton variant="rectangular" width={150} height={32} />
              <Skeleton variant="rectangular" width={150} height={32} />
            </Box>
          </Paper>
        </Grid>

        {/* Growth Readiness Journey Skeleton */}
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 3, height: '100%', position: 'relative', overflow: 'hidden' }}>
            <Typography variant="h5" gutterBottom>
              <Skeleton width="80%" />
            </Typography>
            <Grid container spacing={2}>
              {[1, 2, 3, 4].map((index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <Paper elevation={3} sx={{ p: 2, height: '100%' }}>
                    <Typography variant="h6" gutterBottom>
                      <Skeleton width="70%" />
                    </Typography>
                    <Skeleton variant="text" />
                    <Skeleton variant="text" width="60%" />
                    <Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 2 }}>
                      <Skeleton variant="circular" width={30} height={30} />
                      <Skeleton variant="circular" width={30} height={30} />
                    </Box>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Paper>
        </Grid>

        {/* Active Bids Skeleton */}
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 3, height: '100%', position: 'relative', overflow: 'hidden' }}>
            <Typography variant="h6" gutterBottom>
              <Skeleton width="50%" />
            </Typography>
            <List>
              {[1, 2, 3].map((index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                >
                  <ListItem>
                    <ListItemAvatar>
                      <Skeleton variant="circular" width={40} height={40} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={<Skeleton width="60%" />}
                      secondary={<Skeleton width="40%" />}
                    />
                    <Skeleton variant="rectangular" width={60} height={24} />
                  </ListItem>
                </motion.div>
              ))}
            </List>
            <Skeleton variant="rectangular" width="100%" height={36} sx={{ mt: 2 }} />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );

  useEffect(() => {
    const measureTopBanner = () => {
      const topBanner = document.querySelector('.MuiAppBar-root'); // Adjust this selector if needed
      if (topBanner) {
        setTopBannerHeight(topBanner.offsetHeight);
      }
    };

    measureTopBanner();
    window.addEventListener('resize', measureTopBanner);

    return () => window.removeEventListener('resize', measureTopBanner);
  }, []);

  useEffect(() => {
    const measureTopOffset = () => {
      const appBar = document.querySelector('.MuiAppBar-root');
      if (appBar) {
        setTopOffset(appBar.offsetHeight);
      }
    };

    measureTopOffset();
    window.addEventListener('resize', measureTopOffset);

    return () => window.removeEventListener('resize', measureTopOffset);
  }, []);

  const handleCanvasRoute = (canvasId) => {
    const canvas = canvases.find(c => c.id === canvasId);
    if (canvas) {
      setCanvasDialogState({
        open: true,
        data: canvas,
        type: canvas.type,
      });
    }
  };

  const renderOverviewTab = () => (
    <>
      <Grid item xs={12}>
        <Paper sx={{ p: 3, background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.secondary.main} 90%)`, color: 'white' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <EmojiEventsIcon sx={{ fontSize: '3rem', marginRight: '1rem' }} />
            <Box>
              <Typography variant="h4" gutterBottom>
                Welcome back, {userProfile?.givenName || 'User'}!
              </Typography>
              <Typography variant="subtitle1">{organisation?.name}</Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            <Chip icon={<TrendingUpIcon />} label={`Growth Readiness: ${getGrowthReadiness()}%`} sx={{ bgcolor: 'rgba(255,255,255,0.2)' }} />
            <Chip icon={<AssignmentIcon />} label={`Active Bids: ${getActiveBidsCount()}`} sx={{ bgcolor: 'rgba(255,255,255,0.2)' }} />
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={12} md={8}>
        <Paper sx={{ p: 3, height: '100%' }}>
          <Typography variant="h6" gutterBottom>Growth Readiness Journey</Typography>
          {renderCanvasSection()}
        </Paper>
      </Grid>
      <Grid item xs={12} md={4}>
        <Paper sx={{ p: 3, height: '100%' }}>
          <Typography variant="h6" gutterBottom>Active Bids</Typography>
          {renderActiveBids()}
        </Paper>
      </Grid>
    </>
  );

  const renderActiveBids = () => (
    <List>
      <AnimatePresence>
        {bids.filter(bid => bid.status !== 'closed').slice(0, 3).map((bid, index) => (
          <motion.div
            key={bid.id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3, delay: index * 0.1 }}
          >
            <ListItem 
              secondaryAction={
                <IconButton edge="end" aria-label="more" onClick={() => handleOpenBid(bid)}>
                  <MoreVertIcon />
                </IconButton>
              }
              sx={{ cursor: 'pointer' }}
              onClick={() => handleOpenBid(bid)}
            >
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: theme.palette.primary.main }}>{bid.name[0]}</Avatar>
              </ListItemAvatar>
              <ListItemText 
                primary={bid.name} 
                secondary={`Due: ${format(new Date(bid.dueDate), 'MMM d, yyyy')}`} 
              />
              <Chip label={bid.status} color="primary" size="small" />
            </ListItem>
          </motion.div>
        ))}
      </AnimatePresence>
    </List>
  );

  const renderAnalyticsTab = () => {
    const growthReadiness = getGrowthReadiness();
    const activeBidsCount = getActiveBidsCount();
    const completedCanvasCount = canvases.filter(canvas => canvas.status === 'completed').length;

    const data = [
      { name: 'Growth Readiness', value: growthReadiness },
      { name: 'Remaining', value: 100 - growthReadiness },
    ];

    const COLORS = [theme.palette.primary.main, theme.palette.grey[300]];

    return (
      <Box sx={{ mt: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper 
              elevation={3}
              sx={{ 
                p: 3, 
                mb: 3, 
                background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.secondary.main} 90%)`,
                borderRadius: theme.shape.borderRadius,
                position: 'relative',
              }}
            >
              <Typography variant="h4" gutterBottom sx={{ color: 'white', fontWeight: 'bold' }}>
                Puāwai Intelligence Analytics
              </Typography>
              <Typography variant="subtitle1" sx={{ color: 'white' }}>
                Insights powered by AI to drive your growth strategy
              </Typography>
              <Box sx={{ position: 'absolute', top: 8, right: 8 }}>
                <AITooltip 
                  context="Puāwai Intelligence Analytics Overview"
                  userProfile={userProfile}
                  organisation={organisation}
                />
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper sx={{ 
              p: 3, 
              height: '100%', 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center',
              boxShadow: theme.shadows[3],
              borderRadius: theme.shape.borderRadius,
              position: 'relative',
            }}>
              <Typography variant="h6" gutterBottom sx={{ color: theme.palette.secondary.main }}>Growth Readiness</Typography>
              <Box sx={{ width: '100%', height: 200, mb: 2 }}>
                <ResponsiveContainer>
                  <PieChart>
                    <Pie
                      data={data}
                      cx="50%"
                      cy="50%"
                      innerRadius={60}
                      outerRadius={80}
                      fill="#8884d8"
                      paddingAngle={5}
                      dataKey="value"
                    >
                      {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
              </Box>
              <Typography variant="h4" sx={{ color: theme.palette.primary.main, fontWeight: 'bold' }}>{growthReadiness}%</Typography>
              <Typography variant="body2" sx={{ color: theme.palette.text.secondary, mt: 1 }}>Overall Growth Readiness Score</Typography>
              <Box sx={{ position: 'absolute', top: 8, right: 8 }}>
                <AITooltip 
                  context="Growth Readiness Score"
                  userProfile={userProfile}
                  organisation={organisation}
                />
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper sx={{ 
              p: 3, 
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: theme.shadows[3],
              borderRadius: theme.shape.borderRadius,
              position: 'relative',
            }}>
              <Typography variant="h6" gutterBottom sx={{ color: theme.palette.secondary.main }}>Active Bids</Typography>
              <Typography variant="h4" sx={{ color: theme.palette.primary.main, fontWeight: 'bold', mb: 2 }}>{activeBidsCount}</Typography>
              <LinearProgress 
                variant="determinate" 
                value={(activeBidsCount / 10) * 100}
                sx={{ width: '80%', height: 10, borderRadius: 5 }}
              />
              <Typography variant="body2" sx={{ color: theme.palette.text.secondary, mt: 2 }}>Current Active Bids</Typography>
              <Box sx={{ position: 'absolute', top: 8, right: 8 }}>
                <AITooltip 
                  context="Active Bids Count"
                  userProfile={userProfile}
                  organisation={organisation}
                />
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper sx={{ 
              p: 3, 
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: theme.shadows[3],
              borderRadius: theme.shape.borderRadius,
              position: 'relative',
            }}>
              <Typography variant="h6" gutterBottom sx={{ color: theme.palette.secondary.main }}>Completed Canvases</Typography>
              <Typography variant="h4" sx={{ color: theme.palette.primary.main, fontWeight: 'bold', mb: 2 }}>{completedCanvasCount}</Typography>
              <Box sx={{ width: '80%', display: 'flex', justifyContent: 'space-between' }}>
                {[...Array(5)].map((_, index) => (
                  <Box 
                    key={index}
                    sx={{
                      width: 30,
                      height: 30,
                      borderRadius: '50%',
                      bgcolor: index < completedCanvasCount ? theme.palette.success.main : theme.palette.grey[300],
                    }}
                  />
                ))}
              </Box>
              <Typography variant="body2" sx={{ color: theme.palette.text.secondary, mt: 2 }}>Out of 5 Total Canvases</Typography>
              <Box sx={{ position: 'absolute', top: 8, right: 8 }}>
                <AITooltip 
                  context="Completed Canvases Count"
                  userProfile={userProfile}
                  organisation={organisation}
                />
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const renderContent = () => {
    switch (activeTab) {
      case 'overview':
        return renderOverviewTab();
      case 'analytics':
        return renderAnalyticsTab();
      default:
        return null;
    }
  };

  const renderMobileDrawer = () => (
    <SwipeableDrawer
      anchor="left"
      open={drawerOpen}
      onClose={() => setDrawerOpen(false)}
      onOpen={() => setDrawerOpen(true)}
    >
      <Box sx={{ width: 250 }} role="presentation">
        <List>
          <ListItem button onClick={() => { setActiveTab('overview'); setDrawerOpen(false); }}>
            <ListItemIcon><DashboardIcon /></ListItemIcon>
            <ListItemText primary="Overview" />
          </ListItem>
          <ListItem button onClick={() => { setActiveTab('analytics'); setDrawerOpen(false); }}>
            <ListItemIcon><AssessmentIcon /></ListItemIcon>
            <ListItemText primary="Analytics" />
          </ListItem>
        </List>
      </Box>
    </SwipeableDrawer>
  );

  if (loading) {
    return <CircularProgress />;
  }

  const orgId = selectedOrgId?.id || selectedOrgId;
  if (!orgId) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography variant="h5">No organization selected</Typography>
        <Typography variant="body1">Please select an organization to view the dashboard.</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ flexGrow: 1, p: 3 }} ref={layoutRef}>
      {isMobile && (
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={() => setDrawerOpen(true)}
          sx={{ mr: 2, display: { sm: 'none' } }}
        >
          <MoreVertIcon />
        </IconButton>
      )}
      {renderMobileDrawer()}
      {!isMobile && (
        <Paper sx={{ mb: 2 }}>
          <Tabs value={activeTab} onChange={(e, newValue) => setActiveTab(newValue)}>
            <Tab label="Overview" value="overview" icon={<DashboardIcon />} />
            <Tab label="Analytics" value="analytics" icon={<AssessmentIcon />} />
          </Tabs>
        </Paper>
      )}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Grid container spacing={3}>
          {renderContent()}
        </Grid>
      </motion.div>

      {/* Dialog for creating new items */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{`Create New ${dialogType === 'bid' ? 'Bid' : 'Canvas'}`}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            fullWidth
            value={newItem.name}
            onChange={(e) => setNewItem({ ...newItem, name: e.target.value })}
          />
          {dialogType === 'bid' && (
            <>
              <TextField
                margin="dense"
                label="Client"
                fullWidth
                value={newItem.client}
                onChange={(e) => setNewItem({ ...newItem, client: e.target.value })}
              />
              <TextField
                select
                margin="dense"
                label="Status"
                fullWidth
                value={newItem.status}
                onChange={(e) => setNewItem({ ...newItem, status: e.target.value })}
              >
                <MenuItem value="draft">Draft</MenuItem>
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="submitted">Submitted</MenuItem>
                <MenuItem value="won">Won</MenuItem>
                <MenuItem value="lost">Lost</MenuItem>
              </TextField>
              <TextField
                margin="dense"
                label="Due Date"
                type="date"
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={newItem.dueDate}
                onChange={(e) => setNewItem({ ...newItem, dueDate: e.target.value })}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleCreateItem}>Create</Button>
        </DialogActions>
      </Dialog>

      {/* Canvas Dialog */}
      <Dialog 
        open={canvasDialogState.open} 
        onClose={handleCloseCanvasDialog}
        fullScreen
        PaperProps={{
          style: {
            backgroundColor: 'rgba(255, 255, 255, 0.95)',
          },
        }}
      >
        <DialogContent sx={{ p: 0 }}>
          {canvasDialogState.type && (
            React.createElement(
              canvasTypes.find(ct => ct.type === canvasDialogState.type).component,
              {
                user: userProfile,
                selectedOrg: selectedOrgId,
                canvas: canvasDialogState.data,
                onClose: handleCloseCanvasDialog,
                onSave: handleSaveCanvas,  // Make sure this is handleSaveCanvas
                onUpdate: (updatedCanvas) => {
                  const orgId = typeof selectedOrgId === 'string' ? selectedOrgId : selectedOrgId.id;
                  updateOrgCanvas(orgId, updatedCanvas.id, updatedCanvas);
                }
              }
            )
          )}
        </DialogContent>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteConfirmation.open}
        onClose={() => setDeleteConfirmation({ open: false, canvasId: null })}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this canvas? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmation({ open: false, canvasId: null })}>Cancel</Button>
          <Button onClick={confirmDeleteCanvas} color="error">Delete</Button>
        </DialogActions>
      </Dialog>

      {/* Add the FloatingAIIcon component at the end of the return statement */}
      <FloatingAIIcon
        userProfile={userProfile}
        organisation={organisation}
        toneOfVoice={toneOfVoiceData}
        topOffset={topOffset}
        bids={bids}
        canvases={canvases}
        // You can add more props here if needed, e.g.:
        // activeTasks={activeTasks}
        // organisationInsights={organisationInsights}
      />

      <PuawaiIntelligenceInterface
        userProfile={userProfile}
        organisation={{...organisation, portfolio: portfolio}}
        toneOfVoice={toneOfVoiceData}
        topOffset={topOffset}
        bids={bids}
        canvases={canvases}
      />

      <Routes>
        <Route path="/canvas/:canvasId" element={<CanvasRoute handleCanvasRoute={handleCanvasRoute} />} />
      </Routes>
    </Box>
  );
};

const CanvasRoute = ({ handleCanvasRoute }) => {
  const { canvasId } = useParams();
  useEffect(() => {
    handleCanvasRoute(canvasId);
  }, [canvasId, handleCanvasRoute]);
  return null;
};

export default Dashboard;