import api from './api';
import { getAuthHeader } from './authService';

const apiCall = async (method, endpoint, data = null) => {
  try {
    const token = await getAuthHeader();
    const config = {
      method,
      url: `/api/collateral${endpoint}`,
      headers: { 
        Authorization: token,
        'Content-Type': 'application/json'
      },
    };
    if (data) {
      config.data = data;
    }
    const response = await api(config);
    return response.data;
  } catch (error) {
    throw new Error(`API error: ${error.response?.data?.message || error.message}`);
  }
};

/**
 * Function to create a new collateral item
 * @param {Object} collateralData - Data of the collateral item to create
 * @param {string} organisationId - ID of the organisation
 * @returns {Object} - The created collateral item data
 */
export const createCollateral = async (collateralData, selectedOrg) => {
  try {
    const token = await getAuthHeader();
    
    // Clean up the data before sending
    const cleanedData = {
      ...collateralData,
      organisationId: selectedOrg,
      // Convert any undefined values to null or empty string
      referenceUrls: collateralData.referenceUrls || [],
      // Add any other required fields with defaults
    };

    // Log the request for debugging
    console.log('Creating collateral with data:', cleanedData);

    const response = await api({
      method: 'post',
      url: '/api/collateral',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json'
      },
      data: cleanedData
    });

    return response.data;
  } catch (error) {
    console.error('Error creating collateral:', error);
    if (error.response) {
      console.error('Error response:', error.response.data);
    }
    throw error;
  }
};

/**
 * Function to get details of a collateral item by ID
 * @param {string} collateralId - ID of the collateral item
 * @returns {Object} - The collateral item data
 */
export const getCollateral = async (collateralId) => {
  return apiCall('get', `/${collateralId}`);
};

/**
 * Function to update a collateral item
 * @param {string} collateralId - Collateral item ID to update
 * @param {Object} updateData - Updated data for the collateral item
 * @param {string} orgId - ID of the organisation
 * @returns {Object} - The updated collateral item data
 */
export const updateCollateral = async (id, collateralData, selectedOrg) => {
  try {
    const token = await getAuthHeader();
    
    // Clean up the data before sending
    const cleanedData = {
      ...collateralData,
      organisationId: selectedOrg,
      // Convert any undefined values to null or empty string
      referenceUrls: collateralData.referenceUrls || [],
      // Add any other required fields with defaults
    };

    // Log the request for debugging
    console.log('Updating collateral with data:', cleanedData);

    const response = await api({
      method: 'put',
      url: `/api/collateral/${id}`,
      headers: {
        Authorization: token,
        'Content-Type': 'application/json'
      },
      data: cleanedData
    });

    return response.data;
  } catch (error) {
    console.error('Error updating collateral:', error);
    if (error.response) {
      console.error('Error response:', error.response.data);
    }
    throw error;
  }
};

/**
 * Function to delete a collateral item
 * @param {string} itemId - ID of the collateral item to delete
 * @param {string} orgId - ID of the organisation
 * @returns {Object} - The response from the server
 */
export const deleteCollateral = async (itemId, orgId) => {
  if (!itemId || !orgId) {
    throw new Error('Item ID and Organization ID are required');
  }
  return apiCall('delete', `/${itemId}?orgId=${orgId}`);
};

/**
 * Function to list all collateral items for an organisation
 * @param {string} organisationId - ID of the organisation
 * @returns {Array} - List of all collateral items for the organisation
 */
export const listCollateral = async (orgId) => {
  return apiCall('get', `?orgId=${orgId}`);
};

/**
 * Function to search collateral items
 * @param {string} organisationId - ID of the organisation
 * @param {string} searchTerm - Search term
 * @returns {Array} - List of collateral items matching the search term
 */
export const searchCollateral = async (organisationId, searchTerm) => {
  return apiCall('get', `?organisationId=${organisationId}&search=${searchTerm}`);
};

/**
 * Function to get the download URL for a collateral item
 * @param {string} collateralId - ID of the collateral item
 * @returns {string} - Download URL for the collateral item
 */
export const getCollateralDownloadUrl = async (itemId, orgId) => {
  const response = await apiCall('get', `/${itemId}/download?orgId=${orgId}`);
  return response.downloadUrl;
};

export const uploadCollateral = async (file, orgId) => {
  const formData = new FormData();
  formData.append('file', file);
  return apiCall('post', `?orgId=${orgId}`, formData);
};

export const renameCollateral = async (itemId, orgId, newFileName) => {
  return apiCall('put', `/${itemId}?orgId=${orgId}`, { newFileName });
};

export default {
  createCollateral,
  listCollateral,
  deleteCollateral,
  getCollateralDownloadUrl,
  uploadCollateral,
  renameCollateral,
};
